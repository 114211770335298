import React, { Component } from "react";
import { Modal } from "antd";
import { connect } from "react-redux";
import { closeModal } from "./modalActions";
import DuplicateOrdersList from "../orderPunching/modal/DuplicateOrdersList";

class DuplicateOrderListModal extends Component {
  state = {};
  render() {
    const { closeModal, orderList, type } = this.props;
    return (
      <Modal
        title="Duplicate Orders"
        visible={true}
        onCancel={closeModal}
        onOk={closeModal}
      >
        <DuplicateOrdersList orderList={orderList} type={type} />
      </Modal>
    );
  }
}
const actions = { closeModal };
const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  actions
)(DuplicateOrderListModal);
