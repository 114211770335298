export const FORM_SUBMIT_START = "FORM_SUBMIT_START";
export const FORM_SUBMIT_SUCCESS = "FORM_SUBMIT_SUCCESS";
export const FORM_SUBMIT_FAILURE = "FORM_SUBMIT_FAILURE";
export const FETCH_FORM_DATA_START = "FETCH_FORM_DATA_START";
export const FETCH_FORM_DATA_SUCCESS = "FETCH_FORM_DATA_SUCCESS";
export const FETCH_FORM_DATA_FAILURE = "FETCH_FORM_DATA_FAILURE";
export const SET_TINT_CHECKBOX_TRUE = "SET_TINT_CHECKBOX_TRUE";
export const SET_TINT_CHECKBOX_FALSE = "SET_TINT_CHECKBOX_FALSE";
export const SET_FITTING_CHECKBOX_TRUE = "SET_FITTING_CHECKBOX_TRUE";
export const SET_FITTING_CHECKBOX_FALSE = "SET_FITTING_CHECKBOX_FALSE";
export const SET_CUSTOMER_ID = "SET_CUSTOMER_ID";
export const SET_ADDRESS_LIST = "SET_ADDRESS_LIST";
export const SET_LNAM_ID = "SET_LNAM_ID";
export const SET_CORRIDOR_ID = "SET_CORRIDOR_ID";
export const RESET_FIELDS = "RESET_FIELDS";
export const SET_URGENCY = "SET_URGENCY";
export const CHECK_COATING_TIMELINE = "CHECK_COATING_TIMELINE";
export const SET_TINT_SPECIFICATION = "SET_TINT_SPECIFICATION";
export const SET_FITTING_FOR_CORRIDOR = "SET_FITTING_FOR_CORRIDOR";
export const SET_FITTING_HEIGHT_ID = "SET_FITTING_HEIGHT_ID";
export const SET_CORRIDOR_FOR_FITTING = "SET_CORRIDOR_FOR_FITTING";
export const ENABLE_RIGHT_PRISM = "ENABLE_RIGHT_PRISM";
export const DISABLE_RIGHT_PRISM = "DISABLE_RIGHT_PRISM";
export const ENABLE_LEFT_PRISM = "ENABLE_LEFT_PRISM";
export const DISABLE_LEFT_PRISM = "DISABLE_LEFT_PRISM";
export const SHOW_ADD_FOR_LNAM = "SHOW_ADD_FOR_LNAM";
export const HIDE_ADD_FOR_LNAM = "HIDE_ADD_FOR_LNAM";
export const SET_PANTO_MANDATORY_FLAG = "SET_PANTO_MANDATORY_FLAG";
export const SET_ZTILT_MANDATORY_FLAG = "SET_ZTILT_MANDATORY_FLAG";
export const SET_BVD_MANDATORY_FLAG = "SET_BVD_MANDATORY_FLAG";
export const SET_MONO_PD_FOR_LNAM = "SET_MONO_PD_FOR_LNAM";
export const SET_NPD_FOR_LNAM = "SET_NPD_FOR_LNAM";
export const SET_SWING_FOR_LNAM = "SET_SWING_FOR_LNAM";
export const SET_RIGHT_EYE_CHECKBOX = "SET_RIGHT_EYE_CHECKBOX";
export const SET_LEFT_EYE_CHECKBOX = "SET_LEFT_EYE_CHECKBOX";
export const HIDE_FIELDS_FOR_FP_LNAMS = "HIDE_FIELDS_FOR_FP_LNAMS";
export const SET_ADD_FIELD_INCLUDE_FLAG = "SET_ADD_FIELD_INCLUDE_FLAG";
export const CHECK_INTERNAL_ORDER_ID_START = "CHECK_INTERNAL_ORDER_ID_START";
export const CHECK_INTERNAL_ORDER_ID_SUCCESS =
  "CHECK_INTERNAL_ORDER_ID_SUCCESS";
export const CHECK_INTERNAL_ORDER_ID_FAILURE =
  "CHECK_INTERNAL_ORDER_ID_FAILURE";
export const SHOW_CUSTOMER_INITIAL_FIELD = "SHOW_CUSTOMER_INITIAL_FIELD";
export const CHECK_DUPLICATE_ORDERS_START = "CHECK_DUPLICATE_ORDERS_START";
export const CHECK_DUPLICATE_ORDERS_SUCCESS = "CHECK_DUPLICATE_ORDERS_SUCCESS";
export const CHECK_DUPLICATE_ORDERS_FAILURE = "CHECK_DUPLICATE_ORDERS_FAILURE";
export const SET_NPD_DISABLED_FLAG = "SET_NPD_DISABLED_FLAG";
export const SHOW_NPD_FOR_LNAM = "SHOW_NPD_FOR_LNAM";
export const SHOW_MONO_PD_FOR_LNAM = "SHOW_MONO_PD_FOR_LNAM";
export const SET_CTO_FIELDS_MANDATORY = "SET_CTO_FIELDS_MANDATORY";
export const SET_TINT_DESCRIPTION = "SET_TINT_DESCRIPTION";
export const SET_LNAM_DESCRIPTION = "SET_LNAM_DESCRIPTION";
export const SET_ADDRESS_DESCRIPTION = "SET_ADDRESS_DESCRIPTION";
export const GET_ORDER_FROM_REFERENCEID_START =
  "GET_ORDER_FROM_REFERENCEID_START";
export const GET_ORDER_FROM_REFERENCEID_SUCCESS =
  "GET_ORDER_FROM_REFERENCEID_SUCCESS";
export const GET_ORDER_FROM_REFERENCEID_FAILURE =
  "GET_ORDER_FROM_REFERENCEID_FAILURE";
export const SET_DISTRIBUTOR_LIST = "SET_DISTRIBUTOR_LIST"
export const SET_RETAILER_LIST = "SET_RETAILER_LIST"
export const SET_PRODUCT_GROUP_LIST = "SET_PRODUCT_GROUP_LIST"
export const SET_PRODUCT_GROUP_DESCRIPTION = "SET_PRODUCT_GROUP_DESCRIPTION"
export const SET_LNAM_LIST_FOR_PRODUCT_GROUP = "SET_LNAM_LIST_FOR_PRODUCT_GROUP";
export const SET_PRODUCT_GROUP_OWNER = "SET_PRODUCT_GROUP_OWNER";
export const SET_FIELDS_STATUS_FOR_PRODUCT_GROUP = "SET_FIELDS_STATUS_FOR_PRODUCT_GROUP"