import axios from "axios";
import { makeUrl } from "../../app/common/util/genericUtils";

export const loginApiCall = creds => {
  console.log(creds);
  return axios({
    method: "POST",
    url: makeUrl("/account/login"),
    data: creds
  });
};
