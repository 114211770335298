import React, { Component } from "react";
import { Modal } from "antd";
import { connect } from "react-redux";
import { closeModal } from "./modalActions";
import WhatsappOrders from "../whatsappOrders/WhatsappOrders";

const actions = { closeModal };

class WhatsappModal extends Component {
  render() {
    const { closeModal } = this.props;
    return (
      <Modal
        title="WhatsApp Orders"
        visible={true}
        onCancel={closeModal}
        onOk={closeModal}
        width="88%"
        centered
      >
        <WhatsappOrders />
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(WhatsappModal);
