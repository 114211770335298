import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { Form, Header } from "semantic-ui-react";
import { Button } from "antd";
import CustomSelect from "../../app/common/form/CustomSelect";

import {
  isRequired,
  hasLengthGreaterThan,
  combineValidators,
  composeValidators
} from "revalidate";
import { createNotification } from "./notificationActions";
const validate = combineValidators({
  notification_type: isRequired({ message: "Type is required" }),
  reviewers: isRequired({ message: "Visibilty is required" }),
  text: composeValidators(
    isRequired({ message: "Notification text is required" }),
    hasLengthGreaterThan(3)({ message: "Length should be greater than 3" })
  )()
});
class CreateNotificationForm extends Component {
  state = {};
  render() {
    const {
      handleSubmit,
      invalid,
      creatingNotification,
      createNotification
    } = this.props;
    return (
      <div>
        <Form error size="large" autoComplete="off">
          <Header content="Notification Type" sub color="teal" />
          <Field
            name="notification_type"
            component={CustomSelect}
            placeholder="Notification Type"
            allowClear={true}
            data={notificationTypes}
          />
          <Header content="Visibility" sub color="teal" />
          <Field
            name="reviewers"
            component={CustomSelect}
            placeholder="Visiblity"
            allowClear={true}
            data={visibiltiy}
          />
          <Header content="Notification Text" sub color="teal" />
          <Field
            name="text"
            component="textarea"
            placeholder="Notification Type"
          />
          <Button
            block
            type="primary"
            style={{ height: "38px", marginTop: "5%" }}
            onClick={handleSubmit(createNotification)}
            disabled={invalid}
            loading={creatingNotification}
          >
            Submit
          </Button>
        </Form>
      </div>
    );
  }
}
const actions = {
  createNotification
};
const mapStateToProps = state => ({
  creatingNotification: state.notification.creatingNotification
});
export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "createNotificationForm",
    validate,
    enableReinitialize: true
  })(CreateNotificationForm)
);

const notificationTypes = [
  { label: "Order Notification", key: "Order Notification" },
  { label: "Process Notification", key: "Process Notification" },
  { label: "Hardware Notification", key: "Hardware Notification" },
  { label: "Blanks Notification", key: "Blanks Notification" },
  { label: "Personnel Notification", key: "Personnel Notification" },
  { label: "Price Notification", key: "Price Notification" },
  { label: "Holiday Notification", key: "Holiday Notification" },
  { label: "Delay Notification", key: "Delay Notification" },
  { label: "Other Notification", key: "Other Notification" }
];

const visibiltiy = [
  { label: "All", key: "ALL" },
  { label: "Lab (Corporate)", key: "CORPORATE" },
  { label: "Branch", key: "BRANCH" },
  { label: "Customer", key: "CUSTOMER" },
  { label: "Lab & Branch", key: "CB" }
];
