import React from "react";
import { Col } from "antd";

const LeftLensConfig = ({ data, highlightedFields, headerMap }) => {
  console.log("left", data);
  if (data) {
    return (
      <Col span={12}>
        {Object.entries(headerMap).map(([key, value]) => {
          return (
            <Col span={6} key={key} style={{ height: "75px" }}>
              {data.hasOwnProperty(key) === true ? (
                <div
                  style={
                    highlightedFields && highlightedFields.hasOwnProperty(key)
                      ? { backgroundColor: "yellow" }
                      : { backgroundColor: "white" }
                  }
                >
                  <h4 className="detail-heading">{value}</h4>
                  {data[key] && data[key]["label"] ? (
                    <p className="detail-value">{data[key]["label"]}</p>
                  ) : (
                    <p className="detail-value">{data[key]}</p>
                  )}
                </div>
              ) : (
                <span>
                  <h4 className="detail-heading">{value}</h4>
                  <span>Not Entered</span>
                </span>
              )}
            </Col>
          );
        })}
      </Col>
    );
  } else {
    return (
      <Col span={12}>
        {Object.entries(headerMap).map(([key, value]) => {
          return (
            <Col span={6} key={key} style={{ height: "75px" }}>
              <h4 className="detail-heading">{value}</h4>
              <span>Not Entered</span>
            </Col>
          );
        })}
      </Col>
    );
  }
};

export default LeftLensConfig;
