import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, FormSection } from "redux-form";
import { Button, Row, Col } from "antd";
import { Form } from "semantic-ui-react";
import TextInput from "../../app/common/form/TextInput";
import { blankSelection, checkOpcValidation } from "./blankSelectionActions";
import { combineValidators, isNumeric } from "revalidate";

const validate = combineValidators({
  right_opc: isNumeric({ message: "Numeric Value allowed" }),
  left_opc: isNumeric({ message: "Numeric Value allowed" }),
  tray_number: isNumeric({ message: "Numeric Value allowed" })
});
class BlankSelectionForm extends Component {
  state = {};
  handleFormSubmit = data => {
    const {
      orderId,
      blankSelection,
      multipleBlankReason,
      orderDetails
    } = this.props;
    let bData = {
      blank_selection: {
        ...data
      }
    };
    if (multipleBlankReason !== null) {
      bData.blank_selection["reason_for_blank_selection"] = multipleBlankReason;
    }
    bData["id"] = orderId;
    console.log(bData);
    blankSelection(bData, orderDetails);
  };
  handleOpcValidation = (event, newValue, previousValue, name) => {
    const obj = {
      name,
      newValue,
      orderId: this.props.orderId
    };
    console.log("opc val", obj);
    this.props.checkOpcValidation(obj);
  };
  render() {
    const {
      handleSubmit,
      invalid,
      loader,
      gettingLabelData,
      orderDetails,
      authCardLoader
    } = this.props;
    return (
      <div style={{ margin: "2% 0 0 10%" }}>
        <Form error size="large" autoComplete="off">
          <Row gutter={8}>
            {orderDetails.right_lens_config !== null && (
              <Col span={4}>
                <Field
                  name="right_opc"
                  type="text"
                  component={TextInput}
                  placeholder="Right Opc"
                  onBlur={this.handleOpcValidation}
                />
              </Col>
            )}
            {orderDetails.left_lens_config !== null && (
              <Col span={4}>
                <Field
                  name="left_opc"
                  type="text"
                  component={TextInput}
                  placeholder="Left Opc"
                  onBlur={this.handleOpcValidation}
                />
              </Col>
            )}
            {/**<Col span={4}>
              <Field
                name="tray_number"
                type="text"
                component={TextInput}
                placeholder="Tray Number"
              />
            </Col> */}
          </Row>

          <div style={{ margin: "2% 0 0 0" }}>
            <Button
              type="primary"
              disabled={invalid}
              onClick={handleSubmit(this.handleFormSubmit)}
              loading={loader || gettingLabelData || authCardLoader}
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}
const actions = { blankSelection, checkOpcValidation };
const mapStateToProps = state => ({
  multipleBlankReason: state.blankSelection.multipleBlankReason,
  loader: state.blankSelection.blankSelectionLoader,
  gettingLabelData: state.blankSelection.gettingLabelData,
  authCardLoader: state.authCard.authCardLoader
});
export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({ form: "blankSelectionForm", validate, enableReinitialize: true })(
    BlankSelectionForm
  )
);
