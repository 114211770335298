import axios from "axios";
import { makeUrl, getItem } from "../../app/common/util/genericUtils";

export const labelDataApiCall = () => {
  const token = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/master/label"),
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};

export const createLabelApiCall = data => {
  const token = getItem("suprolUser");
  return axios({
    method: "POST",
    url: makeUrl("/orders/label"),
    data,
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};
