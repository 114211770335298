import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { closeModal } from "./modalActions";
import OnHoldModal from "../orderPunching/modal/OnHoldModal";

const actions = { closeModal };
const mapStateToProps = state => ({});

class HoldModal extends Component {
  render() {
    const { closeModal, formData } = this.props;
    return (
      <Modal size="mini" open={true} onClose={closeModal}>
        <Modal.Header>Order On Hold</Modal.Header>
        <Modal.Content>
          <OnHoldModal formData={formData} />
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  actions
)(HoldModal);
