import React, { Component } from "react";
import { Col, Row, message } from "antd";
import { Header, Form, Label } from "semantic-ui-react";
import { Field, touch } from "redux-form";
import TextInput from "../../../app/common/form/TextInput";
import { connect } from "react-redux";
import { MandatoryHeader, lensConfig } from "../formUtils";
import {
  enableRightPrism,
  disableRightPrism,
  transformOnBlur,
  setRightEyeCheckBox,
  changeFieldsWithDia,
  bothEyesEqual,
  checkSumForLnam
} from "../orderActions";
import CustomSelect from "../../../app/common/form/CustomSelect";
import { mbaseList } from "../constants";
import FieldWrapper from "../FieldWrapper";
import { getUserType } from "../../../app/common/util/genericUtils";
class RightEyeConfig extends Component {
  state = {
    userType: getUserType()
  };
  checkEyeField = (e, value) => {
    const {
      enableRightPrism,
      disableRightPrism,
      monoPdR,
      npdR,
      touch,
      change,
      setRightEyeCheckBox,
      swing
    } = this.props;
    setRightEyeCheckBox(value);
    if (value === true) {
      swing === true && change("right_lens_config.swing", "15");
      enableRightPrism();
      change("frame.mono_pd_r", monoPdR);
      change("frame.npd_r", npdR);
      change("right_lens_config.dia", "65");
      change("right_lens_config.ellh", "65");
      touch("orderForm", "right_lens_config.dia");
      touch("orderForm", "right_lens_config.ellh");
    } else {
      change("right_lens_config.swing", "");
      disableRightPrism();
      lensConfig.forEach(config => {
        change(`right_lens_config.${config}`, "");
      });
      change("frame.mono_pd_r", "");
      change("frame.npd_r", "");
      change("right_lens_config.dia", "");
      change("right_lens_config.ellh", "");
    }
  };
  checkPower = (newValue, name) => {
    this.props.transformOnBlur(newValue, name);
    //this.props.addFieldIncludeFlag === false &&
    this.props.checkSumForLnam("right_lens_config");
  };

  handleMbase = obj => {
    if (obj && obj.key !== undefined) {
      let { userType } = this.state;
      if (userType && userType !== "Corporate") {
        message.warning(
          "Only enter a value here if customer has specifically requested a base. \nLab’s lens calculation optimizes for thickness and base automatically. \nIf you enter a base value here, unless it is impossible to make power in that base, the lens calculator will use this base provided, and the lab can’t guarantee the thinnest possible lens in this case",
          15
        );
      }
    }
  };
  handleCtR = (newValue, name) => {
    this.props.transformOnBlur(newValue, name);
    let { userType } = this.state;
    if (userType && userType !== "Corporate") {
      message.warning(
        "Our system has a minimum thickness recommendation for lens quality and life. If you enter a value lower than what is calculated by the system at the time of processing at the lab, value entered by you will be ignored, Please acknowledge.",
        10
      );
    }
  };
  handleEtr = (newValue, name) => {
    this.props.transformOnBlur(newValue, name);
    let { userType } = this.state;
    if (userType && userType !== "Corporate") {
      message.warning(
        "Our system has a minimum thickness recommendation for lens quality and life. If you enter a value lower than what is calculated by the system at the time of processing at the lab, value entered by you will be ignored, Please acknowledge.",
        10
      );
    }
  };
  render() {
    const {
      bothEyesEqual,
      change,
      showAddForLnam,
      transformOnBlur,
      rightEyeCheckbox,
      labelInValue,
      changeFieldsWithDia,
      leftEyeCheckbox,
      checkSumForLnam,
      swing,
      addFieldIncludeFlag,
      pgDependentFields
    } = this.props;
    return (
      <Col span={12} style={{ background: "antiquewhite", padding: "20px" }}>
        <Row>
          <Col span={2}>
            <Header sub color="teal" content="RE" />
            <Form.Group inline style={{ marginTop: "15px" }}>
              <Field
                id="re"
                name="re"
                //isChecked={true}
                component="input"
                type="checkbox"
                onChange={this.checkEyeField}
              />
            </Form.Group>
          </Col>
          <Col span={8}>
            {rightEyeCheckbox === false && (
              <Label basic color="red">
                Select RE to enter values
              </Label>
            )}
          </Col>
          <Col span={6}>
            <Header sub color="teal" content="Both eyes equal" />
            <Form.Group inline style={{ marginTop: "15px" }}>
              <Field
                id="both_eyes_equal"
                name="bothEyeEqual"
                disabled={!leftEyeCheckbox}
                component="input"
                type="checkbox"
                onChange={bothEyesEqual}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row gutter={10}>
          <FieldWrapper
            name="sph"
            type="text"
            component={TextInput}
            placeholder="SPH_R"
            disabled={!rightEyeCheckbox}
            handleOnBlur={transformOnBlur}
            mandatory={true}
            heading="SPH_R"
            width={6}
          />

          <FieldWrapper
            name="cyl"
            type="text"
            component={TextInput}
            placeholder="CYL_R"
            disabled={!rightEyeCheckbox}
            handleOnBlur={this.checkPower}
            mandatory={false}
            heading="CYL_R"
            width={6}
          />
          <FieldWrapper
            name="axis"
            type="text"
            component={TextInput}
            placeholder="Axis_R"
            disabled={!rightEyeCheckbox}
            mandatory={false}
            heading="AXIS_R"
            width={6}
          />
          {/* showAddForLnam === true && */}
          {pgDependentFields.add === true && rightEyeCheckbox === true && (
            <FieldWrapper
              name="add"
              type="text"
              component={TextInput}
              placeholder="ADD_R"
              disabled={!rightEyeCheckbox}
              handleOnBlur={this.checkPower}
              mandatory={true}
              heading="ADD_R"
              width={6}
            />
          )}
          {rightEyeCheckbox === true && (
            <FieldWrapper
              name="dia"
              type="text"
              component={TextInput}
              placeholder="DIA_R"
              disabled={!rightEyeCheckbox}
              handleOnBlur={changeFieldsWithDia}
              mandatory={true}
              heading="DIA_R"
              width={6}
            />
          )}

          {pgDependentFields.ellh === true && <FieldWrapper
            name="ellh"
            type="text"
            component={TextInput}
            placeholder="ELLH_R"
            disabled={!rightEyeCheckbox}
            mandatory={false}
            heading="ELLH_R"
            width={6}
          />}
          {rightEyeCheckbox === true && swing === true && (
            <FieldWrapper
              name="swing"
              type="text"
              component={TextInput}
              placeholder="SWING_R"
              disabled={!rightEyeCheckbox || !swing}
              mandatory={false}
              heading="SWING_R"
              width={6}
            />
          )}

          <FieldWrapper
            name="mbase"
            type="select"
            component={CustomSelect}
            placeholder="MBASE_R"
            disabled={!rightEyeCheckbox}
            labelInValue={labelInValue}
            data={mbaseList}
            showSearch={true}
            getSelectedValue={this.handleMbase}
            mandatory={false}
            heading="MBASE_R"
            width={6}
            allowClear={true}
          />

          <FieldWrapper
            name="ct"
            type="text"
            component={TextInput}
            placeholder="CT_R"
            disabled={!rightEyeCheckbox}
            handleOnBlur={this.handleCtR}
            mandatory={false}
            heading="CT_R"
            width={6}
          />

          <FieldWrapper
            name="et"
            type="text"
            component={TextInput}
            placeholder="ET_R"
            disabled={!rightEyeCheckbox}
            handleOnBlur={this.handleEtr}
            mandatory={false}
            heading="ET_R"
            width={6}
          />

          <FieldWrapper
            name="ed"
            type="text"
            component={TextInput}
            placeholder="ed_R"
            disabled={!rightEyeCheckbox}
            mandatory={false}
            heading="ED_R"
            width={6}
          />
        </Row>
      </Col>
    );
  }
}
const actions = {
  enableRightPrism,
  disableRightPrism,
  transformOnBlur,
  setRightEyeCheckBox,
  changeFieldsWithDia,
  bothEyesEqual,
  checkSumForLnam,
  touch
};
const mapStateToProps = state => ({
  showAddForLnam: state.orderForm.showAddForLnam,
  npdR: state.orderForm.npd,
  monoPdR: state.orderForm.monoPd,
  swing: state.orderForm.swing,
  rightEyeCheckbox: state.orderForm.rightEyeCheckbox,
  leftEyeCheckbox: state.orderForm.leftEyeCheckbox,
  addFieldIncludeFlag: state.orderForm.addFieldIncludeFlag,
  pgDependentFields: state.orderForm.pgDependentFields
});
export default connect(
  mapStateToProps,
  actions
)(RightEyeConfig);
