import React, { Component } from "react";
import moment from "moment";
import { Select, Input, DatePicker, Button, Icon, message } from "antd";
import { searchOrder, exportOrder } from "../../../features/home/api";
import Sidebar from "./Sidebar";
import NavBar from "../../../features/nav/NavBar/NavBar";
import Table from "../../../features/home/tabView/table/Table";
import Paginater from "../../../features/home/tabView/table/Paginater";
import {
  getEmployeeList,
  getDistributorList,
  getRetailerList,
  getDispatchList,
  getLnamList,
  getCustomerList,
  getCoatingList,
  getProductGroupMasterList
} from "../../../features/orderPunching/formUtils";
import { connect } from "react-redux";
import {
  fetchOnHoldOrders,
  onHoldStatusChange,
  getOrderDetailsForPage,
  getOrderDetailsForForm
} from "../../../features/home/homeActions";
import { openModal } from "../../../features/modal/modalActions";
import { labelDataApiCall } from "../../../features/orderLabelling/api";
import { errorToast, successToast, getUserPermissions } from "./genericUtils";
import { reportError } from "./sentryConfig";
import { PermissibleRender } from "@brainhubeu/react-permissible";
const Option = Select.Option;

// Constants
const SEARCH_TYPES = [
  { label: "Order No", value: "order_no" },
  { label: "Reference Id", value: "reference_id" },
  { label: "External Id", value: "external_id" },
  { label: "Product Group", value: "product_group" },
  { label: "Coating Id", value: "coating_id" },
  { label: "Lnam Id", value: "lnam_id" },
  { label: "Customer Id", value: "customer_id" },
  { label: "Distributor Id", value: "distributor_id" },
  { label: "Author Id", value: "author_id" },
  { label: "Retailer Id", value: "retailer_id" },
  { label: "Dispatch Type Id", value: "dispatch_type_id" },
  { label: "All", value: "all_orders" },
  { label: "Label", value: "label" }
];
const STATUS_TYPES = [
  { label: "OnHold", value: "on_hold" },
  { label: "Authorization", value: "pending_auth" },
  { label: "Processing", value: "processing" }
];
const DEFAULT_PAGE_SIZE = 40;

// Component
class SearchOrders extends Component {
  state = {
    selectedSearchType: null,
    areSecondarySearchFieldsActive: false,
    startDate: "",
    endDate: "",
    selectedSearchValue: "",
    orderStatus: STATUS_TYPES[0].value,
    orders: {},
    loading: false,

    author_id_list: [],
    customer_id_list: [],
    dispatch_type_id_list: [],
    lnam_id_list: [],
    coating_id_list: [],
    distributor_id_list: [],
    retailer_id_list: [],
    label_list: [],
    product_group_list: []
  };
  columns = [
    {
      title: "Order No.",
      dataIndex: "internal_order_id",
      sorter: (a, b) => a.internal_order_id - b.internal_order_id,
      sortDirections: ["ascend", "descend"],
      render: (text, record, index) => {
        return this.state.orderStatus === "pending_auth" ? (
          <p
            className="validate-link"
            onClick={() => this.props.getOrderDetailsForForm(record.id, index)}
          >
            {text}
          </p>
        ) : (
            <p
              className="validate-link"
              onClick={() => this.props.getOrderDetailsForPage(record.id, index)}
            >
              {text}
            </p>
          );
      }
    },
    {
      title: "Reference Id",
      dataIndex: "reference_id"
    },
    {
      title: "External Order Id",
      dataIndex: "external_order_id"
    },
    {
      title: "Entry Date & Time",
      dataIndex: "received_date",
      sorter: (a, b) => {
        return new Date(b) - new Date(a);
      },
      sortDirections: ["ascend", "descend"],
      render: text => {
        if (text !== null) {
          return <p>{moment(text).format("YYYY-MM-DD HH:mm")}</p>;
        }
      }
    },
    {
      title: "Coating",
      dataIndex: "coating.value"
    },
    {
      title: "LNAM",
      dataIndex: "lnam.name"
    },
    {
      title: "Customer",
      dataIndex: "customer.name"
    },
    {
      title: "Actions",
      fixed: "right",
      width: 100,
      render: (text, record) => {
        return this.state.orderStatus === "on_hold" ? (
          <div>
            <Icon
              type="check"
              className="tick-icon"
              onClick={() => this.props.onHoldStatusChange(record.id)}
            />
            <Icon
              type="close"
              className="cross-icon"
              onClick={() =>
                this.props.openModal("CancellationModal", {
                  record,
                  actions: ["fetchOnHoldOrders"]
                })
              }
            />
          </div>
        ) : (
            <div>
              <p className="validate-link">action</p>
            </div>
          );
      }
    }
  ];
  componentDidMount() {
    this.setState({
      author_id_list: getEmployeeList(),
      dispatch_type_id_list: getDispatchList(),
      lnam_id_list: getLnamList(),
      customer_id_list: getCustomerList(),
      coating_id_list: getCoatingList(),
      distributor_id_list: getDistributorList(),
      retailer_id_list: getRetailerList(),
      product_group_list: getProductGroupMasterList()
    });
    this.getLabelData();
  }
  transFormLabelData = data => {
    let labelList = [];
    data.forEach(label => {
      let labelItem = {
        key: label.short_code,
        label: label.long_code
      };
      labelList.push(labelItem);
    });
    return labelList;
  };
  getLabelData = async () => {
    labelDataApiCall()
      .then(response => {
        if (response && response.data.response_code === 80) {
          let transformedData = this.transFormLabelData(response.data.data);
          this.setState({ label_list: transformedData });
        } else {
          errorToast("Error", response.data.ui_message);
        }
      })
      .catch(error => {
        errorToast("Error", error.message);
        reportError(error);
      });
  };
  handleSearchTypeChange = selectedSearchType => {
    this.setState({
      selectedSearchType,
      areSecondarySearchFieldsActive: true,
      selectedSearchValue: ""
    });
  };
  handleValueChangeFromDropdown = selectedSearchValue => {
    this.setState({
      selectedSearchValue
    });
  };
  handleDateChange = (name, date) => {
    if (name === "startDate" && !date) {
      this.setState({ [name]: date, endDate: null });
    } else {
      this.setState({
        [name]: date
      });
    }
  };
  handleValueChangeFromTextBox = e => {
    this.setState({
      selectedSearchValue: e.target.value
    });
  };
  handleStatusChange = orderStatus => {
    this.setState({
      orderStatus
    });
  };
  getSearchValueDropdown = selectedSearchType => {
    console.log(
      `${selectedSearchType}_list`,
      this.state[`${selectedSearchType}_list`]
    );
    // TODO: Not a good way to do, but hacking for now
    return this.state[`${selectedSearchType}_list`] || [];
  };
  getSearchValueField = () => {
    const { selectedSearchType, selectedSearchValue } = this.state;
    if (selectedSearchType === "order_no") {
      return (
        <Input
          placeholder={`Write Order No.`}
          size="large"
          onChange={this.handleValueChangeFromTextBox}
          style={{ width: 200, marginLeft: "1.5%" }}
          value={selectedSearchValue}
        />
      );
    } else if (selectedSearchType === "reference_id") {
      return (
        <Input
          placeholder={`Write Reference Id`}
          size="large"
          onChange={this.handleValueChangeFromTextBox}
          style={{ width: 200, marginLeft: "1.5%" }}
          value={selectedSearchValue}
        />
      );
    } else if (selectedSearchType === "external_id") {
      return (
        <Input
          placeholder={`Write External Id`}
          size="large"
          onChange={this.handleValueChangeFromTextBox}
          style={{ width: 200, marginLeft: "1.5%" }}
          value={selectedSearchValue}
        />
      );
    } else if (selectedSearchType !== "all_orders") {
      return (
        <Select
          showSearch
          style={{
            width: 200,
            marginLeft: "1.5%"
          }}
          placeholder="Select value"
          value={selectedSearchValue}
          onChange={this.handleValueChangeFromDropdown}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {this.getSearchValueDropdown(selectedSearchType).map(item => (
            <Option value={item.key} key={item.key}>
              {item.label}
            </Option>
          ))}
        </Select>
      );
    }
  };
  getDateSelectorsAndButtons = () => {
    const {
      selectedSearchValue,
      selectedSearchType,
      startDate,
      endDate
    } = this.state;
    return (
      <React.Fragment>
        <DatePicker
          style={{
            width: 200,
            marginLeft: "1.5%"
          }}
          placeholder="start date"
          format="YYYY-MM-DD"
          disabledDate={current => current && current > moment().endOf("day")}
          onChange={(date, dateString) =>
            this.handleDateChange("startDate", date)
          }
          value={startDate}
          size="large"
        />
        <DatePicker
          style={{
            width: 200,
            marginLeft: "1.5%"
          }}
          placeholder="end date"
          format="YYYY-MM-DD"
          disabledDate={current => current && current > moment().endOf("day")}
          onChange={(date, dateString) =>
            this.handleDateChange("endDate", date)
          }
          disabled={startDate ? false : true}
          value={endDate}
          size="large"
        />
        <Button
          style={{
            height: "38px",
            marginLeft: "1.5%"
          }}
          disabled={
            selectedSearchType === "all_orders" ? false : !selectedSearchValue
          }
          type="primary"
          onClick={() => this.searchOrder("1")}
        >
          Search
        </Button>
        <Button
          style={{
            height: "38px",
            marginLeft: "1.5%"
          }}
          type="primary"
          onClick={this.clearSearch}
        >
          Clear
        </Button>
      </React.Fragment>
    );
  };
  exportOrder = () => {
    const {
      selectedSearchType,
      selectedSearchValue,
      orderStatus,
      startDate,
      endDate
    } = this.state;

    const formatedStartDate = startDate ? startDate.format("YYYY-MM-DD") : null;
    const formatedEndDate = endDate ? endDate.format("YYYY-MM-DD") : null;
    successToast("Success", "You will shortly receive an email");
    exportOrder({
      selectedSearchType,
      selectedSearchValue,
      orderStatus,
      startDate: formatedStartDate,
      endDate: formatedEndDate
    });
  };
  searchOrder = pageNo => {
    this.setState({
      loading: true
    });
    const {
      selectedSearchType,
      selectedSearchValue,
      orderStatus,
      startDate,
      endDate
    } = this.state;

    const formatedStartDate = startDate ? startDate.format("YYYY-MM-DD") : null;
    const formatedEndDate = endDate ? endDate.format("YYYY-MM-DD") : null;

    searchOrder({
      selectedSearchType,
      selectedSearchValue,
      orderStatus,
      startDate: formatedStartDate,
      endDate: formatedEndDate,
      pageNo
    })
      .then(response => {
        this.setState({
          orders: response,
          loading: false
        });
      })
      .catch(e => {
        this.setState({
          loading: false,
          error: e.message
        });
        message.error("Oops! Some error occured");
      });
  };
  clearSearch = () => {
    this.setState({
      selectedSearchType: "",
      areSecondarySearchFieldsActive: false,
      value: "",
      status: "",
      orders: {},
      startDate: "",
      endDate: ""
    });
  };

  render() {
    const {
      selectedSearchType,
      areSecondarySearchFieldsActive,
      loading,
      orders,
      orderStatus,
      selectedSearchValue,
      startDate,
      endDate
    } = this.state;
    const userPermissions = getUserPermissions();
    return (
      <div>
        <Sidebar />
        <NavBar />
        <div>
          <h1
            style={{
              marginLeft: "10%",
              marginTop: "5%",
              display: "inline-flex"
            }}
          >
            Search Orders
          </h1>

          <PermissibleRender
            userPermissions={userPermissions}
            requiredPermissions={["can_export_data"]}
          >
            <Button
              style={{
                height: "38px",
                marginLeft: "58%"
              }}
              disabled={
                startDate !== "" &&
                  endDate !== "" &&
                  startDate !== null &&
                  endDate !== null
                  ? false
                  : true
              }
              type="primary"
              onClick={this.exportOrder}
            >
              Export
            </Button>
          </PermissibleRender>
        </div>

        <div style={{ marginLeft: "10%", marginTop: "2%", display: "flex" }}>
          {/* Search Type Dropdown */}
          <Select
            style={{ width: 200 }}
            placeholder="Select status"
            value={orderStatus}
            onChange={this.handleStatusChange}
          >
            {STATUS_TYPES.map((statusType, index) => (
              <Option key={index} value={statusType.value}>
                {statusType.label}
              </Option>
            ))}
          </Select>

          {/* Search Value Field */}
          <Select
            style={{ width: 200, marginLeft: "1.5%" }}
            placeholder="Select search type"
            value={selectedSearchType}
            onChange={this.handleSearchTypeChange}
          >
            {SEARCH_TYPES.map((searchType, index) => (
              <Option key={index} value={searchType.value}>
                {searchType.label}
              </Option>
            ))}
          </Select>

          {/* Search Value Field */}
          {areSecondarySearchFieldsActive ? this.getSearchValueField() : null}

          {/* Date Selectors */}
          {areSecondarySearchFieldsActive
            ? this.getDateSelectorsAndButtons()
            : null}
        </div>
        <div style={{ marginLeft: "10%", marginTop: "3%", width: "86%" }}>
          <Table
            columns={this.columns}
            loading={loading}
            data={orders.results}
            pagination={false}
            bordered={true}
            scroll={{ x: 1300 }}
            size="small"
          />
          <div style={{ textAlign: "center", marginTop: "4%" }}>
            <Paginater
              defaultCurrent={1}
              total={orders.count}
              onChange={this.searchOrder}
              defaultPageSize={DEFAULT_PAGE_SIZE}
            />
          </div>
        </div>
      </div>
    );
  }
}

const actions = {
  fetchOnHoldOrders,
  onHoldStatusChange,
  getOrderDetailsForPage,
  getOrderDetailsForForm,
  openModal
  // fetchFormData
};
const mapStateToProps = state => ({
  // loading: state.home.loadingOnHoldOrders,
  // orders: state.home.onHoldOrders,
  // activeTabKey: state.home.activeTabKey
});
export default connect(
  mapStateToProps,
  actions
)(SearchOrders);
