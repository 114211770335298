export const fieldList = [
  { name: "customer", type: "selectInput" },
  { name: "product_group", type: "selectInput" },
  { name: "distributor", type: "selectInput" },
  { name: "retailer", type: "selectInput" },
  { name: "received_by", type: "selectInput" },
  { name: "entered_by", type: "selectInput" },
  { name: "shipping_address", type: "selectInput" },
  { name: "dispatch_type", type: "selectInput" },
  { name: "manufacturing_business_unit", type: "selectInput" },
  { name: "lnam", type: "selectInput" },
  { name: "coating", type: "selectInput" },
  { name: "corridor", type: "selectInput" },
  { name: "fitting_height", type: "selectInput" },
  { name: "reference_id", type: "textInput" },
  { name: "patient", type: "textInput" },
  { name: "external_order_id", type: "textInput" },
  { name: "shipping_same_as_billing", type: "radioInput" },
  { name: "shipping_address", type: "selectInput" },
  { name: "received_date", type: "dateInput" },
  { name: "urgent", type: "radioInput" },
  { name: "cto", type: "radioInput" },
  { name: "clinit", type: "textInput" },
  { name: "promised_date", type: "dateInput" },
  { name: "status", type: "textInput" },
  { name: "reason", type: "textInput" },
  { name: "source", type: "textInput" },
  { name: "product_group_owner", type: "textInput" },
  {
    name: "right_lens_config",
    type: "obj",
    child: [
      { name: "sph", type: "textInput" },
      { name: "cyl", type: "textInput" },
      { name: "axis", type: "textInput" },
      { name: "add", type: "textInput" },
      { name: "dia", type: "textInput" },
      { name: "ellh", type: "textInput" },
      { name: "mbase", type: "selectInput" },
      { name: "swing", type: "textInput" },
      { name: "et", type: "textInput" },
      { name: "ct", type: "textInput" },
      { name: "ed", type: "textInput" }
    ]
  },
  {
    name: "left_lens_config",
    type: "obj",
    child: [
      { name: "sph", type: "textInput" },
      { name: "cyl", type: "textInput" },
      { name: "axis", type: "textInput" },
      { name: "add", type: "textInput" },
      { name: "dia", type: "textInput" },
      { name: "ellh", type: "textInput" },
      { name: "mbase", type: "selectInput" },
      { name: "swing", type: "textInput" },
      { name: "et", type: "textInput" },
      { name: "ct", type: "textInput" },
      { name: "ed", type: "textInput" }
    ]
  },
  {
    name: "frame",
    type: "obj",
    child: [
      { name: "mono_pd_r", type: "textInput" },
      { name: "mono_pd_l", type: "textInput" },
      { name: "npd_r", type: "textInput" },
      { name: "npd_l", type: "textInput" },
      { name: "frame_type", type: "selectInput" },
      { name: "a_width", type: "textInput" },
      { name: "b_width", type: "textInput" },
      { name: "dbl", type: "textInput" },
      { name: "panto", type: "textInput" },
      { name: "bvd", type: "textInput" },
      { name: "ztilt", type: "textInput" },
      { name: "right_prism", type: "obj" },
      { name: "left_prism", type: "obj" }
    ]
  },
  {
    name: "add_ons",
    type: "obj",
    child: [
      { name: "fitting_received", type: "textInput" },
      { name: "special_instructions", type: "textInput" },
      { name: "in_out", type: "textInput" },
      { name: "other_charges", type: "textInput" },
      { name: "tool_charges", type: "textInput" },
      { name: "specification", type: "selectInput" },
      { name: "tint", type: "selectInput" }
    ]
  }
];

const checkBoxFields = [
  {
    parent: "right_lens_config",
    name: "re"
  },
  {
    parent: "left_lens_config",
    name: "le"
  },
  {
    parent: "right_lens_config",
    name: "both_eyes_equal"
  },
  {
    parent: "add_ons",
    name: "tint_needed"
  },
  {
    parent: "add_ons",
    name: "fitting_needed"
  }
];
export const checkCheckInputs = data => {
  const payload = { ...data };
  checkBoxFields.forEach(field => {
    if (
      payload[field.parent] !== undefined &&
      payload[field.parent][field.name] === false
    ) {
      delete payload[field.parent][field.name];
    }
    if (
      payload[field.parent] !== undefined &&
      Object.keys(payload[field.parent]).length === 0
    ) {
      delete payload[field.parent];
    }
  });
  return payload;
};
export const payloadForOrderPunching = formData => {
  const payload = {};
  const data = checkCheckInputs(formData);
  fieldList.forEach(field => {
    if (data[field.name] !== undefined) {
      if (
        field.type === "textInput" ||
        field.type === "radioInput" ||
        field.type === "dateInput"
      ) {
        payload[field.name] = data[field.name];
      } else if (field.type === "selectInput") {
        if (data[field.name]["key"] !== undefined) {
          payload[field.name] = data[field.name]["key"];
        } else {
          payload[field.name] = data[field.name];
        }
      } else if (field.type === "obj") {
        payload[field.name] = {};
        field.child.forEach(childField => {
          if (data[field.name][childField.name] !== undefined) {
            if (
              childField.type === "textInput" ||
              childField.type === "radioInput" ||
              childField.type === "obj"
            ) {
              payload[field.name][childField.name] =
                data[field.name][childField.name];
            } else if (childField.type === "selectInput") {
              payload[field.name][childField.name] =
                data[field.name][childField.name]["key"];
            }
          }
        });
      }
    }
  });
  return payload;
};
