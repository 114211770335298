import React, { Component } from "react";
import { Row, Col } from "antd";
import Commons from "../../orderDetails/Commons";
import Barcode from "react-barcode";
import RightLensConfig from "../../orderDetails/RightLensConfig";
import LeftLensConfig from "../../orderDetails/LeftLensConfig";
import {
  makeCommonsData,
  makeRightEyeConfig,
  makeLeftEyeConfig
} from "../../orderPunching/formUtils";
import {
  stockOrderCommonsHeaderMap,
  rightLensStockHeaderMap,
  leftLensStockHeaderMap
} from "../../orderPunching/constants";
class StockOrderDetails extends Component {
  state = {};
  render() {
    const { details, highlightedFields } = this.props;
    const commonsData = makeCommonsData(details);
    const rightLensConfig = makeRightEyeConfig(details);
    const leftLensConfig = makeLeftEyeConfig(details);
    return (
      <div className="order-details-container">
        <h3>Customer Section</h3>
        <Commons
          data={commonsData}
          highlightedFields={highlightedFields}
          headerMap={stockOrderCommonsHeaderMap}
        />
        <h3>Lens Section</h3>
        <Row>
          <RightLensConfig
            data={rightLensConfig.right_lens_config}
            highlightedFields={highlightedFields}
            headerMap={rightLensStockHeaderMap}
          />
          <LeftLensConfig
            data={leftLensConfig.left_lens_config}
            highlightedFields={highlightedFields}
            headerMap={leftLensStockHeaderMap}
          />
        </Row>
        <Row>
          <Col span={12}>
            <Barcode
              value={details.internal_order_id}
              displayValue={false}
              format="CODE39"
              height={44}
              width={2}
            />
          </Col>
          <Col span={12}>
            {details.external_order_id !== null && (
              <Barcode
                value={details.external_order_id}
                displayValue={false}
                format="CODE39"
                height={44}
                width={2}
              />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default StockOrderDetails;
