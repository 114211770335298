import React, { Component } from "react";
import { connect } from "react-redux";
import { getNotificationList } from "./notificationActions";
import { Card, Row, Col } from "antd";
import LoaderComponent from "../../app/common/util/LoaderComponent";
import moment from "moment";
import Paginater from "../home/tabView/table/Paginater";
class NotificationList extends Component {
  state = {};
  componentDidMount() {
    this.props.getNotificationList();
  }
  render() {
    const { notificationList, fetchingList, getNotificationList } = this.props;
    if (fetchingList === true) {
      return <LoaderComponent />;
    }
    return (
      <div style={{ margin: "8% 30%" }}>
        {notificationList &&
          notificationList.results &&
          notificationList.results.map((notification, index) => {
            return (
              <Card
                //size="small"
                title={notification.notification_type}
                style={{ width: 600, marginBottom: "2%" }}
                key={index}
              >
                <Row gutter={2}>
                  <Col span={5}>
                    <p>
                      Username: <strong>{notification.author.username}</strong>
                    </p>
                  </Col>
                  <Col span={8}>
                    <p>
                      Email:{" "}
                      <strong>
                        {notification.author.email
                          ? notification.author.email
                          : ""}
                      </strong>
                    </p>
                  </Col>
                  <Col span={11}>
                    <p>
                      Created On:{" "}
                      <strong>
                        {moment(notification.created_on).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </strong>
                    </p>
                  </Col>
                </Row>
                <p style={{ marginTop: "2%" }}>{notification.text}</p>
              </Card>
            );
          })}
        <div style={{ margin: "5% 0 1% 0", textAlign: "center" }}>
          <Paginater
            defaultCurrent={1}
            total={notificationList.count}
            onChange={getNotificationList}
            defaultPageSize={40}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  fetchingList: state.notification.fetchingList,
  notificationList: state.notification.notificationList
});
const actions = {
  getNotificationList
};
export default connect(
  mapStateToProps,
  actions
)(NotificationList);
