import axios from "axios";
import { makeUrl, getItem } from "../../app/common/util/genericUtils";

export const whatsAppOrdersApiCall = page => {
  const user = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/whatsapp/order/list"),
    params: {
      page
    },
    headers: {
      Authorization: `Token ${user.auth_token}`
    }
  });
};

export const imageDataApiCall = id => {
  const user = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/whatsapp/order/detail/" + id),
    headers: {
      Authorization: `Token ${user.auth_token}`
    }
  });
};
export const internalOrderIdApiCall = () => {
  const user = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/whatsapp/order/detail/"),
    headers: {
      Authorization: `Token ${user.auth_token}`
    }
  });
};
export const archiveOrder = id => {
  const user = getItem("suprolUser");
  return axios({
    method: "PUT",
    url: makeUrl("/whatsapp/order/cleanup/" + id),
    headers: {
      Authorization: `Token ${user.auth_token}`
    }
  });
};
export const submitWhatsappOrder = (data, id) => {
  const user = getItem("suprolUser");
  return axios({
    method: "PUT",
    url: makeUrl("/whatsapp/order/create/" + id),
    data: {
      order: data
    },
    headers: {
      Authorization: `Token ${user.auth_token}`
    }
  });
};
