import {
  LOGIN_ACTION_START,
  LOGIN_ACTION_FINISH,
  LOGIN_ACTION_ERROR,
  LOGOUT_USER
} from "./authConstants";
import { createReducer } from "../../app/common/util/reducerUtil";

const initialState = {
  currentUser: {},
  authenticated: false
};

export const loginActionStart = (state, payload) => {
  return {
    ...state
  };
};
export const loginActionFinish = (state, payload) => {
  return {
    ...state,
    authenticated: true,
    currentUser: payload
  };
};
export const loginActionError = (state, payload) => {
  return {
    ...state,
    authenticated: false,
    currentUser: {}
  };
};
export const logoutUser = (state, payload) => {
  return {
    ...state,
    authenticated: false,
    currentUser: {}
  };
};

export default createReducer(initialState, {
  [LOGIN_ACTION_START]: loginActionStart,
  [LOGIN_ACTION_FINISH]: loginActionFinish,
  [LOGIN_ACTION_ERROR]: loginActionError,
  [LOGOUT_USER]: logoutUser
});
