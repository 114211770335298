import React, { Component } from "react";
import { Modal } from "antd";
import { connect } from "react-redux";
import { closeModal } from "./modalActions";
import LinkedOrderForm from "../orderLinking/modal/LinkedOrderForm";

class LinkedOrderModal extends Component {
  state = {};
  render() {
    const { closeModal, linkingOrderId, type } = this.props;
    return (
      <Modal
        title="Order Linking"
        visible={true}
        onCancel={closeModal}
        onOk={closeModal}
        footer={null}
        width="25%"
      >
        <LinkedOrderForm linkingOrderId={linkingOrderId} type={type} />
      </Modal>
    );
  }
}
const actions = { closeModal };
const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  actions
)(LinkedOrderModal);
