import { getItem, makeUrl } from "../../app/common/util/genericUtils";
import axios from "axios";

export const createNotificationApiCall = data => {
  const token = getItem("suprolUser");
  return axios({
    method: "POST",
    url: makeUrl("/orders/create/notification"),
    data,
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};

export const notificationListApiCall = page => {
  const token = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/orders/list/notification"),
    params: {
      page
    },
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};
