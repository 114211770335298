import React, { Component } from "react";
import { Button } from "antd";
import { Form } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import {
  combineValidators,
  composeValidators,
  isRequired,
  hasLengthLessThan,
  hasLengthGreaterThan,
  isNumeric
} from "revalidate";

import TextInput from "../../../app/common/form/TextInput";
import { validatefromHome } from "../orderActions";
class ValidateFromHome extends Component {
  handleFormSubmit = data => {
    // console.log(data);
    this.props.validatefromHome(data.reference_id);
  };
  render() {
    const { handleSubmit, invalid, loadingFormData } = this.props;
    return (
      <div style={{ padding: "2% 4% 0% 74%" }}>
        <Form error size="large" autoComplete="off" style={{ display: "flex" }}>
          <Field
            name="reference_id"
            component={TextInput}
            type="text"
            placeholder="Reference Id"
            //   autoFocus={true}
            style={{ height: "39px", width: "150px" }}
          />

          <Button
            block
            type="primary"
            style={{ height: "38px", marginLeft: "8%" }}
            onClick={handleSubmit(this.handleFormSubmit)}
            disabled={invalid}
            loading={loadingFormData}
          >
            Submit
          </Button>
        </Form>
      </div>
    );
  }
}
const validate = combineValidators({
  reference_id: composeValidators(
    isNumeric({ message: "Numeric value only" }),
    isRequired({ message: "Reference Id is required" }),
    hasLengthLessThan(21)({
      message: "Max. 20 characters allowed"
    }),
    hasLengthGreaterThan(7)({ message: "Min. 8 characters" })
  )()
});
const actions = {
  validatefromHome
};
const mapStateToProps = state => ({
  loadingFormData: state.orderForm.loadingOrderFromReferenceId,
  orderDetails: state.orderForm.orderDetailsFromReferenceId
});
export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "validateOrderIdForm",
    validate,
    enableReinitialize: true
  })(ValidateFromHome)
);
