import { createReducer } from "../../app/common/util/reducerUtil";
import {
  AUTH_CARD_PRINTING_START,
  AUTH_CARD_PRINTING_SUCCESS,
  AUTH_CARD_PRINTING_FAILURE
} from "./authCardConstants";

const initialState = {
  authCardLoader: false
};

export const authCardPrintingStart = state => {
  return {
    ...state,
    authCardLoader: true
  };
};
export const authCardPrintingSuccess = state => {
  return {
    ...state,
    authCardLoader: false
  };
};
export const authCardPrintingFailure = state => {
  return {
    ...state,
    authCardLoader: false
  };
};

export default createReducer(initialState, {
  [AUTH_CARD_PRINTING_START]: authCardPrintingStart,
  [AUTH_CARD_PRINTING_SUCCESS]: authCardPrintingSuccess,
  [AUTH_CARD_PRINTING_FAILURE]: authCardPrintingFailure
});
