import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "../table/Table";
import { fetchBlankSelectionOrders } from "../../homeActions";
import { getOrderForBlankSelection } from "../../../blankSelection/blankSelectionActions";
import Paginater from "../table/Paginater";
import moment from "moment";
import { Tag, Button } from "antd";
import { openModal } from "../../../modal/modalActions";

class BlankSelectionTab extends Component {
  state = {};
  componentDidMount() {
    //  if (this.props.activeTabKey === "4") {
    //  this.props.fetchBlankSelectionOrders();
    //}
  }
  columns = [
    {
      title: "Order No.",
      dataIndex: "internal_order_id",
      sorter: (a, b) => a.internal_order_id - b.internal_order_id,
      sortDirections: ["ascend"],
      render: (text, record, index) => (
        <p
          className="validate-link"
          onClick={() =>
            this.props.getOrderForBlankSelection({
              internal_order_id: record.internal_order_id
            })
          }
        >
          {text}
        </p>
      )
    },
    {
      title: "Reference Id",
      dataIndex: "reference_id"
    },
    {
      title: "Entry Date & Time",
      dataIndex: "received_date",
      render: text => {
        if (text !== null) {
          return <p>{moment(text).format("YYYY-MM-DD HH:mm")}</p>;
        }
      }
    },
    {
      title: "Coating",
      dataIndex: "coating.value"
    },
    {
      title: "LNAM",
      dataIndex: "lnam.name"
    },
    {
      title: "Customer",
      dataIndex: "customer.name"
    },
    {
      title: "Promise Date & Time",
      dataIndex: "promised_date",
      render: text => {
        if (text !== null) {
          return <p>{moment(text).format("YYYY-MM-DD HH:mm")}</p>;
        }
      }
    },
    {
      title: "Labels",
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {record.label.length > 0 &&
              record.label.map((item, index) => {
                return (
                  <Tag color="blue" key={index}>
                    {item.label.short_code}
                  </Tag>
                );
              })}
          </div>
        );
      }
    },
    {
      title: "Current Status",
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {record.lensware_status.length > 0 &&
              record.lensware_status.map((item, index) => {
                return (
                  <Tag color="blue" key={index}>
                    {item.status.value}
                  </Tag>
                );
              })}
          </div>
        );
      }
    },
    {
      title: "Tray No.",
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {record.lensware_status.length > 0 &&
              record.lensware_status.map((item, index) => {
                return <p key={index}>{item.tray}</p>;
              })}
          </div>
        );
      }
    },
    {
      title: "Transport Mode",
      dataIndex: "dispatch_type.value"
    },
    {
      title: "Right Dia",
      dataIndex: "right_lens_config.dia"
    },
    {
      title: "Left Dia",
      dataIndex: "left_lens_config.dia"
    },
    {
      title: "Right Ellh",
      dataIndex: "right_lens_config.ellh"
    },
    {
      title: "Left Ellh",
      dataIndex: "left_lens_config.ellh"
    }
  ];
  render() {
    const {
      loading,
      orders,
      fetchBlankSelectionOrders,
      getOrderForBlankSelection
    } = this.props;

    return (
      <div>
        <Table
          columns={this.columns}
          loading={loading}
          data={orders.results}
          defaultPageSize={40}
          pagination={false}
          bordered={true}
          scroll={{ x: 1300 }}
          size="small"
        />
        <div style={{ textAlign: "center", marginTop: "4%" }}>
          <Paginater
            defaultCurrent={1}
            total={orders.count}
            onChange={fetchBlankSelectionOrders}
            defaultPageSize={40}
          />
        </div>
      </div>
    );
  }
}

const actions = {
  fetchBlankSelectionOrders,
  getOrderForBlankSelection,
  openModal
};
const mapStateToProps = state => ({
  loading: state.home.loadingBlankSelectionOrders,
  orders: state.home.blankSelectionOrders,
  activeTabKey: state.home.activeTabKey
});
export default connect(
  mapStateToProps,
  actions
)(BlankSelectionTab);
