import React, { Component } from "react";
import { Menu, Container, Dropdown, Image, Label } from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import { logoutUser } from "../../auth/authActions";
import { connect } from "react-redux";
import {
  getItem,
  deleteDataOnLogOut,
  getHourDifference
} from "../../../app/common/util/genericUtils";
import { resetHomeState } from "../../home/homeActions";
const actions = {
  logoutUser,
  resetHomeState
};
const mapStateToProps = state => ({
  latestNotification: state.notification.latestNotification
});

class NavBar extends Component {
  handleLogOut = () => {
    deleteDataOnLogOut();
    this.props.logoutUser();
    this.props.history.push("/login");
    this.props.resetHomeState();
  };
  render() {
    const user = getItem("suprolUser");
    const { latestNotification } = this.props;
    const hourDiff =
      latestNotification !== null
        ? getHourDifference(latestNotification.created_on)
        : 25;
    return (
      <Menu inverted fixed="top" id="navbar-style">
        {latestNotification && hourDiff < 25 && (
          <marquee behavior="scroll" direction="left">
            <Label
              basic
              color="purple"
              style={{
                margin: "13px 0 0 10%",
                height: "1%"
                //whiteSpace: "nowrap",
                //overflow: "hidden",
                //  textOverflow: "ellipsis"
              }}
            >
              {latestNotification.text}
            </Label>
          </marquee>
        )}

        <Container>
          <Menu.Item position="right">
            <Image avatar spaced="right" src={"/assets/user.png"} />
            <Dropdown
              pointing="top left"
              text={user.user.username}
              style={{ color: "black" }}
            >
              <Dropdown.Menu>
                <Dropdown.Item text="Add New" icon="plus" />
                <Dropdown.Item
                  text="Settings"
                  icon="settings"
                  as={Link}
                  to="/settings"
                />
                <Dropdown.Item
                  text="Log Out"
                  icon="power"
                  onClick={this.handleLogOut}
                />
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        </Container>
      </Menu>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )(NavBar)
);
