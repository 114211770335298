import {
  CREATE_NOTIFICATION_START,
  CREATE_NOTIFICATION_SUCCESS,
  CREATE_NOTIFICATION_FAILURE,
  GET_NOTIFICATION_LIST_START,
  GET_NOTIFICATION_LIST_SUCCESS,
  GET_NOTIFICATION_LIST_FAILURE,
  SET_LATEST_NOTIFICATION
} from "./notificationConstants";
import { createNotificationApiCall, notificationListApiCall } from "./api";
import { errorToast, successToast } from "../../app/common/util/genericUtils";
import { push } from "connected-react-router";
import { closeModal } from "../modal/modalActions";
import { reportError } from "../../app/common/util/sentryConfig";

export const createNotificationStart = () => {
  return {
    type: CREATE_NOTIFICATION_START
  };
};
export const createNotificationSuccess = () => {
  return {
    type: CREATE_NOTIFICATION_SUCCESS
  };
};
export const createNotificationFailure = () => {
  return {
    type: CREATE_NOTIFICATION_FAILURE
  };
};

export const createNotification = data => async dispatch => {
  dispatch(createNotificationStart());
  return createNotificationApiCall(data)
    .then(response => {
      if (response && response.data.response_code === 80) {
        dispatch(createNotificationSuccess());
        dispatch(setLatestNotification(response.data.data));
        successToast("Success", "Notification Created");
        dispatch(closeModal());
      } else {
        dispatch(createNotificationFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch(error => {
      dispatch(createNotificationFailure());
      errorToast("Error", error.message);
      reportError(error);
      console.log(error);
    });
};

export const getNotificationListStart = () => {
  return {
    type: GET_NOTIFICATION_LIST_START
  };
};
export const getNotificationListSuccess = data => {
  return {
    type: GET_NOTIFICATION_LIST_SUCCESS,
    payload: data
  };
};
export const getNotificationListFailure = () => {
  return {
    type: GET_NOTIFICATION_LIST_FAILURE
  };
};

export const getNotificationList = page => async dispatch => {
  dispatch(getNotificationListStart());
  return notificationListApiCall(page)
    .then(response => {
      if (response && response.data.response_code === 80) {
        console.log("noti", response);
        dispatch(getNotificationListSuccess(response.data.data));
        dispatch(setLatestNotification(response.data.data.results[0]));
      } else {
        dispatch(getNotificationListFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch(error => {
      dispatch(getNotificationListFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};

export const setLatestNotification = data => {
  return {
    type: SET_LATEST_NOTIFICATION,
    payload: data
  };
};
