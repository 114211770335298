export const GET_WHATSAPP_ORDERS = "GET_WHATSAPP_ORDERS";
export const GET_WHATSAPP_ORDERS_SUCCESS = "GET_WHATSAPP_ORDERS_SUCCESS";
export const GET_WHATSAPP_ORDERS_FAILURE = "GET_WHATSAPP_ORDERS_FAILURE";
export const ARCHIVE_ORDER_START = "ARCHIVE_ORDER_START";
export const ARCHIVE_ORDER_SUCCESS = "ARCHIVE_ORDER_SUCCESS";
export const ARCHIVE_ORDER_FAILURE = "ARCHIVE_ORDER_FAILURE";
export const PUNCH_WHATSAPP_ORDER_START = "PUNCH_WHATSAPP_ORDER_START";
export const PUNCH_WHATSAPP_ORDER_SUCCESS = "PUNCH_WHATSAPP_ORDER_SUCCESS";
export const PUNCH_WHATSAPP_ORDER_FAILURE = "PUNCH_WHATSAPP_ORDER_FAILURE";
export const SET_DATA_FOR_FORM = "SET_DATA_FOR_FORM";
export const SET_CURRENT_ACTIVE_ORDER = "SET_CURRENT_ACTIVE_ORDER";
export const SET_MULTIPLE_ORDER_FLAG = "SET_MULTIPLE_ORDER_FLAG";
export const GET_IMAGE_DATA_START = "GET_IMAGE_DATA_START";
export const GET_IMAGE_DATA_SUCCESS = "GET_IMAGE_DATA_SUCCESS";
export const GET_IMAGE_DATA_FAILURE = "GET_IMAGE_DATA_FAILURE";
export const GET_INTERNAL_ORDER_ID_START = "GET_INTERNAL_ORDER_ID_START";
export const GET_INTERNAL_ORDER_ID_SUCCESS = "GET_INTERNAL_ORDER_ID_SUCCESS";
export const GET_INTERNAL_ORDER_ID_FAILURE = "GET_INTERNAL_ORDER_ID_FAILURE";
