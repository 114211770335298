export const mbaseList = [
  { label: "0", key: "0" },
  { label: "2", key: "2" },
  { label: "4", key: "4" },
  { label: "5", key: "5" },
  { label: "6", key: "6" },
  { label: "7", key: "7" },
  { label: "8", key: "8" },
  { label: "10", key: "10" },
  { label: "12", key: "12" },
  { label: "14", key: "14" },
  { label: "16", key: "16" },
  { label: "18", key: "18" },
  { label: "20", key: "20" },
  { label: "22", key: "22" }
];

export const messages = [
  "Place an oval order",
  "Place an order with prism",
  "Place an order that is a Prolux Order",
  "Get an order tinted or fitting. You can place the order online, but will need to follow up with us on a phone call",
  "Place an order with a specific base"
];
export const commonsKeyHeaderMap = {
  internal_order_id: "Internal Order Id",
  reference_id: "Reference Id",
  customer: "Customer Details",
  distributor: "Distributor Name",
  retailer: "Retailer Name",
  patient: "Patient Name",
  external_order_id: "External Order Id",
  dispatch_type: "Dispatch Type",
  received_by: "Order Received By",
  entered_by: "Order Entered By",
  shipping_same_as_billing: "Shipping Address Same as Billing",
  shipping_address: "Shipping Address",
  manufacturing_business_unit: "Manufacturing Location",
  received_date: "Order Received Date",
  received_hour: "Order Received Time(Hour)",
  received_minute: "Order Received Time(Minute)",
  urgent: "Urgent",
  lnam: "lnam",
  coating: "Coating",
  corridor: "Corridor",
  fitting_height: "Fitting Height",
  promised_date: "Promised Date",
  promised_hour: "Promised Time(Hour)",
  promised_minute: "Promised Time(Hour)",
  clinit: "Customer Initials",
  cto: "CTO",
  product_group: 'Product Group'
};
export const rightLensHeaderMap = {
  sph: "SPH_R",
  cyl: "CYL_R",
  axis: "AXIS_R",
  add: "ADD_R",
  dia: "DIA_R",
  ellh: "ELLH_R",
  swing: "SWING_R",
  mbase: "MBASE_R",
  ct: "CT_R",
  et: "ET_R",
  ed: "ED_R"
};
export const leftLensHeaderMap = {
  sph: "SPH_L",
  cyl: "CYL_L",
  axis: "AXIS_L",
  add: "ADD_L",
  dia: "DIA_L",
  ellh: "ELLH_L",
  swing: "SWING_L",
  mbase: "MBASE_L",
  ct: "CT_L",
  et: "ET_L",
  ed: "ED_L"
};
export const frameHeaderMap = {
  mono_pd_r: "MONO_PD_R",
  mono_pd_l: "MONO_PD_L",
  npd_r: "NPD_R",
  npd_l: "NPD_L",
  frame_type: "FRAME TYPE",
  a_width: "A-WIDTH",
  b_width: "B-WIDTH",
  dbl: "DBL",
  panto: "PANTO TILT",
  ztilt: "FACE FORM ANGLE",
  bvd: "BACK VERTEX DISTANCE"
};
export const rightPrismHeaderMap = {
  prvm1: "PRISM1_R",
  prva1: "BASE1_R",
  prvm2: "PRISM2_R",
  prva2: "BASE2_R"
};
export const leftPrismHeaderMap = {
  prvm1: "PRISM1_L",
  prva1: "BASE1_L",
  prvm2: "PRISM2_L",
  prva2: "BASE2_L"
};
export const addOnsHeaderMap = {
  specification: "TINT SPECIFICATION",
  tint: "TINT DESCRIPTION",
  fitting_received: "FITTING STATUS",
  special_instructions: "SPECIAL INSTRUCTIONS",
  in_out: "IN OUT",
  other_charges: "OTHER CHARGES",
  tool_charges: "TOOL CHARGES"
};
export const stockOrderCommonsHeaderMap = {
  internal_order_id: "Internal Order Id",
  reference_id: "Reference Id",
  external_order_id: "External Order Id",
  customer: "Customer Details",
  distributor: "Distributor Name",
  retailer: "Retailer Name",
  received_date: "Order Received Date",
  urgent: "Urgent",
  lnam: "lnam",
  coating: "Coating",
  promised_date: "Promised Date"
};
export const rightLensStockHeaderMap = {
  sph: "SPH_R",
  cyl: "CYL_R",
  axis: "AXIS_R",
  add: "ADD_R"
};
export const leftLensStockHeaderMap = {
  sph: "SPH_L",
  cyl: "CYL_L",
  axis: "AXIS_L",
  add: "ADD_L"
};
export const lnamFromFAlphabet = ["FPL", "FPD", "FPP", "FPA"];
export const lnamForAdd = [
  "ASP861",
  "ASP8C6",
  "ASP8E6",
  "ASPO56",
  "ASPOC5",
  "ASPT58",
  "ASPTC8",
  "ASPW49",
  "ASPW56",
  "ASPW67",
  "ASPWC4",
  "ASPWC5",
  "ASPWC7",
  "ASPWE5",
  "ASPWE7",
  "LENW49",
  "LENWC4",
  "SCN861",
  "SCNAB5",
  "SCNAB6",
  "SCNAG5",
  "SCNAG6",
  "SCNO56",
  "SCNPB4",
  "SCNPB6",
  "SCNPG4",
  "SCNR61",
  "SCNT58",
  "SCNW49",
  "SCNW56",
  "SCNW67",
  "SPH861",
  "SPH8A6",
  "SPH8C6",
  "SPH8E6",
  "SPHAB5",
  "SPHAB6",
  "SPHAF5",
  "SPHAF6",
  "SPHAG5",
  "SPHAG6",
  "SPHAG9",
  "SPHAH5",
  "SPHAM5",
  "SPHAQ5",
  "SPHO56",
  "SPHOC5",
  "SPHPB4",
  "SPHPB6",
  "SPHPF4",
  "SPHPG4",
  "SPHPH4",
  "SPHPH6",
  "SPHPM4",
  "SPHPN4",
  "SPHPP4",
  "SPHPQ4",
  "SPHPY4",
  "SPHR61",
  "SPHRC6",
  "SPHT58",
  "SPHTC8",
  "SPHTD8",
  "SPHV49",
  "SPHW49",
  "SPHW56",
  "SPHW59",
  "SPHW61",
  "SPHW67",
  "SPHWA4",
  "SPHWA5",
  "SPHWC4",
  "SPHWC5",
  "SPHWC7",
  "SPHWD4",
  "SPHWD6",
  "SPHWE4",
  "SPHWE5",
  "SPHWE7",
  "SPHWI4",
  "SPHWL4",
  "SPHWO4",
  "SPHWP4",
  "SPHWR4",
  "SPHX52",
  "SVA861",
  "SVAAB5",
  "SVAAB6",
  "SVAAG5",
  "SVAAG6",
  "SVAO56",
  "SVAPB4",
  "SVAPB6",
  "SVAPG4",
  "SVAR61",
  "SVAT58",
  "SVAW49",
  "SVAW56",
  "SVAW67",
  "SVH861",
  "SVHAB5",
  "SVHAB6",
  "SVHAG5",
  "SVHAG6",
  "SVHO56",
  "SVHPB4",
  "SVHPB6",
  "SVHPG4",
  "SVHR61",
  "SVHT58",
  "SVHW49",
  "SVHW56",
  "SVHW67"
];
