import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs, Icon, Tag, Button } from "antd";
import Table from "../table/Table";
import {
  fetchOnHoldOrders,
  onHoldStatusChange,
  getOrderDetailsForPage
} from "../../homeActions";
import Paginater from "../table/Paginater";
import { openModal } from "../../../modal/modalActions";
import moment from "moment";
import { PermissibleRender } from "@brainhubeu/react-permissible";
import { getUserPermissions } from "../../../../app/common/util/genericUtils";
class OnHoldTab extends Component {
  state = {};
  componentDidMount() {
    // if (this.props.activeTabKey === "1") {
    // this.props.fetchOnHoldOrders();
    //}
  }
  columns = [
    {
      title: "Order No.",
      dataIndex: "internal_order_id",
      sorter: (a, b) => a.internal_order_id - b.internal_order_id,
      sortDirections: ["ascend"],
      render: (text, record, index) => (
        <p
          className="validate-link"
          onClick={() => this.props.getOrderDetailsForPage(record.id, index)}
        >
          {text}
        </p>
      )
    },
    {
      title: "Reference Id",
      dataIndex: "reference_id"
    },
    {
      title: "Entry Date & Time",
      dataIndex: "received_date",
      render: text => {
        if (text !== null) {
          return <p>{moment(text).format("YYYY-MM-DD HH:mm")}</p>;
        }
      }
    },
    {
      title: "Coating",
      dataIndex: "coating.value"
    },
    {
      title: "LNAM",
      dataIndex: "lnam.name"
    },
    {
      title: "Customer",
      dataIndex: "customer.name"
    },
    {
      title: "Order Source",
      dataIndex: "source"
    },
    {
      title: "Labels",
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {record &&
              record.label.length > 0 &&
              record.label.map((item, index) => {
                return (
                  <Tag color="blue" key={index}>
                    {item.label.short_code}
                  </Tag>
                );
              })}
          </div>
        );
      }
    },
    {
      title: "Hold Reason",
      dataIndex: "reason"
    },

    {
      title: "Actions",
      fixed: "right",
      width: 150,
      render: (text, record) => {
        const data = {
          record,
          actions: ["fetchOnHoldOrders"]
        };
        const userPermissions = getUserPermissions();
        return (
          <div>
            <Icon
              type="check"
              className="tick-icon"
              onClick={() => this.props.onHoldStatusChange(record.id)}
            />
            <Icon
              type="close"
              className="cross-icon"
              onClick={() => this.props.openModal("CancellationModal", data)}
            />
            <PermissibleRender
              userPermissions={userPermissions}
              requiredPermissions={["can_add_label"]}
            >
              <Button
                style={{ margin: "7% 0 0 5%" }}
                size="small"
                type="primary"
                onClick={() =>
                  this.props.openModal("CreateLabelModal", { id: record.id })
                }
              >
                Add Label
              </Button>
            </PermissibleRender>
            <PermissibleRender
              userPermissions={userPermissions}
              requiredPermissions={["can_link_order"]}
            >
              <Button
                style={{ margin: "7% 0 0 5%" }}
                size="small"
                type="primary"
                onClick={() =>
                  this.props.openModal("LinkedOrderModal", {
                    type: "linkOldOrder",
                    linkingOrderId: record.id
                  })
                }
              >
                Link Order
              </Button>
            </PermissibleRender>
          </div>
        );
      }
    }
  ];
  render() {
    const {
      loading,
      orders,
      fetchOnHoldOrders,
      onHoldStatusChange,
      openModal,
      getOrderDetailsForPage
    } = this.props;

    return (
      <div>
        <Table
          columns={this.columns}
          loading={loading}
          data={orders.results}
          defaultPageSize={40}
          pagination={false}
          bordered={true}
          scroll={{ x: 1300 }}
          size="small"
        />
        <div style={{ textAlign: "center", marginTop: "4%" }}>
          <Paginater
            defaultCurrent={1}
            total={orders.count}
            onChange={fetchOnHoldOrders}
            defaultPageSize={40}
          />
        </div>
      </div>
    );
  }
}

const actions = {
  fetchOnHoldOrders,
  onHoldStatusChange,
  getOrderDetailsForPage,
  openModal
};
const mapStateToProps = state => ({
  loading: state.home.loadingOnHoldOrders,
  orders: state.home.onHoldOrders,
  activeTabKey: state.home.activeTabKey
});
export default connect(
  mapStateToProps,
  actions
)(OnHoldTab);
