import { combineReducers } from "redux";
import { reducer as FormReducer } from "redux-form";
import { connectRouter } from "connected-react-router";
import authReducer from "../../features/auth/authReducer";
import modalReducer from "../../features/modal/modalReducer";
import whatsappOrdersReducer from "../../features/whatsappOrders/whatsappOrderReducer";
import orderFormReducer from "../../features/orderPunching/orderReducer";
import homeReducer from "../../features/home/homeReducer";
import cancellationReducer from "../../features/cancellation/cancellationReducer";
import blankSelectionReducer from "../../features/blankSelection/blankSelectionReducer";
import authCardReducer from "../../features/authCardPrinting/authCardReducer";
import labelReducer from "../../features/labelPrinting/labelPrintingReducer";
import orderLabelReducer from "../../features/orderLabelling/labelReducer";
import linkingReducer from "../../features/orderLinking/linkingReducer";
import notificationReducer from "../../features/internalNotifications/notificationReducer";
const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    form: FormReducer,
    modals: modalReducer,
    auth: authReducer,
    whatsappOrders: whatsappOrdersReducer,
    orderForm: orderFormReducer,
    home: homeReducer,
    cancellation: cancellationReducer,
    blankSelection: blankSelectionReducer,
    orderLabelling: orderLabelReducer,
    linking: linkingReducer,
    notification: notificationReducer,
    authCard: authCardReducer,
    label: labelReducer
  });

export default rootReducer;
