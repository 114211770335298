import {
  CREATE_LABEL_START,
  CREATE_LABEL_SUCCESS,
  CREATE_LABEL_FAILURE,
  GET_LABEL_DATA_START,
  GET_LABEL_DATA_SUCCESS,
  GET_LABEL_DATA_FAILURE
} from "./labelConstants";
import { createLabelApiCall, labelDataApiCall } from "./api";
import { errorToast, successToast } from "../../app/common/util/genericUtils";
import { reportError } from "../../app/common/util/sentryConfig";
import { closeModal } from "../modal/modalActions";

export const getLabelDataStart = () => {
  return {
    type: GET_LABEL_DATA_START
  };
};

export const getLabelDataSuccess = response => {
  return {
    type: GET_LABEL_DATA_SUCCESS,
    payload: response
  };
};
export const getLabelDataFailure = () => {
  return {
    type: GET_LABEL_DATA_FAILURE
  };
};

export const getLabelData = () => async dispatch => {
  dispatch(getLabelDataStart());
  return labelDataApiCall()
    .then(response => {
      if (response && response.data.response_code === 80) {
        dispatch(getLabelDataSuccess(response.data.data));
      } else {
        dispatch(getLabelDataFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch(error => {
      dispatch(getLabelDataFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};

export const createLabelStart = () => {
  return {
    type: CREATE_LABEL_START
  };
};
export const createLabelSuccess = () => {
  return {
    type: CREATE_LABEL_SUCCESS
  };
};
export const createLabelFailure = () => {
  return {
    type: CREATE_LABEL_FAILURE
  };
};

export const createLabel = data => async dispatch => {
  dispatch(createLabelStart());
  console.log(data);
  return createLabelApiCall(data)
    .then(response => {
      if (response && response.data.response_code === 80) {
        dispatch(createLabelSuccess());
        successToast("Success", "Label Added Successfully");
        dispatch(closeModal());
      } else {
        dispatch(createLabelFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch(error => {
      dispatch(createLabelFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};
