import React from "react";
import { Row, Col } from "antd";

const Commons = ({ data, highlightedFields, headerMap }) => {
  delete data["id"];
  console.log("commons", data);
  return (
    <Row gutter={12}>
      {Object.entries(headerMap).map(([key, value]) => {
        return (
          <Col span={6} key={key} style={{ height: "100px" }}>
            {data.hasOwnProperty(key) === true ? (
              <div
                style={
                  highlightedFields && highlightedFields.hasOwnProperty(key)
                    ? { backgroundColor: "yellow" }
                    : { backgroundColor: "white" }
                }
              >
                <h4 style={{ color: "#1c8efa", marginBottom: "5px" }}>
                  {value}
                </h4>
                {data[key] && data[key]["label"] !== undefined ? (
                  <p className="detail-value">{data[key]["label"]}</p>
                ) : (
                  <p className="detail-value">{data[key]}</p>
                )}
              </div>
            ) : (
              <span>
                <h4 style={{ color: "#1c8efa", marginBottom: "5px" }}>
                  {value}
                </h4>
                <p className="detail-value">Not Entered</p>
              </span>
            )}
          </Col>
        );
      })}
    </Row>
  );
};

export default Commons;
