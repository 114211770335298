import React from "react";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { PermissibleRender } from "@brainhubeu/react-permissible";
import { getUserPermissions } from "../../../app/common/util/genericUtils";
import ValidateFromHome from "../../orderPunching/modal/ValidateFromHome";
const UtilMenus = ({ openModal }) => {
  const userPermissions = getUserPermissions();
  return (
    <div style={{ textAlign: "right", padding: "110px 30px 0 0" }}>
      <PermissibleRender
        userPermissions={userPermissions}
        requiredPermissions={["can_broadcast_notifications"]}
      >
        <Button
          color="olive"
          onClick={() => openModal("CreateNotificationModal")}
          style={{ marginRight: "20px" }}
        >
          Create Notification
        </Button>
      </PermissibleRender>
      <PermissibleRender
        userPermissions={userPermissions}
        requiredPermissions={["can_punch_order"]}
      >
        <Button
          color="teal"
          style={{ marginRight: "20px" }}
          as={Link}
          to="/create"
        >
          Add New
        </Button>
      </PermissibleRender>
      <PermissibleRender
        userPermissions={userPermissions}
        requiredPermissions={["can_view_whatsapp_orders"]}
      >
        <Button
          color="green"
          style={{ marginRight: "20px" }}
          onClick={() => openModal("WhatsappModal")}
        >
          WhatsApp Orders
        </Button>
      </PermissibleRender>
      <PermissibleRender
        userPermissions={userPermissions}
        requiredPermissions={["can_do_blankselection"]}
      >
        <Button
          color="brown"
          style={{ marginRight: "20px" }}
          onClick={() => openModal("OrderSelectionModal")}
        >
          Blank Selection
        </Button>
      </PermissibleRender>
      <PermissibleRender
        userPermissions={userPermissions}
        requiredPermissions={["can_link_order"]}
      >
        <Button
          color="purple"
          onClick={() => openModal("LinkedOrderModal")}
          style={{ marginRight: "20px" }}
        >
          New Linked Order
        </Button>
      </PermissibleRender>
    </div>
  );
};
export default UtilMenus;
