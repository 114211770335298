import React, { Component } from "react";
import Table from "../home/tabView/table/Table";
import moment from "moment";
class LabelDetails extends Component {
  state = {};

  columns = [
    {
      title: "Short Code",
      dataIndex: "label.short_code"
    },
    {
      title: "Label (Long Code)",
      dataIndex: "label.long_code"
    },
    {
      title: "Comment",
      dataIndex: "comment"
    },
    {
      title: "Timestamp",
      dataIndex: "created_on",
      render: text => <p>{moment(text).format("YYYY-MM-DD HH:mm")}</p>
    },
    {
      title: "User",
      dataIndex: "user.username"
    }
  ];

  render() {
    const { loading, data } = this.props;
    return (
      <div style={{ width: "92%", marginTop: "7%", marginBottom: "4%" }}>
        <h3>Order Labels</h3>
        <Table
          columns={this.columns}
          data={data}
          loading={loading}
          pagination={false}
          bordered={true}
          scroll={{ x: 1300 }}
          size="small"
        />
      </div>
    );
  }
}

export default LabelDetails;
