import {
  LOGIN_ACTION_START,
  LOGIN_ACTION_FINISH,
  LOGIN_ACTION_ERROR,
  LOGOUT_USER
} from "./authConstants";
import { reportError } from "../../app/common/util/sentryConfig";
import { loginApiCall } from "./api";
import { setItem, errorToast } from "../../app/common/util/genericUtils";
import { doesFormDataExist } from "../orderPunching/formUtils";
import { fetchFormData } from "../orderPunching/orderActions";

export const loginActionStart = () => {
  return {
    type: LOGIN_ACTION_START
  };
};
export const loginActionFinish = response => {
  return {
    type: LOGIN_ACTION_FINISH,
    payload: response
  };
};
export const loginActionError = () => {
  return {
    type: LOGIN_ACTION_ERROR
  };
};
export const logoutUser = () => {
  return {
    type: LOGOUT_USER
  };
};

export const loginUser = creds => dispatch => {
  dispatch(loginActionStart());
  return loginApiCall(creds)
    .then(response => {
      if (response && response.data.response_code === 80) {
        const data = response.data.data;
        setItem("suprolUser", data);
        dispatch(loginActionFinish(data));

        // Fecthing the form data as soon as the user logs in
        if (doesFormDataExist()) dispatch(fetchFormData());
      } else {
        dispatch(loginActionError());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch(error => {
      dispatch(loginActionError());
      errorToast("Error", error.message);
      reportError(error, creds);
    });
};
