export const GET_LINKING_REASONS_START = "GET_LINKING_REASONS_START";
export const GET_LINKING_REASONS_SUCCESS = "GET_LINKING_REASONS_SUCCESS";
export const GET_LINKING_REASONS_FAILURE = "GET_LINKING_REASONS_FAILURE";
export const CHECK_LINKED_ORDER_START = "CHECK_LINKED_ORDER_START";
export const CHECK_LINKED_ORDER_SUCCESS = "CHECK_LINKED_ORDER_SUCCESS";
export const CHECK_LINKED_ORDER_FAILURE = "CHECK_LINKED_ORDER_FAILURE";
export const LINK_ORDERS_START = "LINK_ORDERS_START";
export const LINK_ORDERS_SUCCESS = "LINK_ORDERS_SUCCESS";
export const LINK_ORDERS_FAILURE = "LINK_ORDERS_FAILURE";
export const SET_DATA_FOR_LINKING_FORM = "SET_DATA_FOR_LINKING_FORM";
export const GET_DETAILS_FOR_LINKING_FORM_START =
  "GET_DETAILS_FOR_LINKING_FORM_START";
export const GET_DETAILS_FOR_LINKING_FORM_SUCCESS =
  "GET_DETAILS_FOR_LINKING_FORM_SUCCESS";
export const GET_DETAILS_FOR_LINKING_FORM_FAILURE =
  "GET_DETAILS_FOR_LINKING_FORM_FAILURE";
export const SET_LINKED_ORDER_ID = "SET_LINKED_ORDER_ID";
