import { createReducer } from "../../app/common/util/reducerUtil";
import {
  CREATE_LABEL_START,
  CREATE_LABEL_SUCCESS,
  CREATE_LABEL_FAILURE,
  GET_LABEL_DATA_START,
  GET_LABEL_DATA_SUCCESS,
  GET_LABEL_DATA_FAILURE
} from "./labelConstants";
const initialState = {
  createLabelLoader: false,
  labelData: [],
  loadingLabelList: false
};

export const getLabelDataStart = state => {
  return {
    ...state,
    labelData: [],
    loadingLabelList: true
  };
};
export const getLabelDataSuccess = (state, response) => {
  return {
    ...state,
    labelData: response,
    loadingLabelList: false
  };
};
export const getLabelDataFailure = state => {
  return {
    ...state,
    labelData: [],
    loadingLabelList: false
  };
};
export const createLabelStart = state => {
  return {
    ...state,
    createLabelLoader: true
  };
};
export const createLabelSuccess = state => {
  return {
    ...state,
    createLabelLoader: false
  };
};
export const createLabelFailure = state => {
  return {
    ...state,
    createLabelLoader: false
  };
};

export default createReducer(initialState, {
  [CREATE_LABEL_START]: createLabelStart,
  [CREATE_LABEL_SUCCESS]: createLabelSuccess,
  [CREATE_LABEL_FAILURE]: createLabelFailure,
  [GET_LABEL_DATA_START]: getLabelDataStart,
  [GET_LABEL_DATA_SUCCESS]: getLabelDataSuccess,
  [GET_LABEL_DATA_FAILURE]: getLabelDataFailure
});
