import React, { Component } from "react";
import { Table } from "antd";
class TabView extends Component {
  render() {
    const {
      columns,
      loading,
      onFetchData,
      data,
      showPagination,
      defaultPageSize,
      pagination,
      rowKey,
      bordered,
      scroll,
      size
    } = this.props;
    return (
      <Table
        columns={columns}
        // defaultPageSize={defaultPageSize}
        dataSource={data}
        loading={loading}
        pagination={pagination}
        rowKey={record => record.id}
        bordered={bordered}
        scroll={scroll}
        size={size}
        //onFetchData={onFetchData}
        // showPagination={showPagination}
      />
    );
  }
}
export default TabView;
