import axios from "axios";
import { getItem, makeUrl } from "../../app/common/util/genericUtils";

export const getCustomerWithoutDist = page => {
  const token = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/master/customer/without-dist"),
    params: {
      page
    },
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};
export const getCustomerWithDist = page => {
  const token = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/master/customer/with-dist"),
    params: {
      page
    },
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};

export const getOnHoldOrderList = page => {
  console.log(page);
  const token = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/orders/list"),
    params: {
      status: "on_hold",
      page: page
    },
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};
export const getPendingAuthOrderList = page => {
  const token = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/orders/list"),
    params: {
      status: "pending_auth",
      page: page
    },
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};
export const getProcessingOrderList = page => {
  const token = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/orders/list"),
    params: {
      status: "processing",
      page: page
    },
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};
export const getCancelledOrderList = page => {
  const token = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/orders/list"),
    params: {
      status: "cancelled",
      page
    },
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};
export const getBlankSelectionOrderList = page => {
  const token = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/orders/blank-selection/list"),
    params: {
      status: "blank_selection",
      page
    },
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};

export const changeStatusOfOnHold = id => {
  const token = getItem("suprolUser");
  return axios({
    method: "PUT",
    url: makeUrl("/orders/on-hold/pending-auth/" + id),
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};

export const cancelOrder = (id, data) => {
  const token = getItem("suprolUser");
  return axios({
    method: "PUT",
    url: makeUrl("/orders/cancel/" + id),
    data,
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};

export const orderDetails = id => {
  const token = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/orders/details/" + id),
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};

export const validatePendingAuth = (id, data) => {
  const token = getItem("suprolUser");
  return axios({
    method: "PUT",
    url: makeUrl("/orders/validate/" + id),
    data,
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};

export const putOnHold = id => {
  const token = getItem("suprolUser");
  return axios({
    method: "PUT",
    url: makeUrl("/orders/hold/" + id),
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};

export const searchOrder = ({
  selectedSearchType,
  selectedSearchValue,
  orderStatus,
  startDate,
  endDate,
  pageNo
}) => {
  const token = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/orders/search"),
    params: {
      search_type: selectedSearchType,
      value: selectedSearchValue,
      status: orderStatus,
      start_date: startDate,
      end_date: endDate,
      page: pageNo
    },
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  }).then(response => {
    const { data } = response;
    if (data.response_code !== 80) {
      throw new Error(data.ui_message);
    }
    return data.data;
  });
};
export const exportOrder = ({
  selectedSearchType,
  selectedSearchValue,
  orderStatus,
  startDate,
  endDate,
  pageNo
}) => {
  const token = getItem("suprolUser");
  return axios({
    method: "POST",
    url: makeUrl("/orders/export"),
    params: {
      search_type: selectedSearchType,
      value: selectedSearchValue,
      status: orderStatus,
      start_date: startDate,
      end_date: endDate,
      page: pageNo
    },
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};
export const lenswareStatusApiCall = id => {
  const token = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/orders/lensware/" + id),
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};
export const printStockOrderApiCall = id => {
  const token = getItem("suprolUser");
  return axios({
    method: "PUT",
    url: makeUrl("/orders/print_count/" + id),
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};
export const archiveStockOrderApiCall = id => {
  const token = getItem("suprolUser");
  return axios({
    method: "PUT",
    url: makeUrl("/orders/stock_process/" + id),
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};

export const getStockOrderList = page => {
  console.log(page);
  const token = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/orders/stock/list"),
    params: {
      page: page
    },
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};
export const orderUpdationApi = (id, data) => {
  const token = getItem("suprolUser");
  return axios({
    method: "PUT",
    url: makeUrl("/orders/processing/update/" + id),
    data,
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};
