import React from "react";
import {
  makeCommonsData,
  makeFrameData,
  makeRightEyeConfig,
  makeLeftEyeConfig,
  makeAddOnsData
} from "../../orderPunching/formUtils";
import Commons from "../../orderDetails/Commons";
import { Row } from "antd";
import RightLensConfig from "../../orderDetails/RightLensConfig";
import LeftLensConfig from "../../orderDetails/LeftLensConfig";
import Frame from "../../orderDetails/Frame";
import RightPrism from "../../orderDetails/RightPrism";
import LeftPrism from "../../orderDetails/LeftPrism";
import AddOns from "../../orderDetails/AddOns";
import {
  isEmpty,
  getUserPermissions
} from "../../../app/common/util/genericUtils";
import LabelDetails from "../../orderLabelling/LabelDetails";
import {
  commonsKeyHeaderMap,
  rightLensHeaderMap,
  leftLensHeaderMap
} from "../../orderPunching/constants";
import EditOrderForm from "./EditOrderForm";
import { PermissibleRender } from "@brainhubeu/react-permissible";

const OrderDetails = ({ details, highlightedFields, id }) => {
  const commonsData = makeCommonsData(details);
  const rightLensConfig = makeRightEyeConfig(details);
  const leftLensConfig = makeLeftEyeConfig(details);
  const frameData = makeFrameData(details);

  const addOnsData = makeAddOnsData(details);
  const formJson = {
    ...commonsData,
    ...rightLensConfig,
    ...leftLensConfig,
    ...frameData,
    ...addOnsData
  };
  const userPermissions = getUserPermissions();
  return (
    <div className="order-details-container">
      <h3>Customer Section</h3>
      <Commons
        data={commonsData}
        highlightedFields={highlightedFields}
        headerMap={commonsKeyHeaderMap}
      />
      <h3>Lens Section</h3>
      <Row>
        <RightLensConfig
          data={rightLensConfig.right_lens_config}
          highlightedFields={highlightedFields}
          headerMap={rightLensHeaderMap}
        />
        <LeftLensConfig
          data={leftLensConfig.left_lens_config}
          highlightedFields={highlightedFields}
          headerMap={leftLensHeaderMap}
        />
      </Row>
      <h3>Frame Section</h3>
      <Frame data={frameData.frame} />
      <div>
        <RightPrism
          data={
            isEmpty(frameData.frame) === true
              ? null
              : frameData.frame.right_prism
          }
        />
        <LeftPrism
          data={
            isEmpty(frameData.frame) === true
              ? null
              : frameData.frame.left_prism
          }
        />
      </div>
      <div style={{ marginTop: "15%" }}>
        <h3>Add Ons Section</h3>
        <AddOns data={addOnsData.add_ons} />
      </div>
      {details && details.label.length > 0 && (
        <LabelDetails data={details.label} />
      )}
      {details.status === "processing" && (
        <PermissibleRender
          userPermissions={userPermissions}
          requiredPermissions={["can_update_processing_orders"]}
        >
          <EditOrderForm initialValues={formJson} id={id} details={details} />
        </PermissibleRender>
      )}
    </div>
  );
};

export default OrderDetails;
