import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import WhatsappOrderListItem from "./WhatsappOrderListItem";

class WhatsappOrderList extends Component {
  state = {};

  render() {
    const { orders } = this.props;
    console.log("whatsapp orders", orders);
    return (
      <Grid>
        {orders &&
          orders.length > 0 &&
          orders.map((order, index) => {
            return <WhatsappOrderListItem order={order} key={index} />;
          })}
      </Grid>
    );
  }
}

export default WhatsappOrderList;
/*
const orders = [
  {
    from_number: "1312123411",
    order: {},
    order_image:
      "http://www.sheenservices.com/wp-content/uploads/2019/01/Whatsapp-logo-1024x713.jpg"
  },
  {
    from_number: "1315554411",
    order: {},
    order_image:
"https://api.twilio.com/2010-04-01/Accounts/AC719b9f600cc9f547c3592e1fa37b1f17/Messages/MMfd21f14eecf7768f6fc552524c42ed1a/Media/ME4b9b9bd983bfa6586a8259852fd85070"
  }
];
*/
