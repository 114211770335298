import React, { Component } from "react";
import * as Sentry from "@sentry/browser";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    // this.setState({ error });
    // Sentry.configureScope(scope => {
    //   Object.keys(errorInfo).forEach(key => {
    //     scope.setExtra(key, errorInfo[key]);
    //   });
    //   Sentry.captureException(error);
    // });
  }

  render() {
    if (this.state.error) {
      return <p>something went wrong</p>;
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
