import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
import { Loader, Dimmer } from "semantic-ui-react";


class LoadingModal extends Component {
    render() {
        const { closeModal } = this.props;
        return (
            <Modal size="mini" open={true} >
                {/* <Modal.Header>Please Wait</Modal.Header> */}
                <Modal.Content style={{ height: 150 }}>
                    <Dimmer active inverted>
                        <Loader >Loading...</Loader>
                    </Dimmer>
                </Modal.Content>
            </Modal>
        );
    }
}

export default LoadingModal
