import React from "react";
import { Row, Col } from "antd";
import { addOnsHeaderMap } from "../orderPunching/constants";

const AddOns = ({ data }) => {
  console.log("addons", data);
  if (data) {
    return (
      <Row gutter={24}>
        {Object.entries(addOnsHeaderMap).map(([key, value]) => {
          return (
            <Col span={4} key={key} style={{ height: "75px" }}>
              {data.hasOwnProperty(key) === true ? (
                <span>
                  <h4 style={{ color: "#1c8efa", marginBottom: "5px" }}>
                    {value}
                  </h4>
                  {data[key] && data[key]["label"] !== undefined ? (
                    <p className="detail-value">{data[key]["label"]}</p>
                  ) : (
                    <p className="detail-value">{data[key]}</p>
                  )}
                </span>
              ) : (
                <span>
                  <h4 style={{ color: "#1c8efa", marginBottom: "5px" }}>
                    {value}
                  </h4>
                  <p className="detail-value">Not Entered</p>
                </span>
              )}
            </Col>
          );
        })}
      </Row>
    );
  } else {
    return (
      <Row gutter={12} style={{ height: "75px" }}>
        {Object.entries(addOnsHeaderMap).map(([key, value]) => {
          return (
            <Col span={6} key={key} style={{ height: "75px" }}>
              <h4 className="detail-heading">{value}</h4>
              <span>Not Entered</span>
            </Col>
          );
        })}
      </Row>
    );
  }
};

export default AddOns;
