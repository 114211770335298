import React, { Component } from "react";
import { Field, reduxForm, FormSection, touch } from "redux-form";
import { connect } from "react-redux";
import { Form, Grid, Header, Label } from "semantic-ui-react";
import { validate } from "./orderFormValidations";
import TextInput from "../../app/common/form/TextInput";
import RadioInput from "../../app/common/form/RadioInput";
import { Prompt } from "react-router-dom";
import DateInput from "../../app/common/form/DateInput";

import {
  getCustomerList,
  getCustomerDistributorsFromId,
  getRetailersFromCustomerId,
  getCustomerAddressfromId,
  getRetailerDistributorAddressfromId,
  getLnamList,
  getLnamDescription,
  getCoatingListFromLnamId,
  getCorridorList,
  getFittingHeight,
  MandatoryHeader,
  getEmployeeList,
  combineDateTime,
  getDispatchList,
  getCustomerAddressDescription,
  getBranchList,
  getManufacturingUnitList,
  getProductGroupListForCustomer,
  transformLnamList,
  getProductGroupListForDistributor,
  getProductGroupListForRetailer
} from "./formUtils";
import { openModal } from "../modal/modalActions";
import { Row } from "antd";
import RightEyeConfig from "./formSections/RightEyeConfig";
import LeftEyeConfig from "./formSections/LeftEyeConfig";
import Frame from "./formSections/Frame";
import AddOns from "./formSections/AddOns";
import {
  customerActions,
  submitForm,
  setCustomerId,
  setAddressList,
  setLnamId,
  setCorridorId,
  setUrgency,
  checkCoatingTimeline,
  setFittingforCorridor,
  setFittingHeightId,
  setCorridorForFitting,
  setLnamFields,
  resetFields,
  checkOrderId,
  checkDuplicateOrders,
  setShippingAddress,
  setDispatchType,
  setCtoFieldsMandatory,
  setAddressDescription,
  setDistributorList,
  setRetailerList,
  setProductGroupList,
  setProductGroupDescription,
  setLnamListForProductGroup,
  setLnamDescription,
  setProductGroupOwner
} from "./orderActions";

import CustomSelect from "../../app/common/form/CustomSelect";
import LoaderComponent from "../../app/common/util/LoaderComponent";
import CreateOrderButtons from "./buttonSets/CreateOrderButtons";
import ValidateButtonSet from "./buttonSets/ValidateButtonSet";
import {
  disableUpcomingDates,
  disablePreviousDates,
  isEmpty,
  getUserType,
  getItem,
  setItem
} from "../../app/common/util/genericUtils";
import WhatsappButtonSet from "./buttonSets/WhatsappButtonSet";
import FieldWrapper from "./FieldWrapper";
import LinkingButtonSet from "./buttonSets/LinkingButtonSet";
import DropDown from "../../app/common/form/DropDown";

export class OrderForm extends Component {
  setProductGroupFields = (defaultProductGroup, productGroupList, selectedProductGroupOwner) => {
    const { setProductGroupDescription, setLnamListForProductGroup, change, setProductGroupList, setLnamDescription, setProductGroupOwner } = this.props;

    if (defaultProductGroup !== null && productGroupList.length > 0) {
      setProductGroupList(productGroupList);
      setProductGroupDescription(defaultProductGroup.description)
      change('product_group', {
        label: defaultProductGroup.code,
        key: defaultProductGroup.id
      })

      let lnamList = transformLnamList(defaultProductGroup.config)
      setLnamListForProductGroup(lnamList)
      setProductGroupOwner(selectedProductGroupOwner)
    }
    let fieldsToChange = ['lnam', 'coating', 'fitting_height', 'corridor']
    fieldsToChange.forEach(field => change(field, ""))
    setLnamDescription(null)
  }
  handleCustomerField = value => {
    const {
      customerActions,
      change,
      setLnamDescription,
      setProductGroupDescription,
      setAddressDescription,
      setProductGroupOwner
    } = this.props
    if (value && value.key) {
      customerActions(value);
    } else {
      const resetValof = ["distributor", "retailer", "dispatch_type", "shipping_address", 'product_group', 'lnam', 'coating', 'fitting_height', 'corridor'];
      resetValof.forEach(val => {
        change(val, "")
      });
      setLnamDescription(null)
      setAddressDescription(null)
      setProductGroupDescription(null)
      setProductGroupOwner(null)
    }

  };

  handleDistributorChange = (value) => {
    if (value && value.key) {
      const { customerId, change } = this.props;
      change('retailer', "")
      const productGroupData = getProductGroupListForDistributor(customerId, value.key)
      let { defaultProductGroup, productGroupList } = productGroupData;
      this.setProductGroupFields(defaultProductGroup, productGroupList, 'distributor')
    } else {
      const {
        change,
        setLnamDescription,
        setProductGroupDescription,
        setAddressDescription,
        setProductGroupOwner,
        customerActions,
        customerId
      } = this.props
      const resetValof = ["retailer", 'product_group', 'lnam', 'coating', 'fitting_height', 'corridor'];
      resetValof.forEach(val => {
        change(val, "")
      });
      setLnamDescription(null)
      setAddressDescription(null)
      setProductGroupDescription(null)
      setProductGroupOwner("customer")
      customerActions({ key: customerId })
    }


  }
  handleRetailerChange = (value) => {
    if (value && value.key) {
      const { customerId, change } = this.props;
      const productGroupData = getProductGroupListForRetailer(customerId, value.key)
      let { defaultProductGroup, productGroupList } = productGroupData;
      this.setProductGroupFields(defaultProductGroup, productGroupList, 'retailer')
    } else {
      const {
        change,
        setLnamDescription,
        setProductGroupDescription,
        setAddressDescription,
        setProductGroupOwner,
        customerActions,
        customerId
      } = this.props
      const resetValof = ['distributor', 'product_group', 'lnam', 'coating', 'fitting_height', 'corridor'];
      resetValof.forEach(val => {
        change(val, "")
      });
      setLnamDescription(null)
      setAddressDescription(null)
      setProductGroupDescription(null)
      setProductGroupOwner("customer")
      customerActions({ key: customerId })
    }
  }
  handleProductGroupChange = (obj) => {
    const { setProductGroupDescription, setLnamListForProductGroup } = this.props
    let productGroupsForSelectedIdentifier = getItem('productGroupsForSelectedIdentifier');
    let selectedProductGroup = {}
    productGroupsForSelectedIdentifier.forEach(group => {
      if (group.id === obj.key) {
        selectedProductGroup = group
      }
    })
    setItem('selectedProductGroup', selectedProductGroup)
    let lnamList = transformLnamList(selectedProductGroup.config)
    setLnamListForProductGroup(lnamList)
    setProductGroupDescription(selectedProductGroup.description)
  }

  setLnamId = obj => {
    const {
      lnamId,
      setLnamId,
      setLnamFields,
      setFittingforCorridor,
      change
    } = this.props;
    const id = obj.key;
    if (id !== lnamId) {
      const resetValof = ["coating", "corridor", "fitting_height"];
      resetValof.forEach(val => {
        change(val, "");
      });
      setFittingforCorridor(false);
      setLnamId(id);
      setLnamFields(id);
    } else {
      setLnamId(id);
      setLnamFields(id);
    }
  };
  onFormSubmit = values => {
    const { productGroupOwner } = this.props
    Object.assign(values, {
      status: "pending_auth", source: "manual",
      product_group_owner: productGroupOwner
    });
    const payload = combineDateTime(values);
    return this.props.checkDuplicateOrders(payload);
  };
  setCorridorValue = obj => {
    const id = obj.key;
    const { setCorridorId, setFittingforCorridor, change } = this.props;
    if (id === undefined) {
      setCorridorId(id);
      setFittingforCorridor(false);
      change("fitting_height", "");
    } else {
      setCorridorId(id);
      setFittingforCorridor(true);
    }
  };
  setFittingHeightValue = obj => {
    const id = obj.key;
    const { setFittingHeightId, setCorridorForFitting, change } = this.props;
    if (id === undefined) {
      setFittingHeightId(id);
      setCorridorForFitting(false);
      change("corridor", "");
    } else {
      setFittingHeightId(id);
      setCorridorForFitting(true);
    }
  };
  onFormHold = values => {
    const formData = combineDateTime(values);
    formData['product_group_owner'] = this.props.productGroupOwner;
    const data = {
      formData
    };
    this.props.openModal("HoldModal", data);
  };
  handleUrgentField = (e, val) => {
    const { setUrgency } = this.props;
    val === "true" ? setUrgency(true) : setUrgency(false);
  };
  handleCtoField = (e, val) => {
    const { setCtoFieldsMandatory, touch } = this.props;
    val === "true" ? setCtoFieldsMandatory(true) : setCtoFieldsMandatory(false);
    let fields = ["mono_pd_r", "mono_pd_l", "dbl", "a_width", "b_width"];
    fields.forEach(field => {
      touch("orderForm", `frame.${field}`);
    });
  };
  setShippingAddress = (e, val) => {
    const {
      customerId,
      setAddressList,
      setShippingAddress,
      setDispatchType
    } = this.props;
    let addressList = [];
    val === "true"
      ? (addressList = getCustomerAddressfromId(customerId))
      : (addressList = getRetailerDistributorAddressfromId(customerId));
    setAddressList(addressList);
    if (addressList.length > 0) {
      setShippingAddress(addressList[0]);
      setDispatchType(addressList[0]["dispatchType"]);
    } else {
      setShippingAddress(null);
      setDispatchType(null);
    }
  };
  setDispatchTypeOnAddressChange = obj => {
    console.log(obj);
    const { setDispatchType, customerId } = this.props;
    if (obj && obj.dispatchType) {
      setShippingAddress(obj.dispatchType);
      setDispatchType(obj.dispatchType);
    } else {
      setShippingAddress(null);
      setDispatchType(null);
    }
    const adrDescription = getCustomerAddressDescription(customerId, obj.key);
    this.setState({ adrDescription });
  };
  componentWillUnmount() {
    this.props.resetFields();
  }

  handleBtnDisable = () => {
    const { invalid, rightEyeCheckbox, leftEyeCheckbox } = this.props;

    if (rightEyeCheckbox === false && leftEyeCheckbox === false) {
      return true;
    } else {
      if (invalid === true) {
        return true;
      } else {
        return false;
      }
    }
  };

  customerData = getCustomerList();
  // lnamList = getLnamList();
  employeeList = getEmployeeList();
  dispatchList = getDispatchList();
  //branchList = getBranchList();
  muList = getManufacturingUnitList();
  render() {
    const {
      distributorList,
      retailerList,
      handleSubmit,
      invalid,
      customerId,
      addressList,
      lnamId,
      corridorId,
      submittingForm,
      type,
      labelInValue,
      validatingPendingAuth,
      change,
      urgent,
      fittingForCorridor,
      corridorForFitting,
      fittingHeightId,
      lnamDescription,
      addressDescription,
      checkCoatingTimeline,
      checkOrderId,
      initialValues,
      showCustomerInitial,
      punchingWhatsappOrder,
      dirty,
      productGroupDescription,
      // rightEyeCheckbox,
      // leftEyeCheckbox,
      productGroupList,
      lnamListForProductGroup,
      productGroupOwner
    } = this.props;
    //const { adrDescription } = this.state;
    // const distributorData = getCustomerDistributorsFromId(customerId);
    // const retailerData = getRetailersFromCustomerId(customerId);
    const coatingList = getCoatingListFromLnamId(lnamId);
    const corridorList = getCorridorList(
      lnamId,
      fittingHeightId,
      corridorForFitting
    );
    const fittingHeightList = getFittingHeight(
      lnamId,
      corridorId,
      fittingForCorridor
    );
    const userType = getUserType();
    if (submittingForm || validatingPendingAuth || punchingWhatsappOrder) {
      return <LoaderComponent />;
    }
    return (
      <div>
        <Prompt
          when={dirty}
          message={location => `Are you sure you want to leave the Page`}
        />
        <div style={{ width: "85%", margin: "3% 0 2% 10%" }}>
          <Form error size="large" autoComplete="off">
            <Row>
              <Header color="blue" content="Customer Section" />
              <Row gutter={10}>
                <FieldWrapper
                  name="reference_id"
                  component={TextInput}
                  type="text"
                  placeholder="Reff. No."
                  //handleOnBlur={checkOrderId}
                  heading="Reference Id"
                  mandatory={false}
                  width={4}
                />
                <CustomerDetails
                  labelInValue={labelInValue}
                  customerData={this.customerData}
                  customerActions={this.handleCustomerField}
                  heading="Customer Details"
                  mandatory={true}
                  width={12}
                />
                {/* <Field
                    name="customer"
                    component={CustomSelect}
                    placeholder="Customer Details"
                    data={this.customerData}
                    showSearch={true}
                    getSelectedValue={customerActions}
                    allowClear={true}
                    labelInValue={labelInValue}
                  /> */}

                <FieldWrapper
                  name="distributor"
                  // component={CustomSelect}
                  component={DropDown}
                  type="select"
                  placeholder="Distributor Name"
                  data={distributorList}
                  allowClear={true}
                  labelInValue={labelInValue}
                  showSearch={true}
                  heading="Distributor Name"
                  mandatory={false}
                  width={8}
                  getSelectedValue={this.handleDistributorChange}
                />
              </Row>
              <Row gutter={10} style={{ marginTop: "2%" }}>
                <FieldWrapper
                  name="retailer"
                  // component={CustomSelect}
                  component={DropDown}
                  type="select"
                  placeholder="Retailer Name"
                  data={retailerList}
                  allowClear={true}
                  labelInValue={labelInValue}
                  showSearch={true}
                  heading="Retailer Name"
                  mandatory={false}
                  width={6}
                //  getSelectedValue={this.handleRetailerChange}
                />
                <FieldWrapper
                  name="product_group"
                  component={CustomSelect}
                  type="select"
                  placeholder="Product Group"
                  data={productGroupList}
                  allowClear={true}
                  labelInValue={labelInValue}
                  showSearch={true}
                  heading="Product Group"
                  mandatory={true}
                  width={6}
                  description={productGroupDescription}
                  getSelectedValue={this.handleProductGroupChange}
                />
                <FieldWrapper
                  name="patient"
                  type="text"
                  component={TextInput}
                  placeholder="Patient Name"
                  heading="Patient Name"
                  mandatory={false}
                  width={6}
                />
                <FieldWrapper
                  name="external_order_id"
                  component={TextInput}
                  type="text"
                  placeholder="External Reff. No."
                  heading="External Order Id"
                  mandatory={false}
                  width={6}
                />

                <FieldWrapper
                  name="received_by"
                  component={CustomSelect}
                  data={this.employeeList}
                  type="select"
                  placeholder="Order Received By"
                  allowClear={true}
                  showSearch={true}
                  labelInValue={labelInValue}
                  heading="Order Received By"
                  mandatory={true}
                  width={6}
                />
                <FieldWrapper
                  name="entered_by"
                  component={userType === "Branch" ? CustomSelect : TextInput}
                  data={this.employeeList}
                  type={userType === "Branch" ? "select" : "text"}
                  placeholder="Order Entered By"
                  allowClear={true}
                  showSearch={true}
                  labelInValue={labelInValue}
                  heading="Order Entered By"
                  mandatory={userType === "Branch" ? true : false}
                  width={6}
                />
              </Row>
              <Row gutter={10} style={{ marginTop: "2%" }}>
                <FieldWrapper
                  name="shipping_same_as_billing"
                  type="radio"
                  valueOne="true"
                  labelOne="Yes"
                  valueTwo="false"
                  labelTwo="No"
                  component={RadioInput}
                  onChange={this.setShippingAddress}
                  heading="Shipping Same as Billing"
                  mandatory={true}
                  width={6}
                />

                <FieldWrapper
                  name="shipping_address"
                  type="select"
                  component={CustomSelect}
                  placeholder="Shipping Address"
                  data={addressList}
                  allowClear={true}
                  labelInValue={labelInValue}
                  showSearch={true}
                  getSelectedValue={this.setDispatchTypeOnAddressChange}
                  heading="Shipping Address"
                  mandatory={true}
                  width={6}
                  description={addressDescription}
                />

                <FieldWrapper
                  name="dispatch_type"
                  type="select"
                  component={CustomSelect}
                  placeholder="Dispatch Type"
                  data={this.dispatchList}
                  allowClear={true}
                  showSearch={true}
                  labelInValue={labelInValue}
                  heading="Dispatch Type"
                  mandatory={true}
                  width={6}
                />
                <FieldWrapper
                  name="received_date"
                  component={DateInput}
                  type="date"
                  placeholder="Order Received Date"
                  disableFunction={disableUpcomingDates}
                  heading="Order Received Date"
                  mandatory={true}
                  width={6}
                />
              </Row>
              <Row gutter={10} style={{ marginTop: "2%" }}>
                <FieldWrapper
                  name="received_hour"
                  component={TextInput}
                  type="text"
                  placeholder="Order Received Time(Hour)"
                  heading="Order Received Hour"
                  mandatory={true}
                  width={6}
                />
                <FieldWrapper
                  name="received_minute"
                  component={TextInput}
                  type="text"
                  placeholder="Order Received Time(Minute)"
                  heading="Order Received Minute"
                  mandatory={true}
                  width={6}
                />
                <FieldWrapper
                  name="urgent"
                  type="radio"
                  valueOne="true"
                  labelOne="Yes"
                  valueTwo="false"
                  labelTwo="No"
                  component={RadioInput}
                  onChange={this.handleUrgentField}
                  heading="Urgent"
                  mandatory={false}
                  width={6}
                />

                <FieldWrapper
                  name="manufacturing_business_unit"
                  type="select"
                  disabled={initialValues && userType !== "Corporate"}
                  component={CustomSelect}
                  placeholder="Manufacturing Location"
                  data={this.muList}
                  allowClear={true}
                  showSearch={true}
                  labelInValue={labelInValue}
                  heading="Manufacturing Location"
                  mandatory={false}
                  width={6}
                />
              </Row>
              <Row gutter={10} />
              <Header color="blue" content="Product Section" />
              <Row gutter={10} style={{ marginTop: "2%" }}>
                <LnamDetails
                  setLnamId={this.setLnamId}
                  lnamList={lnamListForProductGroup}
                  labelInValue={labelInValue}
                  heading="LNAM"
                  mandatory={true}
                  width={10}
                  description={lnamDescription}
                />

                <FieldWrapper
                  name="coating"
                  component={CustomSelect}
                  type="select"
                  placeholder="coating"
                  data={coatingList}
                  showSearch={true}
                  allowClear={true}
                  labelInValue={labelInValue}
                  heading="Coating"
                  mandatory={true}
                  width={6}
                />
                {corridorList && corridorList.length > 0 && (
                  <FieldWrapper
                    name="corridor"
                    type="select"
                    component={CustomSelect}
                    placeholder="corridor"
                    data={corridorList}
                    showSearch={true}
                    allowClear={true}
                    getSelectedValue={this.setCorridorValue}
                    labelInValue={labelInValue}
                    heading="Corridor"
                    mandatory={true}
                    width={4}
                  />
                )}
                {corridorList && corridorList.length > 0 && (
                  <FieldWrapper
                    name="fitting_height"
                    component={CustomSelect}
                    type="select"
                    placeholder="FittingHeight"
                    data={fittingHeightList}
                    showSearch={true}
                    allowClear={true}
                    labelInValue={labelInValue}
                    //getSelectedValue={this.setFittingHeightValue}
                    heading="Fitting Height"
                    mandatory={false}
                    width={4}
                  />
                )}
              </Row>

              <Row gutter={10} style={{ marginTop: "2%" }}>
                {showCustomerInitial === true && (
                  <FieldWrapper
                    name="clinit"
                    component={TextInput}
                    type="text"
                    placeholder="Customer Initials"
                    heading="Customer Initials"
                    mandatory={false}
                    width={6}
                  />
                )}
                {urgent && (
                  <FieldWrapper
                    name="promised_date"
                    component={DateInput}
                    type="date"
                    placeholder="Promise Date"
                    disableFunction={disablePreviousDates}
                    heading="Promise Date"
                    mandatory={true}
                    width={6}
                  />
                )}
                {urgent && (
                  <FieldWrapper
                    name="promised_hour"
                    component={TextInput}
                    type="text"
                    placeholder="Promise Time(Hour)"
                    heading="Promised Time(Hour)"
                    mandatory={true}
                    width={6}
                  />
                )}
                {urgent && (
                  <FieldWrapper
                    name="promised_minute"
                    component={TextInput}
                    type="text"
                    placeholder="Promise Time(Minute)"
                    handleOnBlur={() => checkCoatingTimeline("orderForm")}
                    heading="Promised Time(Minute)"
                    mandatory={true}
                    width={6}
                  />
                )}
              </Row>
              <Row gutter={10} style={{ marginTop: "2%" }}>
                {showCustomerInitial === true && (
                  <FieldWrapper
                    name="cto"
                    type="radio"
                    valueOne="true"
                    labelOne="Yes"
                    valueTwo="false"
                    labelTwo="No"
                    component={RadioInput}
                    onChange={this.handleCtoField}
                    heading="CTO"
                    mandatory={false}
                    width={6}
                  />
                )}
              </Row>
              <Row style={{ padding: "0px", marginTop: "2%" }}>
                <FormSection name="right_lens_config">
                  <RightEyeConfig change={change} labelInValue={labelInValue} />
                </FormSection>
                <FormSection name="left_lens_config">
                  <LeftEyeConfig change={change} labelInValue={labelInValue} />
                </FormSection>
              </Row>
            </Row>
            {type === "create" && (
              <CreateOrderButtons
                invalid={invalid}
                onFormHold={this.onFormHold}
                onFormSubmit={this.onFormSubmit}
                handleSubmit={handleSubmit}
                handleBtnDisable={this.handleBtnDisable}
                type={type}
                style={{
                  width: "20%",
                  margin: "2% 0% -2% 74%",
                  display: "inline-flex"
                }}
              />
            )}
            {type === "validate" && (
              <ValidateButtonSet
                invalid={invalid}
                handleSubmit={handleSubmit}
                details={initialValues}
                type={type}
                style={{
                  margin: "2% 0% -2% 74%"
                }}
                showNextPrevious={false}
                productGroupOwner={productGroupOwner}
              />
            )}
            {type === "validateFromHome" && (
              <ValidateButtonSet
                invalid={invalid}
                handleSubmit={handleSubmit}
                details={initialValues}
                type={type}
                style={{
                  margin: "2% 0% -2% 74%",
                }}
                showNextPrevious={false}
                productGroupOwner={productGroupOwner}
              />
            )}
            {type === "whatsapp" && (
              <WhatsappButtonSet
                invalid={invalid}
                handleSubmit={handleSubmit}
                style={{
                  width: "20%",
                  margin: "2% 0% -2% 74%",
                  display: "inline-flex"
                }}
                type={type}
                productGroupOwner={productGroupOwner}
              />
            )}
            {type === "linking" && (
              <LinkingButtonSet invalid={invalid} handleSubmit={handleSubmit} style={{
                width: "20%",
                margin: "2% 0% -2% 74%",
                display: "inline-flex"
              }}
                type={type}
                productGroupOwner={productGroupOwner}
              />
            )}
            <div style={{ marginTop: "5%" }}>
              <Header color="blue" content="Frame Section" />
              <FormSection name="frame">
                <Frame labelInValue={labelInValue} change={change} />
              </FormSection>
            </div>
            <div style={{ marginTop: "20px" }}>
              <Header color="blue" content="Add Ons Section" />
              <FormSection name="add_ons">
                <AddOns labelInValue={labelInValue} change={change} />
              </FormSection>
            </div>
            {type === "create" && (
              <CreateOrderButtons
                invalid={invalid}
                onFormHold={this.onFormHold}
                onFormSubmit={this.onFormSubmit}
                handleSubmit={handleSubmit}
                handleBtnDisable={this.handleBtnDisable}
                style={{
                  width: "20%",
                  margin: "6% 0% 10% 37%",
                  display: "inline-flex"
                }}
                type={type}
              />
            )}
            {type === "validate" && (
              <ValidateButtonSet
                invalid={invalid}
                handleSubmit={handleSubmit}
                details={initialValues}
                type={type}
                showNextPrevious={true}
                productGroupOwner={productGroupOwner}
              />
            )}
            {type === "validateFromHome" && (
              <ValidateButtonSet
                invalid={invalid}
                handleSubmit={handleSubmit}
                details={initialValues}
                type={type}
                showNextPrevious={true}
                productGroupOwner={productGroupOwner}
              />
            )}
            {type === "whatsapp" && (
              <WhatsappButtonSet
                invalid={invalid}
                handleSubmit={handleSubmit}
                type={type}
                productGroupOwner={productGroupOwner}
              />
            )}
            {type === "linking" && (
              <LinkingButtonSet invalid={invalid} handleSubmit={handleSubmit} type={type} productGroupOwner={productGroupOwner} />
            )}
          </Form>
        </div>
      </div>
    );
  }
}

const actions = {
  openModal,
  submitForm,
  setCustomerId,
  setDistributorList,
  setRetailerList,
  setProductGroupList,
  setProductGroupDescription,
  setLnamListForProductGroup,
  setAddressList,
  setLnamId,
  setCorridorId,
  setUrgency,
  checkCoatingTimeline,
  setFittingforCorridor,
  setFittingHeightId,
  setCorridorForFitting,
  setLnamFields,
  customerActions,
  resetFields,
  checkOrderId,
  checkDuplicateOrders,
  setDispatchType,
  setShippingAddress,
  setCtoFieldsMandatory,
  touch,
  setAddressDescription,
  setLnamDescription,
  setProductGroupOwner
};
const mapStateToProps = state => ({
  formState: state,
  customerId: state.orderForm.customerId,
  distributorList: state.orderForm.distributorList,
  productGroupList: state.orderForm.productGroupList,
  productGroupDescription: state.orderForm.productGroupDescription,
  lnamListForProductGroup: state.orderForm.lnamListForProductGroup,
  retailerList: state.orderForm.retailerList,
  addressList: state.orderForm.addressList,
  lnamId: state.orderForm.lnamId,
  corridorId: state.orderForm.corridorId,
  submittingForm: state.orderForm.submittingForm,
  validatingPendingAuth: state.home.validatingPendingAuth,
  urgent: state.orderForm.urgent,
  fittingForCorridor: state.orderForm.fittingForCorridor,
  fittingHeightId: state.orderForm.fittingHeightId,
  corridorForFitting: state.orderForm.corridorForFitting,
  showCustomerInitial: state.orderForm.showCustomerInitial,
  punchingWhatsappOrder: state.whatsappOrders.punchingOrder,
  lnamDescription: state.orderForm.lnamDescription,
  addressDescription: state.orderForm.addressDescription,
  rightEyeCheckbox: state.orderForm.rightEyeCheckbox,
  leftEyeCheckbox: state.orderForm.leftEyeCheckbox,
  productGroupOwner: state.orderForm.productGroupOwner
});
export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "orderForm",
    validate,
    enableReinitialize: true
  })(OrderForm)
);

class CustomerDetails extends React.PureComponent {
  render() {
    return (
      <FieldWrapper
        name="customer"
        type="select"
        // component={CustomSelect}
        component={DropDown}
        placeholder="Customer Details"
        data={this.props.customerData}
        showSearch={true}
        getSelectedValue={this.props.customerActions}
        allowClear={true}
        labelInValue={this.props.labelInValue}
        heading={this.props.heading}
        mandatory={this.props.mandatory}
        width={this.props.width}
      />
    );
  }
}

class LnamDetails extends React.PureComponent {
  render() {
    return (
      <FieldWrapper
        onFocus={() => window.scrollTo(0, 500)}
        name="lnam"
        component={CustomSelect}
        type="select"
        placeholder="LNAM"
        data={this.props.lnamList}
        allowClear={true}
        showSearch={true}
        getSelectedValue={this.props.setLnamId}
        heading={this.props.heading}
        mandatory={this.props.mandatory}
        width={this.props.width}
        description={this.props.description}
        //  extraData={id => {
        //  return getLnamDescription(id);
        // }}
        labelInValue={this.props.labelInValue}
      />
    );
  }
}
