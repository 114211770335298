export const GET_ORDER_FOR_BLANK_SELECTION_START =
  "GET_ORDER_FOR_BLANK_SELECTION_START";
export const GET_ORDER_FOR_BLANK_SELECTION_SUCCESS =
  "GET_ORDER_FOR_BLANK_SELECTION_SUCCESS";
export const GET_ORDER_FOR_BLANK_SELECTION_FAILURE =
  "GET_ORDER_FOR_BLANK_SELECTION_FAILURE";
export const CHECK_OPC_VALIDATION_START = "CHECK_OPC_VALIDATION_START";
export const CHECK_OPC_VALIDATION_SUCCESS = "CHECK_OPC_VALIDATION_SUCCESS";
export const CHECK_OPC_VALIDATION_FAILURE = "CHECK_OPC_VALIDATION_FAILURE";
export const BLANK_SELECTION_START = "BLANK_SELECTION_START";
export const BLANK_SELECTION_SUCCESS = "BLANK_SELECTION_SUCCESS";
export const BLANK_SELECTION_FAILURE = "BLANK_SELECTION_FAILURE";
export const SET_MULTIPLE_BLANK_REASON = "SET_MULTIPLE_BLANK_REASON";
