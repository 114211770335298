import { createReducer } from "../../app/common/util/reducerUtil";
import {
  GET_LINKING_REASONS_START,
  GET_LINKING_REASONS_SUCCESS,
  GET_LINKING_REASONS_FAILURE,
  CHECK_LINKED_ORDER_START,
  CHECK_LINKED_ORDER_SUCCESS,
  CHECK_LINKED_ORDER_FAILURE,
  SET_DATA_FOR_LINKING_FORM,
  GET_DETAILS_FOR_LINKING_FORM_START,
  GET_DETAILS_FOR_LINKING_FORM_SUCCESS,
  GET_DETAILS_FOR_LINKING_FORM_FAILURE,
  SET_LINKED_ORDER_ID,
  LINK_ORDERS_START,
  LINK_ORDERS_SUCCESS,
  LINK_ORDERS_FAILURE
} from "./linkingConstants";

const initialState = {
  linkingReasons: [],
  loadingLinkingReasons: false,
  checkingLinkedOrder: false,
  formData: {},
  loadingFormData: false,
  linkedOrderId: null,
  linkOrdersLoader: false
};

export const getLinkingReasonsStart = state => {
  return {
    ...state,
    linkingReasons: [],
    loadingLinkingReasons: true
  };
};

export const getLinkingReasonsSuccess = (state, payload) => {
  return {
    ...state,
    linkingReasons: payload,
    loadingLinkingReasons: false
  };
};
export const getLinkingReasonsFailure = state => {
  return {
    ...state,
    linkingReasons: [],
    loadingLinkingReasons: false
  };
};
export const checkLinkedOrderStart = state => {
  return {
    ...state,
    checkingLinkedOrder: true
  };
};
export const checkLinkedOrderSuccess = state => {
  return {
    ...state,
    checkingLinkedOrder: false
  };
};
export const checkLinkedOrderFailure = state => {
  return {
    ...state,
    checkingLinkedOrder: false
  };
};
export const setDataForLinkingForm = (state, payload) => {
  return {
    ...state,
    formData: payload
  };
};
export const getDetailsForLinkingFormStart = state => {
  return {
    ...state,
    formData: {},
    loadingFormData: true
  };
};
export const getDetailsForLinkingFormSuccess = (state, payload) => {
  return {
    ...state,
    formData: payload,
    loadingFormData: false
  };
};
export const getDetailsForLinkingFormFailure = state => {
  return {
    ...state,
    formData: {},
    loadingFormData: false
  };
};
export const setLinkedOrderId = (state, payload) => {
  return {
    ...state,
    linkedOrderId: payload
  };
};
export const linkOrdersStart = state => {
  return {
    ...state,
    linkOrdersLoader: true
  };
};

export const linkOrdersSuccess = state => {
  return {
    ...state,
    linkOrdersLoader: false
  };
};

export const linkOrdersFailure = state => {
  return {
    ...state,
    linkOrdersLoader: false
  };
};

export default createReducer(initialState, {
  [GET_LINKING_REASONS_START]: getLinkingReasonsStart,
  [GET_LINKING_REASONS_SUCCESS]: getLinkingReasonsSuccess,
  [GET_LINKING_REASONS_FAILURE]: getLinkingReasonsFailure,
  [CHECK_LINKED_ORDER_START]: checkLinkedOrderStart,
  [CHECK_LINKED_ORDER_SUCCESS]: checkLinkedOrderSuccess,
  [CHECK_LINKED_ORDER_FAILURE]: checkLinkedOrderFailure,
  [SET_DATA_FOR_LINKING_FORM]: setDataForLinkingForm,
  [GET_DETAILS_FOR_LINKING_FORM_START]: getDetailsForLinkingFormStart,
  [GET_DETAILS_FOR_LINKING_FORM_SUCCESS]: getDetailsForLinkingFormSuccess,
  [GET_DETAILS_FOR_LINKING_FORM_FAILURE]: getDetailsForLinkingFormFailure,
  [SET_LINKED_ORDER_ID]: setLinkedOrderId,
  [LINK_ORDERS_START]: linkOrdersStart,
  [LINK_ORDERS_SUCCESS]: linkOrdersSuccess,
  [LINK_ORDERS_FAILURE]: linkOrdersFailure
});
