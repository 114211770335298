import React, { Component } from "react";
import { connect } from "react-redux";
import Sidebar from "../../app/common/util/Sidebar";
import NavBar from "../nav/NavBar/NavBar";
import UtilMenus from "../nav/Menus/UtilMenus";
import TabView from "./tabView/TabView";
import { openModal } from "../modal/modalActions";
import { fetchCustomerWithoutDist } from "./homeActions";
import LoaderComponent from "../../app/common/util/LoaderComponent";
import {
  getItem,
  getUserPermissions
} from "../../app/common/util/genericUtils";
import { PermissibleRender } from "@brainhubeu/react-permissible";
import ValidateFromHome from "../orderPunching/modal/ValidateFromHome";
import LoadingModal from "../modal/LoadingModal";
class Dashboard extends Component {
  componentDidMount() {
    const customerList = getItem("customerList");
    if (!customerList || customerList.length < 1) {
      this.props.fetchCustomerWithoutDist();
    }
  }
  state = {};
  render() {
    const {
      openModal,
      loadingCustomerList,
      loadingOrderData,
      blankSelectionLoader
    } = this.props;
    const userPermissions = getUserPermissions();
    // if (loadingCustomerList || loadingOrderData || blankSelectionLoader) {
    //   return <LoaderComponent />;
    // }
    return (
      <div>
        {loadingCustomerList || loadingOrderData || blankSelectionLoader ? <LoadingModal/> : <span />}
        <Sidebar />
        <NavBar />
        <UtilMenus openModal={openModal} />
        <PermissibleRender
          userPermissions={userPermissions}
          requiredPermissions={["can_view_pendingauth_orders"]}
        >
          <ValidateFromHome />
        </PermissibleRender>
        <TabView />
      </div>
    );
  }
}

const actions = {
  openModal,
  fetchCustomerWithoutDist
};
const mapStateToProps = state => ({
  loadingCustomerList: state.home.loadingCustomerList,
  loadingOrderData: state.home.loadingOrderData,
  blankSelectionLoader: state.blankSelection.getOrderLoader
});

export default connect(
  mapStateToProps,
  actions
)(Dashboard);
