import {
  ORDER_CANCELLATION_START,
  ORDER_CANCELLATION_SUCCESS,
  ORDER_CANCELLATION_FAILURE
} from "./cancellationConstants";
import { successToast, errorToast } from "../../app/common/util/genericUtils";
import { cancelOrder } from "../home/api";
import { reportError } from "../../app/common/util/sentryConfig";
import { closeModal } from "../modal/modalActions";
import { fetchOnHoldOrders } from "../home/homeActions";

export const orderCancellationStart = () => {
  return {
    type: ORDER_CANCELLATION_START
  };
};
export const orderCancellationSuccess = () => {
  return {
    type: ORDER_CANCELLATION_SUCCESS
  };
};
export const orderCancellationFailure = () => {
  return {
    type: ORDER_CANCELLATION_FAILURE
  };
};

export const orderCancellation = (id, reason, actions) => async dispatch => {
  dispatch(orderCancellationStart());
  return cancelOrder(id, reason)
    .then(response => {
      if (response && response.data.response_code === 80) {
        dispatch(orderCancellationSuccess());
        successToast("Success", "Order is successfully cancelled");
        dispatch(closeModal());
        dispatch(actionsAfterCancellation(actions));
      } else {
        dispatch(orderCancellationFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch(error => {
      dispatch(orderCancellationFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};

export const actionsAfterCancellation = actions => dispatch => {
  console.log("actions are", actions);
  actions &&
    actions.forEach(action => {
      dispatch(actionsAfterCancellationMapper[action]());
    });
};

const actionsAfterCancellationMapper = {
  fetchOnHoldOrders: fetchOnHoldOrders
};
