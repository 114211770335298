import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "../../features/home/Dashboard";
import LoginForm from "../../features/auth/login/LoginForm";
import OrdersPage from "../../features/orderPunching/OrdersPage";
import ModalManager from "../../features/modal/ModalManager";
import SearchOrders from "../common/util/SearchOrders";

import ErrorBoundary from "../common/util/ErrorBoundry";
import { initializeSentry } from "../common/util/sentryConfig";
import OrderValidationPage from "../../features/orderPunching/OrderValidationPage";
import OrderDetailsPage from "../../features/home/orderDetails/OrderDetailsPage";
import WhatsappOrderPunching from "../../features/orderPunching/WhatsappOrderPunching";
import BlankSelectionPage from "../../features/blankSelection/BlankSelectionPage";
import LabelPrinting from "../../features/labelPrinting/LabelPrinting";

import OrderLinkingForm from "../../features/orderLinking/OrderLinkingForm";

import NotificationListPage from "../../features/internalNotifications/NotificationListPage";
import { getNotificationList } from "../../features/internalNotifications/notificationActions";
import ValidateFromHomePage from "../../features/orderPunching/ValidateFromHomePage";

class App extends Component {
  componentDidMount() {
    initializeSentry();
    const url = window.location.pathname;
    if (this.props.notificationList.length < 1 && url !== "/login") {
      this.props.getNotificationList();
    }
    setInterval(() => {
      if (url !== "/notification_list" && url !== "/login") {
        this.props.getNotificationList();
      }
    }, 30 * 60 * 1000);
  }

  render() {
    return (
      <div>
        <ErrorBoundary>
          <ModalManager />
          <Switch>
            <PrivateRoute exact path="/" component={Dashboard} />
            <PrivateRoute path="/search" exact component={SearchOrders} />
            <Route path="/login" component={LoginForm} />
            <PrivateRoute path="/create" component={OrdersPage} exact />
            <PrivateRoute
              path="/validate/:id"
              component={OrderValidationPage}
              exact
            />
            <PrivateRoute
              path="/validate/reference_id/:id"
              component={ValidateFromHomePage}
              exact
            />
            <PrivateRoute
              path="/order_details/:id"
              component={OrderDetailsPage}
              exact
            />

            <PrivateRoute
              path="/create/whatsapp_order/:id"
              component={WhatsappOrderPunching}
              exact
            />
            <PrivateRoute
              path="/blank_selection/:id"
              component={BlankSelectionPage}
            />
            <PrivateRoute
              path="/create/linked_order/:id"
              component={OrderLinkingForm}
              exact
            />
            <PrivateRoute
              path="/create/new_linked_order"
              component={OrderLinkingForm}
              exact
            />
            <PrivateRoute
              path="/notification_list"
              component={NotificationListPage}
              exact
            />
            <PrivateRoute
              path="/label_printing/:id"
              component={LabelPrinting}
            />
          </Switch>
        </ErrorBoundary>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  notificationList: state.notification.notificationList
});
const actions = {
  getNotificationList
};
export default connect(
  mapStateToProps,
  actions
)(App);
