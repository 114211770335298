import axios from "axios";
import { getItem, makeUrl } from "../../app/common/util/genericUtils";

export const getBlankSelectionDetails = id => {
  const token = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/orders/detail/internal-id/" + id),
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};

export const opcValidationApiCall = obj => {
  const token = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl(
      `/orders/blank-validation?order_id=${obj.orderId}&${obj.name}=${
        obj.newValue
      }`
    ),
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};

export const blankSelectionApiCall = data => {
  const token = getItem("suprolUser");
  return axios({
    method: "PUT",
    url: makeUrl("/orders/blank-selection/" + data.id),
    data,
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};
