export const FETCH_CUSTOMER_WITHOUT_DIST_START =
  "FETCH_CUSTOMER_WITHOUT_DIST_START";
export const FETCH_CUSTOMER_WITHOUT_DIST_SUCCESS =
  "FETCH_CUSTOMER_WITHOUT_DIST_SUCCESS";
export const FETCH_CUSTOMER_WITHOUT_DIST_FAILURE =
  "FETCH_CUSTOMER_WITHOUT_DIST_FAILURE";
export const FETCH_CUSTOMER_WITH_DIST_START = "FETCH_CUSTOMER_WITH_DIST_START";
export const FETCH_CUSTOMER_WITH_DIST_SUCCESS =
  "FETCH_CUSTOMER_WITH_DIST_SUCCESS";
export const FETCH_CUSTOMER_WITH_DIST_FAILURE =
  "FETCH_CUSTOMER_WITH_DIST_FAILURE";
export const SET_PAGE_FOR_CUSTOMER = "SET_PAGE_FOR_CUSTOMER";
export const FETCH_ONHOLD_ORDERS_START = "FETCH_ONHOLD_ORDERS_START";
export const FETCH_ONHOLD_ORDERS_SUCCESS = "FETCH_ONHOLD_ORDERS_SUCCESS";
export const FETCH_ONHOLD_ORDERS_FAILURE = "FETCH_ONHOLD_ORDERS_FAILURE";
export const FETCH_PENDING_AUTH_ORDERS_START =
  "FETCH_PENDING_AUTH_ORDERS_START";
export const FETCH_PENDING_AUTH_ORDERS_SUCCESS =
  "FETCH_PENDING_AUTH_ORDERS_SUCCESS";
export const FETCH_PENDING_AUTH_ORDERS_FAILURE =
  "FETCH_PENDING_AUTH_ORDERS_FAILURE";
export const FETCH_PROCESSING_ORDERS_START = "FETCH_PROCESSING_ORDERS_START";
export const FETCH_PROCESSING_ORDERS_SUCCESS =
  "FETCH_PROCESSING_ORDERS_SUCCESS";
export const FETCH_PROCESSING_ORDERS_FAILURE =
  "FETCH_PROCESSING_ORDERS_FAILURE";
export const FETCH_CANCELLED_ORDERS_START = "FETCH_CANCELLED_ORDERS_START";
export const FETCH_CANCELLED_ORDERS_SUCCESS = "FETCH_CANCELLED_ORDERS_SUCCESS";
export const FETCH_CANCELLED_ORDERS_FAILURE = "FETCH_CANCELLED_ORDERS_FAILURE";
export const FETCH_BLANK_SELECTION_ORDERS_START =
  "FETCH_BLANK_SELECTION_ORDERS_START";
export const FETCH_BLANK_SELECTION_ORDERS_SUCCESS =
  "FETCH_BLANK_SELECTION_ORDERS_SUCCESS";
export const FETCH_BLANK_SELECTION_ORDERS_FAILURE =
  "FETCH_BLANK_SELECTION_ORDERS_FAILURE";
export const ONHOLD_STATUS_CHANGE_START = "ONHOLD_STATUS_CHANGE_START";
export const ONHOLD_STATUS_CHANGE_SUCCESS = "ONHOLD_STATUS_CHANGE_SUCCESS";
export const ONHOLD_STATUS_CHANGE_FAILURE = "ONHOLD_STATUS_CHANGE_FAILURE";
export const GET_ORDER_DETAILS_START = "GET_ORDER_DETAILS_START";
export const GET_ORDER_DETAILS_SUCCESS = "GET_ORDER_DETAILS_SUCCESS";
export const GET_ORDER_DETAILS_FAILURE = "GET_ORDER_DETAILS_FAILURE";
export const VALIDATE_PENDING_AUTH_ORDERS_START =
  "VALIDATE_PENDING_AUTH_ORDERS_START";
export const VALIDATE_PENDING_AUTH_ORDERS_SUCCESS =
  "VALIDATE_PENDING_AUTH_ORDERS_SUCCESS";
export const VALIDATE_PENDING_AUTH_ORDERS_FAILURE =
  "VALIDATE_PENDING_AUTH_ORDERS_FAILURE";
export const PUT_ORDER_ON_HOLD_START = "PUT_ORDER_ON_HOLD_START";
export const PUT_ORDER_ON_HOLD_SUCCESS = "PUT_ORDER_ON_HOLD_SUCCESS";
export const PUT_ORDER_ON_HOLD_FAILURE = "PUT_ORDER_ON_HOLD_FAILURE";
export const SET_INDEX_FOR_ORDER = "SET_INDEX_FOR_ORDER";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_ACTIVE_TAB_KEY = "SET_ACTIVE_TAB_KEY";
export const CHECK_LENSWARE_STATUS_START = "CHECK_LENSWARE_STATUS_START";
export const CHECK_LENSWARE_STATUS_SUCCESS = "CHECK_LENSWARE_STATUS_SUCCESS";
export const CHECK_LENSWARE_STATUS_FAILURE = "CHECK_LENSWARE_STATUS_FAILURE";
export const RESET_HOME_STATE = "RESET_HOME_STATE";
export const FETCH_STOCK_ORDERS_LIST_START = "FETCH_STOCK_ORDERS_LIST_START";
export const FETCH_STOCK_ORDERS_LIST_SUCCESS =
  "FETCH_STOCK_ORDERS_LIST_SUCCESS";
export const FETCH_STOCK_ORDERS_LIST_FAILURE =
  "FETCH_STOCK_ORDERS_LIST_FAILURE";
export const PRINT_STOCK_ORDER_START = "PRINT_STOCK_ORDER_START";
export const PRINT_STOCK_ORDER_SUCCESS = "PRINT_STOCK_ORDER_SUCCESS";
export const PRINT_STOCK_ORDER_FAILURE = "PRINT_STOCK_ORDER_FAILURE";
export const ARCHIVE_STOCK_ORDER_START = "ARCHIVE_STOCK_ORDER_START";
export const ARCHIVE_STOCK_ORDER_SUCCESS = "ARCHIVE_STOCK_ORDER_SUCCESS";
export const ARCHIVE_STOCK_ORDER_FAILURE = "ARCHIVE_STOCK_ORDER_FAILURE";
export const CHECK_FORM_EDIT_STATUS_START = "CHECK_FORM_EDIT_STATUS_START";
export const CHECK_FORM_EDIT_STATUS_SUCCESS = "CHECK_FORM_EDIT_STATUS_SUCCESS";
export const CHECK_FORM_EDIT_STATUS_FAILURE = "CHECK_FORM_EDIT_STATUS_FAILURE";
export const UPDATE_ORDER_START = "UPDATE_ORDER_START";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILURE = "UPDATE_ORDER_FAILURE";
