import {
  GET_LABEL_PRINTING_DATA_START,
  GET_LABEL_PRINTING_DATA_SUCCESS,
  GET_LABEL_PRINTING_DATA_FAILURE
} from "./labelPrintingConstants";
import { labelPrintingApiCall } from "./api";
import { errorToast } from "../../app/common/util/genericUtils";
import { reportError } from "../../app/common/util/sentryConfig";
import { push } from "connected-react-router";
import { printAuthCard } from "../authCardPrinting/authCardActions";
import { message } from "antd";
export const getLabelPrintingDataStart = () => {
  return {
    type: GET_LABEL_PRINTING_DATA_START
  };
};
export const getLabelPrintingDataSuccess = data => {
  return {
    type: GET_LABEL_PRINTING_DATA_SUCCESS,
    payload: data
  };
};
export const getLabelPrintingDataFailure = () => {
  return {
    type: GET_LABEL_PRINTING_DATA_FAILURE
  };
};

export const getLabelPrintingData = (
  orderDetails,
  printAuthCardFlag
) => async dispatch => {
  dispatch(getLabelPrintingDataStart());
  message.info("Label Printing Initiated");
  return labelPrintingApiCall(orderDetails.id)
    .then(response => {
      if (response && response.data.response_code === 80) {
        message.success("Label Printing Success");
        console.log(response.data.data);
        let data = {
          derivedData: response.data.data,
          orderData: orderDetails
        };
        dispatch(getLabelPrintingDataSuccess(data));
        if (printAuthCardFlag === true && data.derivedData.is_oem === false) {
          dispatch(printAuthCard(orderDetails.id));
        }
        dispatch(push("/label_printing/" + orderDetails.id));
      } else {
        dispatch(getLabelPrintingDataFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch(error => {
      dispatch(getLabelPrintingDataFailure());
      errorToast("Error", error.ui_message);
      reportError(error);
    });
};
