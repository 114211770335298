import React, { Component } from "react";
import Commons from "../../orderDetails/Commons";
import {
  makeCommonsData,
  makeRightEyeConfig,
  makeLeftEyeConfig,
  makeFrameData,
  makeAddOnsData
} from "../formUtils";
import { Row } from "antd";
import RightLensConfig from "../../orderDetails/RightLensConfig";
import LeftLensConfig from "../../orderDetails/LeftLensConfig";
import Frame from "../../orderDetails/Frame";
import RightPrism from "../../orderDetails/RightPrism";
import LeftPrism from "../../orderDetails/LeftPrism";
import AddOns from "../../orderDetails/AddOns";
import {
  commonsKeyHeaderMap,
  rightLensHeaderMap,
  leftLensHeaderMap
} from "../constants";
import { isEmpty } from "../../../app/common/util/genericUtils";

class DuplicateOrderDetails extends Component {
  state = {};
  render() {
    const { details } = this.props;
    console.log(details);
    const commonsData = makeCommonsData(details);
    const rightLensConfig = makeRightEyeConfig(details);
    const leftLensConfig = makeLeftEyeConfig(details);
    const frameData = makeFrameData(details);
    const addOnsData = makeAddOnsData(details);
    return (
      <div className="details-container">
        <h3>Customer Section</h3>
        <Commons data={commonsData} headerMap={commonsKeyHeaderMap} />
        <h3>Lens Section</h3>
        <Row>
          <RightLensConfig
            data={rightLensConfig.right_lens_config}
            headerMap={rightLensHeaderMap}
          />
          <LeftLensConfig
            data={leftLensConfig.left_lens_config}
            headerMap={leftLensHeaderMap}
          />
        </Row>
        <h3>Frame Section</h3>
        <Frame data={frameData.frame} />
        <RightPrism
          data={
            isEmpty(frameData.frame) === true
              ? null
              : frameData.frame.right_prism
          }
        />
        <LeftPrism
          data={
            isEmpty(frameData.frame) === true
              ? null
              : frameData.frame.left_prism
          }
        />

        <div style={{ marginTop: "14%" }}>
          <h3>Add Ons Section</h3>
          <AddOns data={addOnsData.add_ons} />
        </div>
      </div>
    );
  }
}

export default DuplicateOrderDetails;
