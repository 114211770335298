import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Icon, Button } from "semantic-ui-react";
import Sidebar from "../../app/common/util/Sidebar";
import { connect } from "react-redux";
import NavBar from "../nav/NavBar/NavBar";
import OrderForm from "./OrderForm";
import { fetchFormData } from "./orderActions";
import { getOrderDetailsForForm } from "../home/homeActions";
import LoaderComponent from "../../app/common/util/LoaderComponent";
import { doesFormDataExist } from "./formUtils";
import { isEmpty } from "../../app/common/util/genericUtils";
import OrderDetailsActions from "../home/orderDetails/OrderDetailsActions";

class OrderValidationPage extends Component {
  state = {};
  componentWillMount() {
    const {
      match: { params },
      orderDetails,
      getOrderDetailsForForm
    } = this.props;
    const dataFlag = doesFormDataExist();
    dataFlag && this.props.fetchFormData();
    if (isEmpty(orderDetails)) {
      getOrderDetailsForForm(params.id);
    }
  }
  render() {
    const { orderDetails, loadingFormData } = this.props;
    if (loadingFormData || isEmpty(orderDetails)) {
      return <LoaderComponent />;
    }

    return (
      <div>
        <Sidebar />
        <Button
          as={Link}
          to="/"
          icon
          labelPosition="left"
          style={{ margin: "5% 0 0 10%" }}
        >
          <Icon name="left arrow" />
          Back to Orders
        </Button>
        <OrderForm
          initialValues={orderDetails}
          type="validate"
          labelInValue={true}
        />
        <NavBar />
      </div>
    );
  }
}
const actions = {
  fetchFormData,
  getOrderDetailsForForm
};
const mapStateToProps = state => ({
  loadingFormData: state.orderForm.loadingFormData,
  orderDetails: state.home.orderDetails
});

export default connect(
  mapStateToProps,
  actions
)(OrderValidationPage);
