import React, { Component } from "react";
import { Modal } from "antd";
import { connect } from "react-redux";
import { closeModal } from "./modalActions";
import CreateNotificationForm from "../internalNotifications/CreateNotificationForm";

class CreateNotificationModal extends Component {
  state = {};
  render() {
    const { closeModal } = this.props;
    return (
      <Modal
        title="Create Notification"
        visible={true}
        onCancel={closeModal}
        onOk={closeModal}
        footer={null}
        width="30%"
      >
        <CreateNotificationForm />
      </Modal>
    );
  }
}
const actions = { closeModal };
const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  actions
)(CreateNotificationModal);
