import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Menu, Sidebar } from "semantic-ui-react";
import { Icon } from "antd";
class CommonSidebar extends Component {
  render() {
    const url = window.location.pathname;
    return (
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        inverted
        vertical
        visible
        id="sidebar-color"
      >
        <Menu.Item as="a">
          <h2 className="side-heading">S</h2>
        </Menu.Item>
        <Link to="/">
          <Menu.Item className={url === "/" ? "active" : ""}>
            <Icon type="home" style={{ fontSize: "25px" }} />
          </Menu.Item>
        </Link>
        <Link to="/search">
          <Menu.Item className={url === "/search" ? "active" : ""}>
            <Icon type="search" style={{ fontSize: "25px" }} />
          </Menu.Item>
        </Link>
        <Link to="/notification_list">
          <Menu.Item className={url === "/notification_list" ? "active" : ""}>
            <Icon type="notification" style={{ fontSize: "25px" }} />
          </Menu.Item>
        </Link>
      </Sidebar>
    );
  }
}

export default CommonSidebar;
