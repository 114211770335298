import React, { Component } from "react";
import { connect } from "react-redux";
import { openModal } from "../../modal/modalActions";
import { submitForm } from "../orderActions";
import { Button } from "antd";
import { punchLinkingOrder } from "../../orderLinking/linkingActions";
const detailsModal = "DuplicateOrderDetailsModal";

class DuplicateOrdersList extends Component {
  state = {};
  render() {
    const {
      orderList,
      openModal,
      submitForm,
      orderData,
      type,
      punchLinkingOrder,
      submittingForm
    } = this.props;
    return (
      <div>
        <div style={{ marginBottom: "2%" }}>
          <h4>List of Internal Order Id's</h4>
        </div>
        <div>
          {orderList &&
            orderList.map((item, index) => {
              return (
                <div key={index}>
                  <span>{index + 1}. </span>
                  <span
                    className="link"
                    onClick={() => {
                      openModal(detailsModal, { details: item });
                    }}
                  >
                    {item.internal_order_id}
                  </span>
                </div>
              );
            })}
        </div>
        {type && type !== undefined && type === "linking" ? (
          <Button
            type="primary"
            style={{ marginTop: "20px" }}
            onClick={() => punchLinkingOrder(orderData)}
            loading={submittingForm}
          >
            Bypass Order
          </Button>
        ) : (
          <Button
            type="primary"
            style={{ marginTop: "20px" }}
            onClick={() => submitForm(orderData)}
            loading={submittingForm}
          >
            Bypass Order
          </Button>
        )}
      </div>
    );
  }
}
const actions = {
  openModal,
  submitForm,
  punchLinkingOrder
};
const mapStateToProps = state => ({
  orderData: state.orderForm.orderData,
  submittingForm: state.orderForm.submittingForm
});
export default connect(
  mapStateToProps,
  actions
)(DuplicateOrdersList);
