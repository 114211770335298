import React, { Component } from "react";
import { Button } from "antd";
import { connect } from "react-redux";
import { noMoreOrder, checkCustommerForOrder } from "../whatsappOrderActions";
class ImageModal extends Component {
  state = {};
  render() {
    const {
      imgData,
      noMoreOrder,
      checkCustommerForOrder,
      activeOrder
    } = this.props;
    console.log(imgData);
    return (
      <div>
        <div style={{ textAlign: "center" }}>
          <img
            src={imgData.twilio_image_url}
            style={{ width: "300px", height: "300px" }}
            alt="order_image"
          />
          <h3 style={{ marginTop: "3%", marginBottom: "3%" }}>
            Does the image contains another order?
          </h3>
        </div>

        <div style={{ justifyContent: "space-evenly", display: "flex" }}>
          <Button
            type="primary"
            onClick={() => checkCustommerForOrder(activeOrder)}
          >
            Yes
          </Button>
          <Button type="danger" onClick={() => noMoreOrder(imgData.id)}>
            No
          </Button>
        </div>
      </div>
    );
  }
}
export const actions = {
  noMoreOrder,
  checkCustommerForOrder
};
export const mapStateToProps = state => ({
  activeOrder: state.whatsappOrders.activeWhatsappOrder
});
export default connect(
  mapStateToProps,
  actions
)(ImageModal);
