import React from "react";
import { printMedia, getItem } from "../../app/common/util/genericUtils";
import { Row, Col } from "antd";
import {
  getCustomerFromId,
  getDistributorFromCustomerId
} from "../orderPunching/formUtils";

export const printWhatsappImage = data => () => {
  const userData = getItem("suprolUser");
  console.log("print", data);
  let customerData;
  if (data.customer !== null) {
    customerData = getCustomerFromId(data.customer);
  }
  const html = `
  <html>
  <head>
  </head>
  <body>
  <div style="position: relative;
  text-align: center;
  color: black;font-size:30px"><img src=${
    data.twilio_image_url
  } style="width: 100%; height: 100%" />
  <div style="position: absolute;
  bottom: 8px;
  left: 16px;text-align:left;"><p>${
    data.customer !== null ? customerData.name : "No Customer for this number"
  }</p><p>${new Date()}</p>${userData.user.username}</div>
  </div>
  </body>
  </html>
  `;
  printMedia(html);
};

export const makeCustomerData = data => {
  const customerData = {};
  const customer = getCustomerFromId(data.customer);
  let distributor;
  if (data.distributor !== null && data.distributor !== undefined) {
    distributor = getDistributorFromCustomerId(data.customer, data.distributor);
    console.log("disri", distributor);
    customerData.distributor = distributor;
  }
  console.log("whatsapp customer", customer);
  customerData.customer = {
    key: customer.id,
    label:
      customer.name + " - " + customer.nickname + " - " + customer.customer_accn
  };
  customerData.shipping_address = {
    key: customer.address[0].id,
    label:
      customer.address[0].address_line +
      " - " +
      customer.address[0].city.name +
      " - " +
      customer.address[0].pincode
  };
  customerData.shipping_same_as_billing = "true";
  customerData.dispatch_type = {
    key: customer.address[0].dispatch_type.id,
    label: customer.address[0].dispatch_type.value
  };
  customerData.urgent = "false";
  if (data.created_on !== null) {
    customerData.received_hour = new Date(data.created_on).getHours();
    customerData.received_minute = new Date(data.created_on).getMinutes();
    customerData.received_date = data.created_on;
  }
  return customerData;
};
export const dataWithoutCustomer = data => {
  const payload = {
    urgent: "false",
    shipping_same_as_billing: "true"
  };
  if (data.created_on !== null) {
    payload.received_hour = new Date(data.created_on).getHours();
    payload.received_minute = new Date(data.created_on).getMinutes();
    payload.received_date = data.created_on;
  }
  return payload;
};
export const getAddressList = customer => {
  let addressList = [];
  customer.address.forEach(adr => {
    const customerAddress = {
      label: adr.address_line + " - " + adr.city.name + " - " + adr.pincode,
      key: adr.id,
      dispatchType: {
        key: adr.dispatch_type.id,
        label: adr.dispatch_type.value
      }
    };
    addressList.push(customerAddress);
  });
  return addressList;
};

export const checkLargestNumberInArray = arr => {
  const max = Math.max.apply(null, arr);
  return max;
};
