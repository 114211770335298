import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { Button } from "antd";
import {
  archiveWhatsappOrder,
  printMediaWithRedirect,
  multipleOrderFlow
} from "./whatsappOrderActions";
import { confirmModal } from "../../app/common/util/genericUtils";
import { connect } from "react-redux";

class WhatsappOrderListItem extends Component {
  state = {};
  handleNotAnOrder = id => () => {
    confirmModal(
      "Are you sure, image does not have an order?",
      "Yes",
      "No",
      () => this.props.archiveWhatsappOrder(id)
    );
  };
  handleIsOrder = order => () => {
    const { multipleOrderFlow, printMediaWithRedirect } = this.props;
    confirmModal(
      "Does this image contain more than one order?",
      "Yes",
      "No",
      () => multipleOrderFlow(order),
      () => printMediaWithRedirect(order)
    );
  };
  render() {
    const { order } = this.props;
    return (
      <Grid.Column width={4}>
        <img
          src={order.twilio_image_url}
          style={{ width: "100%", height: "220px", borderRadius: "4%" }}
        />
        <Grid divided>
          <Grid.Row style={{ paddingTop: "8%" }}>
            <Grid.Column width={8}>
              <b>
                <p style={{ fontSize: "12px" }}>From:</p>
              </b>
              <span>{order.from_number}</span>
            </Grid.Column>
            <Grid.Column width={8}>
              <b>
                <p style={{ fontSize: "12px" }}>Date & Time:</p>
              </b>
              <span>time</span>
            </Grid.Column>
          </Grid.Row>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between"
            }}
          >
            <Button type="primary" onClick={this.handleIsOrder(order)}>
              Is Order
            </Button>
            <Button type="danger" onClick={this.handleNotAnOrder(order.id)}>
              Not an Order
            </Button>
          </div>
        </Grid>
      </Grid.Column>
    );
  }
}
const actions = {
  archiveWhatsappOrder,
  printMediaWithRedirect,
  multipleOrderFlow
};
export default connect(
  null,
  actions
)(WhatsappOrderListItem);
