import React, { Component } from "react";
import { connect } from "react-redux";
import { Select, Input, Button } from "antd";
import { orderCancellation } from "./cancellationActions";
const Option = Select.Option;
class Cancellation extends Component {
  state = {
    selectedOption: null,
    option: "",
    input: ""
  };
  handleChange = selectedOption => {
    this.setState({ selectedOption, option: selectedOption });
  };
  handleInput = e => {
    this.setState({ input: e.target.value });
  };
  handleSubmit = () => {
    const data = {};
    const { record, orderCancellation, actions } = this.props;
    const { selectedOption, input, option } = this.state;
    if (option === "other") {
      Object.assign(data, { reason: input });
    } else {
      Object.assign(data, {
        reason: selectedOption
      });
    }
    orderCancellation(record.id, data, actions);
  };
  render() {
    const { selectedOption, input, option } = this.state;
    const { cancellationLoader } = this.props;
    console.log(option);
    return (
      <div>
        <Select
          onChange={this.handleChange}
          placeholder="Select a Reason"
          style={{ width: "100%" }}
        >
          {options.map(item => {
            return (
              <Option key={item.value} label={item.label}>
                {item.value}
              </Option>
            );
          })}
        </Select>
        {option === "other" && (
          <Input.TextArea
            placeholder="Please provide additional comments"
            value={input}
            onChange={this.handleInput}
            rows={4}
            style={{ marginTop: "30px" }}
          />
        )}
        <Button
          style={{ marginTop: 30 }}
          block
          size="large"
          type="primary"
          disabled={
            selectedOption === null
              ? true
              : option === "other"
              ? input.length < 1
                ? true
                : false
              : false
          }
          onClick={this.handleSubmit}
          loading={cancellationLoader}
        >
          Submit
        </Button>
      </div>
    );
  }
}
const actions = { orderCancellation };
const mapStateToProps = state => ({
  cancellationLoader: state.cancellation.cancellationLoader
});

export default connect(
  mapStateToProps,
  actions
)(Cancellation);

const options = [
  { value: "blank short", label: "Blank Short" },
  {
    value: "confirmation from production",
    label: "Confirmation from Production"
  },
  { value: "request received (mail)", label: "Request Received (Mail)" },
  { value: "request received (call)", label: "Request Received (Call)" },
  {
    value: "request received (whatsapp)",
    label: "Request Received (WhatsApp)"
  },
  { value: "other", label: "Other" }
];
