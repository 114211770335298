import React, { Component } from "react";

import App from "./app/layout/App";
import { configureStore, history } from "./app/store/configureStore";
import ScrollToTop from "./app/common/util/ScrollToTop";

import { Provider } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import { ConnectedRouter } from "connected-react-router";

export const store = configureStore();

class ParentApp extends Component {
  state = {};
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ScrollToTop>
            <Switch>
              <Route path="/" component={App} />
            </Switch>
          </ScrollToTop>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default ParentApp;
