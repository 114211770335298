import * as Sentry from "@sentry/browser";
import { getItem } from "./genericUtils";

export const initializeSentry = () => { }
// Sentry.init({
//   dsn: "https://5581f3276af64154a38b647a38995f8b@sentry.io/1379884",
//   environment: process.env.NODE_ENV,
//   sendDefaultPii: true
//   // debug: true
// });

export const reportError = (error, userData) => { }
// Sentry.configureScope(scope => {
//   const existingUser = getItem("suprolUser");
//   if (existingUser) {
//     const userName = existingUser.user.username;
//     scope.setUser({ username: userName });
//     console.log(userName);
//     Sentry.captureException(error);
//   } else {
//     //console.log(userData.username);
//     scope.setUser({ username: userData.username });
//     Sentry.captureException(error);
//   }
// });

export const reportException = error => Sentry.captureException(error);

export const reportMessage = message => Sentry.captureMessage(message);
