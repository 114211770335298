import { createReducer } from "../../app/common/util/reducerUtil";
import {
  GET_LABEL_PRINTING_DATA_START,
  GET_LABEL_PRINTING_DATA_SUCCESS,
  GET_LABEL_PRINTING_DATA_FAILURE
} from "./labelPrintingConstants";

const initialState = {
  labelData: {},
  gettingLabelData: false
};
export const getLabelPrintingDataStart = state => {
  return {
    ...state,
    labelData: {},
    gettingLabelData: true
  };
};
export const getLabelPrintingDataSuccess = (state, data) => {
  return {
    ...state,
    labelData: data,
    gettingLabelData: false
  };
};
export const getLabelPrintingDataFailure = state => {
  return {
    ...state,
    labelData: {},
    gettingLabelData: false
  };
};

export default createReducer(initialState, {
  [GET_LABEL_PRINTING_DATA_START]: getLabelPrintingDataStart,
  [GET_LABEL_PRINTING_DATA_SUCCESS]: getLabelPrintingDataSuccess,
  [GET_LABEL_PRINTING_DATA_FAILURE]: getLabelPrintingDataFailure
});
