import React, { Component } from "react";
import { Col, Row } from "antd";
import { Header } from "semantic-ui-react";
import TextInput from "../../../app/common/form/TextInput";
import { Field } from "redux-form";
import FieldWrapper from "../FieldWrapper";

class LeftPrism extends Component {
  state = {};
  render() {
    return (
      <Row gutter={12} style={{ marginTop: "15px" }}>
        <FieldWrapper
          name="prva1"
          type="text"
          component={TextInput}
          placeholder="prism1_l"
          heading="Prism1_L"
          mandatory={false}
          width={6}
        />
        <FieldWrapper
          name="prvm1"
          type="text"
          component={TextInput}
          placeholder="base1_l"
          heading="Base1_L"
          mandatory={false}
          width={6}
        />
        <FieldWrapper
          name="prva2"
          type="text"
          component={TextInput}
          placeholder="prism2_l"
          heading="Prism2_L"
          mandatory={false}
          width={6}
        />
        <FieldWrapper
          name="prvm2"
          type="text"
          component={TextInput}
          placeholder="base2_l"
          heading="Base2_L"
          mandatory={false}
          width={6}
        />
      </Row>
    );
  }
}

export default LeftPrism;
