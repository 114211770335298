import React, { Component } from "react";
import { connect } from "react-redux";
import { Select } from "antd";
import { Input } from "antd";
import { Button } from "semantic-ui-react";
import { getLabelData, createLabel } from "./labelActions";
import LoaderComponent from "../../app/common/util/LoaderComponent";
const Option = Select.Option;

class CreateLabelForm extends Component {
  state = {
    selectedOption: null,
    input: ""
  };
  componentDidMount() {
    this.props.getLabelData();
  }
  handleChange = selectedOption => {
    this.setState({ selectedOption });
  };
  handleInput = e => {
    this.setState({ input: e.target.value });
  };
  handleSubmit = () => {
    const { selectedOption, input } = this.state;
    console.log(selectedOption);
    const data = {
      label: selectedOption.key,
      comment: input,
      order: this.props.orderId
    };
    this.props.createLabel(data);
  };
  render() {
    const { selectedOption, input } = this.state;
    const { labelData, createLabelLoader, loadingLabelList } = this.props;
    if (loadingLabelList) {
      return <LoaderComponent />;
    }
    return (
      <div>
        <Select
          onChange={this.handleChange}
          placeholder="Select a Label"
          style={{ width: "100%" }}
          labelInValue={true}
        >
          {labelData &&
            labelData.map((item, index) => {
              return (
                <Option key={index} value={item.id}>
                  {item.long_code}
                </Option>
              );
            })}
        </Select>

        <Input.TextArea
          placeholder="Label Comments"
          value={input}
          onChange={this.handleInput}
          rows={4}
          style={{ marginTop: "30px" }}
        />
        <Button
          style={{ marginTop: 30 }}
          fluid
          size="large"
          color="teal"
          loading={createLabelLoader}
          disabled={
            selectedOption === null ? true : input.length < 1 ? true : false
          }
          onClick={this.handleSubmit}
        >
          Submit
        </Button>
      </div>
    );
  }
}
const actions = {
  getLabelData,
  createLabel
};
const mapStateToProps = state => ({
  labelData: state.orderLabelling.labelData,
  loadingLabelList: state.orderLabelling.loadingLabelList,
  createLabelLoader: state.orderLabelling.createLabelLoader
});
export default connect(
  mapStateToProps,
  actions
)(CreateLabelForm);
