import React, { Component } from "react";
import Barcode from "react-barcode";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import moment from "moment";

class LabelPrinting extends Component {
  state = {};
  componentDidMount() {
    window.print();
    this.props.push("/");
  }
  render() {
    const { labelData } = this.props;
    const { derivedData, orderData } = labelData;
    console.log("derived Data", derivedData);
    console.log("orderData", orderData);

    if (orderData === null || orderData === undefined) {
      return <p>Direct Printing from url not allowed</p>;
    }
    return (
      <React.Fragment>
        {orderData.right_lens_config !== null && (
          <div style={{ margin: "0 30%" }}>
            <div className="row">
              <div className="col-8">
                <p style={{ fontSize: "22px", fontWeight: "bold" }}>
                  {orderData.customer.name}
                </p>
              </div>
              {/*              <div className="col-2">
                <p className="envelope-text">
                  {orderData.customer.customer_accn}
                </p>
              </div>
        */}{" "}
              <div className="col-4">
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "center"
                  }}
                >
                  {orderData.dispatch_type.value}
                </p>
              </div>
            </div>

            <div className="row" style={{ marginTop: "5%" }}>
              <div className="col-6">
                <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {orderData.internal_order_id}
                </p>
              </div>

              <div className="col-6">
                <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {orderData.external_order_id !== null
                    ? orderData.external_order_id
                    : "__"}
                </p>
              </div>
            </div>

            <div className="row" style={{ marginTop: "5%" }}>
              <div className="col-3">
                <p className="envelope-text">
                  {orderData.received_date !== null
                    ? moment(orderData.received_date).format("YYYY-MM-DD HH:mm")
                    : "__"}
                </p>
              </div>
              <div className="col-6">
                <p className="envelope-text">
                  {orderData.add_ons !== null
                    ? orderData.add_ons.tint !== null
                      ? orderData.add_ons.tint.code
                      : "__"
                    : "__"}
                </p>
              </div>

              <div className="col-3">
                <p className="envelope-text">
                  {orderData.add_ons !== null
                    ? orderData.add_ons.fitting_received === true
                      ? "Fitting"
                      : "_"
                    : "__"}
                </p>
              </div>
            </div>

            <div className="row" style={{ marginTop: "5%" }}>
              <div className="col-2">
                <div>
                  <p className="power-text">R</p>
                </div>
                <div>
                  <p className="power-text" style={{ marginTop: "6px" }}>
                    Given Power
                  </p>
                </div>
                <div>
                  <p className="power-text" style={{ marginTop: "6px" }}>
                    Calc. Power
                  </p>
                </div>
              </div>
              <div className="col-10">
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-3 center">
                        <p className="power-text">SPH</p>
                      </div>
                      <div className="col-3 center">
                        <p className="power-text">CYL</p>
                      </div>
                      <div className="col-2 center">
                        <p className="power-text">AX</p>
                      </div>
                      <div className="col-2 center">
                        <p className="power-text">ADD</p>
                      </div>
                      <div className="col-2 center">
                        <p className="power-text">DIA</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row" style={{ marginTop: "2%" }}>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-3 center">
                        <p className="power-text">
                          {orderData.right_lens_config !== null
                            ? orderData.right_lens_config.sph !== null
                              ? orderData.right_lens_config.sph
                              : "__"
                            : "__"}
                        </p>
                      </div>
                      <div className="col-3 center">
                        <p className="power-text">
                          {orderData.right_lens_config !== null
                            ? orderData.right_lens_config.cyl !== null
                              ? orderData.right_lens_config.cyl
                              : "__"
                            : "__"}
                        </p>
                      </div>
                      <div className="col-2 center">
                        <p className="power-text">
                          {orderData.right_lens_config !== null
                            ? orderData.right_lens_config.axis !== null
                              ? orderData.right_lens_config.axis
                              : "__"
                            : "__"}
                        </p>
                      </div>
                      <div className="col-2 center">
                        <p className="power-text">
                          {orderData.right_lens_config !== null
                            ? orderData.right_lens_config.add !== null
                              ? orderData.right_lens_config.add
                              : "__"
                            : "__"}
                        </p>
                      </div>
                      <div className="col-2 center">
                        <p className="power-text">
                          {orderData.right_lens_config !== null
                            ? orderData.right_lens_config.dia !== null
                              ? orderData.right_lens_config.dia
                              : "__"
                            : "__"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row" style={{ marginTop: "2%" }}>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-3 center">
                        <p className="power-text">
                          {derivedData.right_lw_data !== null
                            ? derivedData.right_lw_data.inssph !== null
                              ? derivedData.right_lw_data.inssph
                              : "__"
                            : "__"}
                        </p>
                      </div>
                      <div className="col-3 center">
                        <p className="power-text">
                          {derivedData.right_lw_data !== null
                            ? derivedData.right_lw_data.inscyl !== null
                              ? derivedData.right_lw_data.inscyl
                              : "__"
                            : "__"}
                        </p>
                      </div>
                      <div className="col-2 center">
                        <p className="power-text">
                          {derivedData.right_lw_data !== null
                            ? derivedData.right_lw_data.insaxis !== null
                              ? derivedData.right_lw_data.insaxis
                              : "__"
                            : "__"}
                        </p>
                      </div>
                      <div className="col-2 center">
                        <p className="power-text">
                          {derivedData.right_lw_data !== null
                            ? derivedData.right_lw_data.insadd !== null
                              ? derivedData.right_lw_data.insadd
                              : "__"
                            : "__"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: "2%" }}>
              <div className="col-12" style={{ textAlign: "center" }}>
                <Barcode
                  value={`${orderData.internal_order_id}R`}
                  displayValue={false}
                  format="CODE39"
                  height={44}
                  width={2}
                />
              </div>
            </div>
            <div className="row" style={{ marginTop: "2%" }}>
              <div className="col-12" style={{ textAlign: "center" }}>
                <Barcode
                  value={`${orderData.internal_order_id}`}
                  displayValue={false}
                  format="CODE39"
                  height={44}
                  width={2}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6" />
              <div className="col-6">
                <div>
                  <p className="envelope-text" style={{ margin: 0 }}>
                    {derivedData.lnam ? derivedData.lnam : "__"}
                  </p>
                </div>
                <div style={{ display: "flex" }}>
                  <p style={{ margin: 0 }} className="envelope-text">
                    {orderData.corridor !== null
                      ? " Cor: " + orderData.corridor.value + " "
                      : "Cor: __ "}
                  </p>
                  <p style={{ margin: 0 }} className="envelope-text">
                    {orderData.fitting_height !== null
                      ? "FH: " + orderData.fitting_height.value + " "
                      : "FH: __ "}
                  </p>
                  <p className="envelope-text">
                    {derivedData.coating !== null
                      ? "Coating: " + derivedData.coating
                      : "__"}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6" />
              <div className="col-6">
                <p className="envelope-text">
                  {orderData.shipping_address.location !== null
                    ? orderData.shipping_address.location
                    : "__"}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-6" />
              <div className="col-6">
                <p className="envelope-text">
                  {orderData.distributor !== null
                    ? orderData.distributor.name
                    : "__"}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-6" />
              <div className="col-6">
                <p className="envelope-text">
                  {orderData.retailer !== null ? orderData.retailer.name : "__"}
                </p>
              </div>
            </div>
          </div>
        )}
        {orderData.left_lens_config !== null && (
          <div style={{ margin: "0 30%", pageBreakBefore: "always" }}>
            <div className="row">
              <div className="col-8">
                <p style={{ fontSize: "22px", fontWeight: "bold" }}>
                  {orderData.customer.name}
                </p>
              </div>
              {/*              <div className="col-2">
        <p className="envelope-text">
          {orderData.customer.customer_accn}
        </p>
      </div>
*/}{" "}
              <div className="col-4">
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    textAlign: "center"
                  }}
                >
                  {orderData.dispatch_type.value}
                </p>
              </div>
            </div>

            <div className="row" style={{ marginTop: "5%" }}>
              <div className="col-6">
                <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {orderData.internal_order_id}
                </p>
              </div>

              <div className="col-6">
                <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                  {orderData.external_order_id !== null
                    ? orderData.external_order_id
                    : "__"}
                </p>
              </div>
            </div>

            <div className="row" style={{ marginTop: "5%" }}>
              <div className="col-3">
                <p className="envelope-text">
                  {orderData.received_date !== null
                    ? moment(orderData.received_date).format("YYYY-MM-DD HH:mm")
                    : "__"}
                </p>
              </div>
              <div className="col-6">
                <p className="envelope-text">
                  {orderData.add_ons !== null
                    ? orderData.add_ons.tint !== null
                      ? orderData.add_ons.tint.code
                      : "__"
                    : "__"}
                </p>
              </div>

              <div className="col-3">
                <p className="envelope-text">
                  {orderData.add_ons !== null
                    ? orderData.add_ons.fitting_received === true
                      ? "Fitting"
                      : "_"
                    : "__"}
                </p>
              </div>
            </div>

            <div className="row" style={{ marginTop: "5%" }}>
              <div className="col-2">
                <div>
                  <p className="power-text">L</p>
                </div>
                <div>
                  <p className="power-text" style={{ marginTop: "6px" }}>
                    Given Power
                  </p>
                </div>
                <div>
                  <p className="power-text" style={{ marginTop: "6px" }}>
                    Calc. Power
                  </p>
                </div>
              </div>
              <div className="col-10">
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-3 center">
                        <p className="power-text">SPH</p>
                      </div>
                      <div className="col-3 center">
                        <p className="power-text">CYL</p>
                      </div>
                      <div className="col-2 center">
                        <p className="power-text">AX</p>
                      </div>
                      <div className="col-2 center">
                        <p className="power-text">ADD</p>
                      </div>
                      <div className="col-2 center">
                        <p className="power-text">DIA</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row" style={{ marginTop: "2%" }}>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-3 center">
                        <p className="power-text">
                          {orderData.left_lens_config !== null
                            ? orderData.left_lens_config.sph !== null
                              ? orderData.left_lens_config.sph
                              : "__"
                            : "__"}
                        </p>
                      </div>
                      <div className="col-3 center">
                        <p className="power-text">
                          {orderData.left_lens_config !== null
                            ? orderData.left_lens_config.cyl !== null
                              ? orderData.left_lens_config.cyl
                              : "__"
                            : "__"}
                        </p>
                      </div>
                      <div className="col-2 center">
                        <p className="power-text">
                          {orderData.left_lens_config !== null
                            ? orderData.left_lens_config.axis !== null
                              ? orderData.left_lens_config.axis
                              : "__"
                            : "__"}
                        </p>
                      </div>
                      <div className="col-2 center">
                        <p className="power-text">
                          {orderData.left_lens_config !== null
                            ? orderData.left_lens_config.add !== null
                              ? orderData.left_lens_config.add
                              : "__"
                            : "__"}
                        </p>
                      </div>
                      <div className="col-2 center">
                        <p className="power-text">
                          {orderData.left_lens_config !== null
                            ? orderData.left_lens_config.dia !== null
                              ? orderData.left_lens_config.dia
                              : "__"
                            : "__"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row" style={{ marginTop: "2%" }}>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-3 center">
                        <p className="power-text">
                          {derivedData.left_lw_data !== null
                            ? derivedData.left_lw_data.inssph !== null
                              ? derivedData.left_lw_data.inssph
                              : "__"
                            : "__"}
                        </p>
                      </div>
                      <div className="col-3 center">
                        <p className="power-text">
                          {derivedData.left_lw_data !== null
                            ? derivedData.left_lw_data.inscyl !== null
                              ? derivedData.left_lw_data.inscyl
                              : "__"
                            : "__"}
                        </p>
                      </div>
                      <div className="col-2 center">
                        <p className="power-text">
                          {derivedData.left_lw_data !== null
                            ? derivedData.left_lw_data.insaxis !== null
                              ? derivedData.left_lw_data.insaxis
                              : "__"
                            : "__"}
                        </p>
                      </div>
                      <div className="col-2 center">
                        <p className="power-text">
                          {derivedData.left_lw_data !== null
                            ? derivedData.left_lw_data.insadd !== null
                              ? derivedData.left_lw_data.insadd
                              : "__"
                            : "__"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: "2%" }}>
              <div className="col-12" style={{ textAlign: "center" }}>
                <Barcode
                  value={`${orderData.internal_order_id}L`}
                  displayValue={false}
                  format="CODE39"
                  height={44}
                  width={2}
                />
              </div>
            </div>
            <div className="row" style={{ marginTop: "2%" }}>
              <div className="col-12" style={{ textAlign: "center" }}>
                <Barcode
                  value={`${orderData.internal_order_id}`}
                  displayValue={false}
                  format="CODE39"
                  height={44}
                  width={2}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6" />
              <div className="col-6">
                <div>
                  <p className="envelope-text" style={{ margin: 0 }}>
                    {derivedData.lnam ? derivedData.lnam : "__"}
                  </p>
                </div>
                <div style={{ display: "flex" }}>
                  <p style={{ margin: 0 }} className="envelope-text">
                    {orderData.corridor !== null
                      ? " Cor: " + orderData.corridor.value + " "
                      : "Cor: __ "}
                  </p>
                  <p style={{ margin: 0 }} className="envelope-text">
                    {orderData.fitting_height !== null
                      ? "FH: " + orderData.fitting_height.value + " "
                      : "FH: __ "}
                  </p>
                  <p className="envelope-text">
                    {derivedData.coating !== null
                      ? "Coating: " + derivedData.coating
                      : "__"}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6" />
              <div className="col-6">
                <p className="envelope-text">
                  {orderData.shipping_address.location !== null
                    ? orderData.shipping_address.location
                    : "__"}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-6" />
              <div className="col-6">
                <p className="envelope-text">
                  {orderData.distributor !== null
                    ? orderData.distributor.name
                    : "__"}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-6" />
              <div className="col-6">
                <p className="envelope-text">
                  {orderData.retailer !== null ? orderData.retailer.name : "__"}
                </p>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const actions = {
  push
};

const mapStateToProps = state => ({
  labelData: state.label.labelData
});

export default connect(
  mapStateToProps,
  actions
)(LabelPrinting);
