import React, { Component } from "react";
import { Modal } from "antd";
import { connect } from "react-redux";
import { closeModal } from "./modalActions";
import CreateLabelForm from "../orderLabelling/CreateLabelForm";

class CreateLabelModal extends Component {
  state = {};
  render() {
    const { closeModal, id } = this.props;
    return (
      <Modal
        title="Order Labelling"
        visible={true}
        onCancel={closeModal}
        onOk={closeModal}
        footer={null}
      >
        <CreateLabelForm orderId={id} />
      </Modal>
    );
  }
}
const actions = { closeModal };
const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  actions
)(CreateLabelModal);
