import {
  AUTH_CARD_PRINTING_START,
  AUTH_CARD_PRINTING_SUCCESS,
  AUTH_CARD_PRINTING_FAILURE
} from "./authCardConstants";
import { authCardApiCall } from "./api";
import { errorToast, successToast } from "../../app/common/util/genericUtils";
import { reportError } from "../../app/common/util/sentryConfig";
import { message } from "antd";
export const authCardPrintingStart = () => {
  return {
    type: AUTH_CARD_PRINTING_START
  };
};
export const authCardPrintingSuccess = () => {
  return {
    type: AUTH_CARD_PRINTING_SUCCESS
  };
};
export const authCardPrintingFailure = () => {
  return {
    type: AUTH_CARD_PRINTING_FAILURE
  };
};

export const printAuthCard = id => async dispatch => {
  dispatch(authCardPrintingStart());
  message.info("Requesting Auth Card");
  return authCardApiCall(id)
    .then(response => {
      if (response && response.data.response_code === 80) {
        dispatch(authCardPrintingSuccess());
        message.success("Auth Card Print Initiated");
      } else {
        dispatch(authCardPrintingFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch(error => {
      dispatch(authCardPrintingFailure());
      errorToast("Error", error.ui_message);
      reportError(error);
    });
};
