import React, { Component } from "react";
import { Modal } from "antd";
import { connect } from "react-redux";
import { closeModal } from "./modalActions";
import OrderSelection from "../blankSelection/modal/OrderSelection";

const actions = { closeModal };

class OrderSelectionModal extends Component {
  render() {
    const { closeModal } = this.props;
    return (
      <Modal
        title="Blank Selection"
        visible={true}
        onCancel={closeModal}
        width="25%"
        footer={null}
      >
        <OrderSelection />
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(OrderSelectionModal);
