import React, { Component } from "react";
import { Select, Button } from "antd";
import { connect } from "react-redux";
import { handleMultipleReasonModal } from "../blankSelectionActions";
const Option = Select.Option;

class MultipleSelectionReason extends Component {
  state = {
    option: null
  };
  handleChange = selectedOption => {
    this.setState({ option: selectedOption });
  };
  handleSubmit = () => {
    this.props.handleMultipleReasonModal(
      this.state.option,
      this.props.internalOrderId
    );
  };
  render() {
    const { option } = this.state;
    return (
      <div>
        <Select
          onChange={this.handleChange}
          placeholder="Select a Reason"
          style={{ width: "100%" }}
        >
          {options.map(item => {
            return (
              <Option key={item.value} label={item.label}>
                {item.value}
              </Option>
            );
          })}
        </Select>
        <Button
          type="primary"
          block
          style={{ marginTop: "6%", height: "40px" }}
          disabled={option === null ? true : false}
          onClick={this.handleSubmit}
        >
          Proceed to Selection
        </Button>
      </div>
    );
  }
}
const actions = {
  handleMultipleReasonModal
};
export default connect(
  null,
  actions
)(MultipleSelectionReason);

const options = [
  { value: "Reissue - UC", label: "Reissue - UC" },
  { value: "Reissue - HC", label: "Reissue - HC" },
  { value: "Reissue - ARC", label: "Reissue - ARC" },
  { value: "Incorrect Blank Selected", label: "Incorrect Blank Selected" },
  {
    value: "Blank has an cosmetic defect - dot/scratch",
    label: "Blank has an cosmetic defect - dot/scratch"
  },
  {
    value: "Blank has a power defect - curve/thickness/waves",
    label: "Blank has a power defect - curve/thickness/waves"
  }
];
