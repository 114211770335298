import React, { Component } from "react";
import { Modal } from "antd";
import { connect } from "react-redux";
import { closeModal } from "./modalActions";
import LinkingReasonForm from "../orderLinking/modal/LinkingReasonForm";
class LinkedReasonModal extends Component {
  state = {};
  render() {
    const { closeModal, linkingOrderId } = this.props;
    console.log(this.props);
    return (
      <Modal
        title="Order Linking Reason"
        visible={true}
        onCancel={closeModal}
        onOk={closeModal}
        footer={null}
        width="30%"
      >
        <LinkingReasonForm linkingOrderId={linkingOrderId} />
      </Modal>
    );
  }
}
const actions = { closeModal };
const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
  actions
)(LinkedReasonModal);
