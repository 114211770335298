import axios from "axios";
import { makeUrl, getItem } from "../../app/common/util/genericUtils";

export const formSubmitApiCall = data => {
  const user = getItem("suprolUser");
  return axios({
    method: "POST",
    url: makeUrl("/orders/create"),
    data: data,
    headers: {
      Authorization: `Token ${user.auth_token}`
    }
  });
};

export const getLnamList = () => {
  const user = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/master/lnam"),
    headers: {
      Authorization: `Token ${user.auth_token}`
    }
  });
};
export const getDivisionList = () => {
  const user = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/master/product/group"),
    headers: {
      Authorization: `Token ${user.auth_token}`
    }
  });
};
export const getTintList = () => {
  const user = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/master/tint"),
    headers: {
      Authorization: `Token ${user.auth_token}`
    }
  });
};

export const getEmployeeList = () => {
  const user = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/master/employee"),
    params: {
      id: user.business_unit
    },
    headers: {
      Authorization: `Token ${user.auth_token}`
    }
  });
};
export const getDispatchTypeList = () => {
  const user = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/master/dispatch"),
    headers: {
      Authorization: `Token ${user.auth_token}`
    }
  });
};
/*export const getBranchList = () => {
  const user = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/master/branch"),
    headers: {
      Authorization: `Token ${user.auth_token}`
    }
  });
};
*/
export const getManufacturingUnitList = () => {
  const user = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/master/has-manufacturing"),
    headers: {
      Authorization: `Token ${user.auth_token}`
    }
  });
};
export const getFieldDataForForm = () => {
  return axios.all([
    getLnamList(),
    getTintList(),
    getEmployeeList(),
    getDispatchTypeList(),
    //  getBranchList(),
    getManufacturingUnitList(),
    getDivisionList()
  ]);
};

export const checkInternalOrderId = id => {
  const user = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/orders/exist"),
    params: {
      internal_order_id: id
    },
    headers: {
      Authorization: `Token ${user.auth_token}`
    }
  });
};

export const duplicateOrdersApiCall = data => {
  const user = getItem("suprolUser");
  return axios({
    method: "POST",
    url: makeUrl("/orders/duplicate"),
    data,
    headers: {
      Authorization: `Token ${user.auth_token}`
    }
  });
};

export const orderFromReferenceId = id => {
  const user = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/orders/detail/reference-id/" + id),
    headers: {
      Authorization: `Token ${user.auth_token}`
    }
  });
};
