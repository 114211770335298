import React, { Component } from "react";
import { Form, Label } from "semantic-ui-react";
import moment from "moment";
import { DatePicker } from "antd";

class DateInput extends Component {
  state = {};
  onChange = (val, string) => {
    //console.log(val, string);
    const {
      input: { onBlur, onFocus },
      handleOnChange
    } = this.props;
    const local = moment(val).local();
    onBlur(local);
    //onFocus(local);
    if (handleOnChange) {
      handleOnChange();
    }
  };

  render() {
    const {
      input: { value, onBlur, onFocus, ...restInput },
      width,
      placeholder,
      meta: { touched, error },
      disableFunction
    } = this.props;
    return (
      <Form.Field error={touched && !!error} width={width}>
        <DatePicker
          {...restInput}
          value={value ? moment(value).local() : null}
          placeholder={placeholder}
          format="YYYY-MM-DD"
          onChange={this.onChange}
          size="large"
          style={{ width: "100%" }}
          disabledDate={disableFunction}
          //  showTime={{ use12Hours: true, format: "hh:mm" }}
        />
        {touched && error && (
          <Label basic color="red">
            {error}
          </Label>
        )}
      </Form.Field>
    );
  }
}

export default DateInput;
