import {
  GET_ORDER_FOR_BLANK_SELECTION_START,
  GET_ORDER_FOR_BLANK_SELECTION_SUCCESS,
  GET_ORDER_FOR_BLANK_SELECTION_FAILURE,
  CHECK_OPC_VALIDATION_START,
  CHECK_OPC_VALIDATION_SUCCESS,
  CHECK_OPC_VALIDATION_FAILURE,
  BLANK_SELECTION_START,
  BLANK_SELECTION_SUCCESS,
  BLANK_SELECTION_FAILURE,
  SET_MULTIPLE_BLANK_REASON
} from "./blankSelectionConstants";
import {
  getBlankSelectionDetails,
  opcValidationApiCall,
  blankSelectionApiCall
} from "./api";
import {
  errorToast,
  confirmModal,
  successToast
} from "../../app/common/util/genericUtils";
import { reportError } from "../../app/common/util/sentryConfig";
import { push } from "connected-react-router";
import { openModal, closeModal } from "../modal/modalActions";
import { Modal, message } from "antd";
import { getLabelPrintingData } from "../labelPrinting/labelPrintingActions";
import { setActiveTabKey } from "../home/homeActions";
export const getOrderForBlankSelectionStart = () => {
  return {
    type: GET_ORDER_FOR_BLANK_SELECTION_START
  };
};

export const getOrderForBlankSelectionSuccess = response => {
  return {
    type: GET_ORDER_FOR_BLANK_SELECTION_SUCCESS,
    payload: response
  };
};
export const getOrderForBlankSelectionFailure = () => {
  return {
    type: GET_ORDER_FOR_BLANK_SELECTION_FAILURE
  };
};

export const getOrderForBlankSelection = ({
  internal_order_id
}) => async dispatch => {
  dispatch(getOrderForBlankSelectionStart());
  return getBlankSelectionDetails(internal_order_id)
    .then(response => {
      if (response && response.data.response_code === 80) {
        dispatch(getOrderForBlankSelectionSuccess(response.data.data));
        dispatch(checkBlankSelectionStatus(response.data.data));
      } else {
        dispatch(getOrderForBlankSelectionFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch(error => {
      dispatch(getOrderForBlankSelectionFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};

export const checkBlankSelectionStatus = data => dispatch => {
  dispatch(closeModal());
  if (data.blank_selection_attempts === 0) {
    dispatch(push("/blank_selection/" + data.internal_order_id));
  } else if (data.blank_selection_attempts > 0) {
    confirmModal(
      "Blank has already been selected once, are you sure you want to re select the blank?",
      "Yes",
      "No",
      () =>
        dispatch(
          openModal("MultipleBlankReasonModal", {
            internalOrderId: data.internal_order_id
          })
        ),
      () => dispatch(openModal("OrderSelectionModal"))
    );
  }
};
export const setMultipleBlankReason = reason => {
  return {
    type: SET_MULTIPLE_BLANK_REASON,
    payload: reason
  };
};
export const handleMultipleReasonModal = (reason, id) => dispatch => {
  dispatch(setMultipleBlankReason(reason));
  dispatch(closeModal());
  Modal.destroyAll();
  dispatch(push("/blank_selection/" + id));
};
export const checkOpcValidationStart = () => {
  return {
    type: CHECK_OPC_VALIDATION_START
  };
};
export const checkOpcValidationSuccess = () => {
  return {
    type: CHECK_OPC_VALIDATION_SUCCESS
  };
};
export const checkOpcValidationFailure = () => {
  return {
    type: CHECK_OPC_VALIDATION_FAILURE
  };
};

export const checkOpcValidation = obj => dispatch => {
  dispatch(checkOpcValidationStart());
  return opcValidationApiCall(obj)
    .then(response => {
      console.log(response);
      if (response && response.data.response_code === 80) {
        dispatch(checkOpcValidationSuccess());
        if (response.data.data.is_valid === true) {
          successToast("Success", "OPC Matched");
        } else {
          errorToast("Error", "OPC doesn't matches.");
        }
      } else {
        dispatch(checkOpcValidationFailure());
        confirmModal(
          'Opc Doesn"t Match',
          "Yes",
          "No",
          () => {},
          () => {},
          "Do you want to by pass the order?"
        );
      }
    })
    .catch(error => {
      dispatch(checkOpcValidationFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};
/*  */
export const blankSelectionStart = () => {
  return {
    type: BLANK_SELECTION_START
  };
};
export const blankSelectionSuccess = () => {
  return {
    type: BLANK_SELECTION_SUCCESS
  };
};

export const blankSelectionFailure = () => {
  return {
    type: BLANK_SELECTION_FAILURE
  };
};

export const blankSelection = (blankData, orderDetails) => async dispatch => {
  dispatch(blankSelectionStart());
  message.info("Blank Selection Initiated");
  return blankSelectionApiCall(blankData)
    .then(response => {
      if (response && response.data.response_code === 80) {
        dispatch(blankSelectionSuccess());
        message.success("Blank Selection Done");
        if (orderDetails.blank_selection_attempts === 0) {
          dispatch(getLabelPrintingData(orderDetails, true));
        } else {
          dispatch(push("/"));
        }
        dispatch(setActiveTabKey("4"));
      } else {
        dispatch(blankSelectionFailure());
        errorToast("Error", response.ui_message);
      }
    })
    .catch(error => {
      dispatch(blankSelectionFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};
