import React, { Component } from "react";
import { Button } from "antd";
import { Form } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import {
  combineValidators,
  composeValidators,
  isRequired,
  hasLengthLessThan,
  hasLengthGreaterThan,
  isNumeric
} from "revalidate";
import TextInput from "../../../app/common/form/TextInput";
import { getOrderForBlankSelection } from "../blankSelectionActions";

class OrderSelection extends Component {
  render() {
    const {
      handleSubmit,
      invalid,
      getOrderForBlankSelection,
      loader
    } = this.props;
    return (
      <div>
        <Form error size="large" autoComplete="off">
          <Field
            name="internal_order_id"
            component={TextInput}
            type="text"
            placeholder="Internal Order Id"
            autoFocus={true}
          />

          <Button
            block
            type="primary"
            style={{ height: "38px" }}
            onClick={handleSubmit(getOrderForBlankSelection)}
            disabled={invalid}
            loading={loader}
          >
            Submit
          </Button>
        </Form>
      </div>
    );
  }
}
const validate = combineValidators({
  internal_order_id: composeValidators(
    isNumeric({ message: "Numeric value only" }),
    isRequired({ message: "Internal Order Id is required" }),
    hasLengthLessThan(13)({
      message: "Max. 12 characters allowed"
    }),
    hasLengthGreaterThan(7)({ message: "Min. 8 characters" })
  )()
});
const actions = {
  getOrderForBlankSelection
};
const mapStateToProps = state => ({
  loader: state.blankSelection.getOrderLoader
});
export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({ form: "OrderSelectionForm", validate, enableReinitialize: true })(
    OrderSelection
  )
);
