import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { closeModal } from "./modalActions";
import Cancellation from "../cancellation/Cancellation";

const actions = { closeModal };

class CancellationModal extends Component {
  render() {
    const { closeModal, record, actions } = this.props;
    return (
      <Modal size="mini" open={true} onClose={closeModal} closeIcon>
        <Modal.Header>Order Cancellation</Modal.Header>
        <Modal.Content>
          <Cancellation record={record} actions={actions} />
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(CancellationModal);
