import React, { Component } from "react";
import { Field } from "redux-form";
import { Header, Label, Grid, Form } from "semantic-ui-react";
import { MandatoryHeader, tooltips } from "./formUtils";
import { getItem, getUserType } from "../../app/common/util/genericUtils";
import { Tooltip, Col, Icon, Row } from "antd";
import { connect } from "react-redux";
import {
  setCustomerDependencies,
  setInitialManufacturingLocation
} from "./orderActions";
class FieldWrapper extends Component {
  state = {};
  componentDidMount() {
    let func = this.fieldPermissions[this.props.name].autoGenerateFunc;
    func !== null && func();
  }

  render() {
    const {
      name,
      placeholder,
      heading,
      component,
      type,
      disabled,
      mandatory,
      data,
      allowClear,
      labelInValue,
      showSearch,
      getSelectedValue,
      handleOnBlur,
      onChange,
      labelOne,
      valueOne,
      labelTwo,
      valueTwo,
      disableFunction,
      width,
      description,
      onFocus,
      validate
    } = this.props;
    let userType = getUserType();
    if (this.fieldPermissions[name].hiddenFor.includes(userType) === true) {
      return <span />;
    } else {
      if (type === "text") {
        return (
          <Col span={width}>
            {mandatory === true && <MandatoryHeader heading={heading} />}
            {mandatory === false && (
              <Header sub color="teal" content={heading} />
            )}
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }}>
                <Field
                  disabled={
                    disabled ||
                    this.fieldPermissions[name].disabledFor.includes(
                      userType
                    ) === true
                  }
                  name={name}
                  placeholder={placeholder}
                  component={component}
                  handleOnBlur={handleOnBlur}
                  validate={validate}
                />
              </div>
              {this.fieldPermissions[name].tooltip.showFor.includes(
                userType
              ) === true && (
                  <div style={{ padding: "3%" }}>
                    <Tooltip
                      overlayStyle={{ maxWidth: "100%" }}
                      title={this.fieldPermissions[name].tooltip.data}
                    >
                      <Icon type="question-circle" />
                    </Tooltip>
                  </div>
                )}
            </div>

            {this.fieldPermissions[name].message.showFor.includes(userType) ===
              true && (
                <Label basic color="violet" style={{ marginTop: "10px" }}>
                  {this.fieldPermissions[name].message.value}
                </Label>
              )}
            {description && (
              <Label basic color="blue" style={{ marginTop: "10px" }}>
                {description}
              </Label>
            )}
          </Col>
        );
      } else if (type === "select") {
        return (
          <Col span={width}>
            {mandatory === true && <MandatoryHeader heading={heading} />}
            {mandatory === false && (
              <Header sub color="teal" content={heading} />
            )}
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }}>
                <Field
                  disabled={
                    disabled ||
                    this.fieldPermissions[name].disabledFor.includes(
                      userType
                    ) === true
                  }
                  type={type}
                  name={name}
                  placeholder={placeholder}
                  component={component}
                  data={data}
                  allowClear={allowClear}
                  labelInValue={labelInValue}
                  showSearch={showSearch}
                  getSelectedValue={getSelectedValue}
                  onFocus={onFocus}
                  validate={validate}
                />
              </div>
              {this.fieldPermissions[name].tooltip.showFor.includes(
                userType
              ) === true && (
                  <div style={{ padding: "3%" }}>
                    <Tooltip
                      overlayStyle={{ maxWidth: "100%" }}
                      title={this.fieldPermissions[name].tooltip.data}
                    >
                      <Icon type="question-circle" />
                    </Tooltip>
                  </div>
                )}
            </div>
            {this.fieldPermissions[name].message.showFor.includes(userType) ===
              true && (
                <Label basic color="violet" style={{ marginTop: "10px" }}>
                  {this.fieldPermissions[name].message.value}
                </Label>
              )}
            {description && (
              <Label basic color="blue" style={{ marginTop: "10px" }}>
                {description}
              </Label>
            )}
          </Col>
        );
      } else if (type === "radio") {
        return (
          <Col span={width}>
            {mandatory === true && <MandatoryHeader heading={heading} />}
            {mandatory === false && (
              <Header sub color="teal" content={heading} />
            )}
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }}>
                <Form.Group inline>
                  <Field
                    disabled={
                      disabled ||
                      this.fieldPermissions[name].disabledFor.includes(
                        userType
                      ) === true
                    }
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    component={component}
                    onChange={onChange}
                    label={labelOne}
                    value={valueOne}
                  />
                  <Field
                    disabled={
                      disabled ||
                      this.fieldPermissions[name].disabledFor.includes(
                        userType
                      ) === true
                    }
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    component={component}
                    onChange={onChange}
                    label={labelTwo}
                    value={valueTwo}
                  />
                </Form.Group>
              </div>
              {this.fieldPermissions[name].tooltip.showFor.includes(
                userType
              ) === true && (
                  <div style={{ padding: "0 10%" }}>
                    <Tooltip
                      overlayStyle={{ maxWidth: "100%" }}
                      title={this.fieldPermissions[name].tooltip.data}
                    >
                      <Icon type="question-circle" />
                    </Tooltip>
                  </div>
                )}
            </div>

            {this.fieldPermissions[name].message.showFor.includes(userType) ===
              true && (
                <Label basic color="violet" style={{ marginTop: "10px" }}>
                  {this.fieldPermissions[name].message.value}
                </Label>
              )}
            {description && (
              <Label basic color="blue" style={{ marginTop: "10px" }}>
                {description}
              </Label>
            )}
          </Col>
        );
      } else if (type === "checkbox") {
        return (
          <Col span={width}>
            {mandatory === true && <MandatoryHeader heading={heading} />}
            {mandatory === false && (
              <Header sub color="teal" content={heading} />
            )}

            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Field
                  disabled={
                    disabled ||
                    this.fieldPermissions[name].disabledFor.includes(
                      userType
                    ) === true
                  }
                  type={type}
                  name={name}
                  placeholder={placeholder}
                  component={component}
                  onChange={onChange}
                />
              </div>
              {this.fieldPermissions[name].tooltip.showFor.includes(
                userType
              ) === true && (
                  <div>
                    <Tooltip
                      overlayStyle={{ maxWidth: "100%" }}
                      title={this.fieldPermissions[name].tooltip.data}
                    >
                      <Icon type="question-circle" />
                    </Tooltip>
                  </div>
                )}
            </div>

            {this.fieldPermissions[name].message.showFor.includes(userType) ===
              true && (
                <Label basic color="violet" style={{ marginTop: "10px" }}>
                  {this.fieldPermissions[name].message.value}
                </Label>
              )}
            {description && (
              <Label basic color="blue" style={{ marginTop: "10px" }}>
                {description}
              </Label>
            )}
          </Col>
        );
      } else if (type === "date") {
        return (
          <Col span={width}>
            {mandatory === true && <MandatoryHeader heading={heading} />}
            {mandatory === false && (
              <Header sub color="teal" content={heading} />
            )}
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }}>
                <Field
                  disabled={
                    disabled ||
                    this.fieldPermissions[name].disabledFor.includes(
                      userType
                    ) === true
                  }
                  name={name}
                  placeholder={placeholder}
                  component={component}
                  disableFunction={disableFunction}
                />
              </div>
              {this.fieldPermissions[name].tooltip.showFor.includes(
                userType
              ) === true && (
                  <div style={{ padding: "3%" }}>
                    <Tooltip
                      overlayStyle={{ maxWidth: "100%" }}
                      title={this.fieldPermissions[name].tooltip.data}
                    >
                      <Icon type="question-circle" />
                    </Tooltip>
                  </div>
                )}
            </div>

            {this.fieldPermissions[name].message.showFor.includes(userType) ===
              true && (
                <Label basic color="violet" style={{ marginTop: "10px" }}>
                  {this.fieldPermissions[name].message.value}
                </Label>
              )}
            {description && (
              <Label basic color="blue" style={{ marginTop: "10px" }}>
                {description}
              </Label>
            )}
          </Col>
        );
      }
    }
  }
  fieldPermissions = {
    reference_id: {
      disabledFor: [],
      autoGenerated: true,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    customer: {
      disabledFor: ["Branch", "Customer"],
      autoGenerated: true,
      autoGenerateFunc: this.props.setCustomerDependencies,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    distributor: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    retailer: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    product_group: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    patient: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    external_order_id: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    received_by: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: ["Branch", "Customer"],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    entered_by: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: ["Corporate"],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    manufacturing_business_unit: {
      disabledFor: [],
      autoGenerated: true,
      autoGenerateFunc: this.props.setInitialManufacturingLocation,
      hiddenFor: ["Customer"],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    shipping_same_as_billing: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    shipping_address: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },

    received_date: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: ["Branch", "Customer"],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },

    dispatch_type: {
      disabledFor: ["Branch", "Customer"],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value:
          "To change shipping mode, please contact lab after entering order here",
        showFor: ["Branch", "Customer"]
      }
    },

    received_hour: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: ["Branch", "Customer"],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    received_minute: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: ["Branch", "Customer"],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    urgent: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: tooltips.urgent, showFor: ["Branch", "Customer"] },
      message: {
        value:
          "Please note that urgent orders can’t be accepted beyond certain timelines. Please refer to the information attached. If you still have any queries, contact customer care",
        showFor: ["Branch", "Customer"]
      }
    },
    lnam: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: tooltips.lnam, showFor: ["Branch"] },
      message: {
        value: null,
        showFor: []
      }
    },
    coating: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    corridor: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: tooltips.corridor, showFor: ["Branch", "Customer"] },
      message: {
        value:
          "For Prolux range, Corridor is not chosen, and so will take a value automatically",
        showFor: ["Branch", "Customer"]
      }
    },
    fitting_height: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: {
        data: tooltips.fitting_height,
        showFor: ["Branch", "Customer"]
      },
      message: {
        value: null,
        showFor: []
      }
    },
    clinit: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    promised_date: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    promised_hour: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    promised_minute: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    cto: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: ["Branch", "Customer"],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    sph: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    cyl: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    axis: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    add: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    dia: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    ellh: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: ["Branch", "Customer"],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    swing: {
      disabledFor: ["Branch", "Customer"],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: tooltips.swing, showFor: ["Branch", "Customer"] },
      message: {
        value:
          "If you want to change the swing, place that order with customer care",
        showFor: ["Branch", "Customer"]
      }
    },
    mbase: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: tooltips.mbase, showFor: ["Branch", "Customer"] },
      message: {
        value: null,
        showFor: []
      }
    },
    ct: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: tooltips.ct, showFor: ["Branch", "Customer"] },
      message: {
        value: null,
        showFor: []
      }
    },
    et: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: tooltips.et, showFor: ["Branch", "Customer"] },
      message: {
        value: null,
        showFor: []
      }
    },
    ed: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: ["Branch", "Customer"],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    mono_pd_r: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: tooltips.mono_pd_r, showFor: ["Branch", "Customer"] },
      message: {
        value: null,
        showFor: []
      }
    },
    mono_pd_l: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: tooltips.mono_pd_l, showFor: ["Branch", "Customer"] },
      message: {
        value: null,
        showFor: []
      }
    },
    npd_r: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: tooltips.npd_r, showFor: ["Branch", "Customer"] },
      message: {
        value: "This measurement is only needed when booking a Prolux Product",
        showFor: ["Branch", "Customer"]
      }
    },
    npd_l: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: tooltips.npd_l, showFor: ["Branch", "Customer"] },
      message: {
        value: "This measurement is only needed when booking a Prolux Product",
        showFor: ["Branch", "Customer"]
      }
    },
    frame_type: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: tooltips.frame_type, showFor: ["Branch", "Customer"] },
      message: {
        value: null,
        showFor: []
      }
    },
    a_width: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: tooltips.a_width, showFor: ["Branch", "Customer"] },
      message: {
        value: null,
        showFor: []
      }
    },
    b_width: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: tooltips.b_width, showFor: ["Branch", "Customer"] },
      message: {
        value: null,
        showFor: []
      }
    },
    dbl: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: tooltips.dbl, showFor: ["Branch", "Customer"] },
      message: {
        value: null,
        showFor: []
      }
    },
    panto: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: tooltips.panto, showFor: ["Branch", "Customer"] },
      message: {
        value: "This measurement is only needed when booking a Prolux Product",
        showFor: ["Branch", "Customer"]
      }
    },
    ztilt: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: tooltips.ztilt, showFor: ["Branch", "Customer"] },
      message: {
        value: "This measurement is only needed when booking a Prolux Product",
        showFor: ["Branch", "Customer"]
      }
    },
    bvd: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: tooltips.bvd, showFor: ["Branch", "Customer"] },
      message: {
        value: "This measurement is only needed when booking a Prolux Product",
        showFor: ["Branch", "Customer"]
      }
    },
    specification: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    tint_needed: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: tooltips.tint, showFor: ["Branch", "Customer"] },
      message: {
        value: null,
        showFor: []
      }
    },
    tint: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: tooltips.tint, showFor: ["Branch", "Customer"] },
      message: {
        value: null,
        showFor: []
      }
    },
    fitting_received: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    special_instructions: {
      disabledFor: ["Branch", "Customer"],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value:
          "If you have any other requests or clarification about the order, please contact customer care.",
        showFor: ["Branch", "Customer"]
      }
    },
    tool_charges: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: ["Branch", "Customer"],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    other_charges: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: ["Branch", "Customer"],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    in_out: {
      disabledFor: [],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: ["Branch", "Customer"],
      tooltip: { data: null, showFor: [] },
      message: {
        value: null,
        showFor: []
      }
    },
    prva1: {
      disabledFor: ["Branch", "Customer"],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value:
          "If your order has prism, please consult with Customer care directly",
        showFor: ["Branch", "Customer"]
      }
    },
    prva2: {
      disabledFor: ["Branch", "Customer"],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value:
          "If your order has prism, please consult with Customer care directly",
        showFor: ["Branch", "Customer"]
      }
    },
    prvm1: {
      disabledFor: ["Branch", "Customer"],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value:
          "If your order has prism, please consult with Customer care directly",
        showFor: ["Branch", "Customer"]
      }
    },
    prvm2: {
      disabledFor: ["Branch", "Customer"],
      autoGenerated: false,
      autoGenerateFunc: null,
      hiddenFor: [],
      tooltip: { data: null, showFor: [] },
      message: {
        value:
          "If your order has prism, please consult with Customer care directly",
        showFor: ["Branch", "Customer"]
      }
    }
  };
}
const actions = {
  setCustomerDependencies,
  setInitialManufacturingLocation
};
const mapStateToProps = state => ({});
export default connect(
  mapStateToProps,
  actions
)(FieldWrapper);
