import React, { Component } from "react";
import { Grid, Header } from "semantic-ui-react";
import { Field, FormSection } from "redux-form";
import { Col, Row } from "antd";
import { connect } from "react-redux";
import TextInput from "../../../app/common/form/TextInput";
import { MandatoryHeader, getFrameValues } from "../formUtils";
import CustomSelect from "../../../app/common/form/CustomSelect";
import RightPrism from "./RightPrism";
import LeftPrism from "./LeftPrism";
import { customIsRequired } from "../orderFormValidations";
import { transformOnBlur } from "../orderActions";
import FieldWrapper from "../FieldWrapper";
class Frame extends Component {
  state = {};
  handleMonoPdR = (newValue, name) => {
    const { change, transformOnBlur } = this.props;
    //transformOnBlur(newValue, name);
    newValue ? change("frame.npd_r", newValue - 2) : change("frame.npd_r", "");
  };
  handleMonoPdL = (newValue, name) => {
    const { change, transformOnBlur } = this.props;
    // transformOnBlur(newValue, name);
    newValue ? change("frame.npd_l", newValue - 2) : change("frame.npd_l", "");
  };
  render() {
    const frameList = getFrameValues();
    const {
      labelInValue,
      showRightPrism,
      showLeftPrism,
      isPantoMandatory,
      isZtiltMandatory,
      isBvdMandatory,
      hideFieldsForFpLnams,
      change,
      transformOnBlur,
      showNpdForLnam,
      showMonoPdForLnam,
      npdDisabledFlag,
      pgDependentFields
    } = this.props;
    return (
      <Col span={24}>
        <Row gutter={12}>
          {showMonoPdForLnam === true && (
            <FieldWrapper
              name="mono_pd_r"
              type="text"
              component={TextInput}
              placeholder="Mono_Pd_R"
              handleOnBlur={this.handleMonoPdR}
              onFocus={() => window.scrollTo(0, 900)}
              heading="MONO_PD_R"
              mandatory={false}
              width={6}
            />
          )}
          {showNpdForLnam === true && (
            <FieldWrapper
              name="npd_r"
              type="text"
              component={TextInput}
              placeholder="NPD_R"
              //handleOnBlur={transformOnBlur}
              disabled={npdDisabledFlag}
              onFocus={() => window.scrollTo(0, 900)}
              heading="NPd_R"
              mandatory={false}
              width={6}
            />
          )}
          {showMonoPdForLnam === true && (
            <FieldWrapper
              name="mono_pd_l"
              type="text"
              component={TextInput}
              placeholder="Mono_Pd_L"
              handleOnBlur={this.handleMonoPdL}
              heading="MONO_PD_L"
              mandatory={false}
              width={6}
            />
          )}
          {showNpdForLnam === true && (
            <FieldWrapper
              name="npd_l"
              type="text"
              component={TextInput}
              placeholder="NPD_L"
              // handleOnBlur={transformOnBlur}
              disabled={npdDisabledFlag}
              heading="NPD_L"
              mandatory={false}
              width={6}
            />
          )}

          <FieldWrapper
            name="frame_type"
            type="select"
            placeholder="Frame Type"
            data={frameList}
            component={CustomSelect}
            allowClear={true}
            labelInValue={labelInValue}
            showSearch={true}
            onFocus={() => window.scrollTo(0, 900)}
            heading="Frame Type"
            mandatory={false}
            width={4}
          />

          <FieldWrapper
            name="a_width"
            type="text"
            component={TextInput}
            placeholder="A-width"
            heading="A-Width"
            mandatory={false}
            width={4}
          />

          <FieldWrapper
            name="b_width"
            type="text"
            component={TextInput}
            placeholder="B-width"
            heading="B-Width"
            mandatory={false}
            width={4}
          />

          <FieldWrapper
            name="dbl"
            type="text"
            component={TextInput}
            placeholder="dbl"
            heading="DBL"
            mandatory={false}
            width={4}
          />

          {hideFieldsForFpLnams === false && (
            <FieldWrapper
              name="panto"
              type="text"
              component={TextInput}
              placeholder="panto tilt"
              validate={isPantoMandatory === true ? customIsRequired : []}
              heading="Panto"
              mandatory={false}
              width={4}
            />
          )}
          {hideFieldsForFpLnams === false && (
            <FieldWrapper
              name="ztilt"
              type="text"
              component={TextInput}
              placeholder="Face Form Angle"
              validate={isZtiltMandatory === true ? customIsRequired : []}
              heading="Face Form Angle"
              mandatory={false}
              width={4}
            />
          )}
          {hideFieldsForFpLnams === false && (
            <FieldWrapper
              name="bvd"
              type="text"
              component={TextInput}
              placeholder="bvd"
              validate={isBvdMandatory === true ? customIsRequired : []}
              heading="Back Vertex Distance"
              mandatory={false}
              width={4}
            />
          )}
        </Row>
        {pgDependentFields.prism === true && showRightPrism === true && (
          <FormSection name="right_prism">
            <RightPrism />
          </FormSection>
        )}
        {pgDependentFields.prism === true && showLeftPrism === true && (
          <FormSection name="left_prism">
            <LeftPrism />
          </FormSection>
        )}
      </Col>
    );
  }
}
const mapStateToProps = state => ({
  showRightPrism: state.orderForm.showRightPrism,
  showLeftPrism: state.orderForm.showLeftPrism,
  isPantoMandatory: state.orderForm.isPantoMandatory,
  isZtiltMandatory: state.orderForm.isZtiltMandatory,
  isBvdMandatory: state.orderForm.isBvdMandatory,
  hideFieldsForFpLnams: state.orderForm.hideFieldsForFpLnams,
  showMonoPdForLnam: state.orderForm.showMonoPdForLnam,
  showNpdForLnam: state.orderForm.showNpdForLnam,
  npdDisabledFlag: state.orderForm.npdDisabledFlag,
  pgDependentFields: state.orderForm.pgDependentFields
});
const actions = {
  transformOnBlur
};
export default connect(
  mapStateToProps,
  actions
)(Frame);
