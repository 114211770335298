import { createReducer } from "../../app/common/util/reducerUtil";
import {
  CREATE_NOTIFICATION_START,
  CREATE_NOTIFICATION_SUCCESS,
  CREATE_NOTIFICATION_FAILURE,
  GET_NOTIFICATION_LIST_START,
  GET_NOTIFICATION_LIST_SUCCESS,
  GET_NOTIFICATION_LIST_FAILURE,
  SET_LATEST_NOTIFICATION
} from "./notificationConstants";
const initialState = {
  creatingNotification: false,
  fetchingList: false,
  notificationList: [],
  latestNotification: null
};

export const createNotificationStart = state => {
  return {
    ...state,
    creatingNotification: true
  };
};
export const createNotificationSuccess = state => {
  return {
    ...state,
    creatingNotification: false
  };
};
export const createNotificationFailure = state => {
  return {
    ...state,
    creatingNotification: false
  };
};

export const getNotificationListStart = state => {
  return {
    ...state,
    notificationList: [],
    fetchingList: true
  };
};
export const getNotificationListSuccess = (state, data) => {
  return {
    ...state,
    notificationList: data,
    fetchingList: false
  };
};
export const getNotificationListFailure = state => {
  return {
    ...state,
    notificationList: [],
    fetchingList: false
  };
};
export const setLatestNotification = (state, payload) => {
  return {
    ...state,
    latestNotification: payload
  };
};
export default createReducer(initialState, {
  [CREATE_NOTIFICATION_START]: createNotificationStart,
  [CREATE_NOTIFICATION_SUCCESS]: createNotificationSuccess,
  [CREATE_NOTIFICATION_FAILURE]: createNotificationFailure,
  [GET_NOTIFICATION_LIST_START]: getNotificationListStart,
  [GET_NOTIFICATION_LIST_SUCCESS]: getNotificationListSuccess,
  [GET_NOTIFICATION_LIST_FAILURE]: getNotificationListFailure,
  [SET_LATEST_NOTIFICATION]: setLatestNotification
});
