import { getItem, makeUrl } from "../../app/common/util/genericUtils";
import axios from "axios";

export const linkingReasonsApiCall = () => {
  const token = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/master/link"),
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};

export const getLinkedOrderDetails = id => {
  const token = getItem("suprolUser");
  return axios({
    method: "GET",
    url: makeUrl("/orders/detail/internal-id/" + id),
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};

export const linkOrdersApicall = data => {
  console.log("link dataaa", data);
  const token = getItem("suprolUser");
  return axios({
    method: "PUT",
    url: makeUrl("/orders/link/" + data.linkedOrderId),
    data: {
      linked_order: data.linkingOrderId,
      reason_for_linking: data.reason
    },
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};
