import React, { Component } from "react";
import { Pagination } from "antd";

class Paginater extends Component {
  state = {
    currentPage: 1
  };
  handleChange = page => {
    this.props.onChange(page);
    console.log(page);
    this.setState({ currentPage: page });
  };
  render() {
    const { total, defaultCurrent, defaultPageSize } = this.props;
    const { currentPage } = this.state;
    return (
      <Pagination
        total={total}
        current={currentPage}
        onChange={page => this.handleChange(page)}
        defaultCurrent={defaultCurrent}
        defaultPageSize={defaultPageSize}
      />
    );
  }
}

export default Paginater;
