import React from "react";
import { Form, Label } from "semantic-ui-react";

// const TextInput = ({
//   input,
//   width,
//   type,
//   placeholder,
//   disabled,
//   meta: { touched, error },
//   autoFocus
// }) => {

// };

class TextInput extends React.PureComponent {
  state = {
    value: ""
  };
  componentWillMount = () => {
    const {
      input: { value }
    } = this.props;

    this.setState({ value: value });
  };

  componentDidUpdate(previousProps) {
    if (previousProps.input.value !== this.props.input.value) {
      this.setState({ value: this.props.input.value });
    }
  }
  handleChange = e => {
    const { value } = e.target;
    this.setState({ value });
  };
  handleBlur = () => {
    const {
      input: { onBlur, name },
      handleOnBlur
    } = this.props;
    const { value } = this.state;
    onBlur(value);
    if (handleOnBlur) {
      handleOnBlur(value, name);
    }
  };
  render() {
    const {
      input: { onBlur, restInput },
      width,
      type,
      placeholder,
      disabled,
      meta: { touched, error },
      autoFocus,
      style
    } = this.props;
    const { value } = this.state;
    //    console.log(`OMG! ${placeholder} rendered again!`);

    return (
      <Form.Field error={touched && !!error} width={width}>
        <input
          {...restInput}
          type={type}
          onBlur={this.handleBlur}
          placeholder={placeholder}
          disabled={disabled}
          autoFocus={autoFocus}
          value={value}
          onChange={this.handleChange}
          style={style}
        />
        {touched && error && (
          <Label basic color="red" style={{ marginTop: "10px" }}>
            {error}
          </Label>
        )}
      </Form.Field>
    );
  }
}

export default TextInput;
