import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Icon, Button, Label } from "semantic-ui-react";
import Sidebar from "../../../app/common/util/Sidebar";
import NavBar from "../../nav/NavBar/NavBar";
import OrderDetails from "./OrderDetails";
import { connect } from "react-redux";
import { isEmpty } from "../../../app/common/util/genericUtils";
import { getOrderDetailsForPage } from "../homeActions";
import LoaderComponent from "../../../app/common/util/LoaderComponent";
import OrderDetailsActions from "./OrderDetailsActions";
import { Row, Col } from "antd";
import StockOrderDetails from "./StockOrderDetails";

class OrderDetailsPage extends Component {
  state = {};
  componentWillMount() {
    const {
      match: { params },
      details,
      getOrderDetailsForPage
    } = this.props;

    if (isEmpty(details)) {
      getOrderDetailsForPage(params.id);
    }
  }
  render() {
    const { details } = this.props;
    if (isEmpty(details)) {
      return <LoaderComponent />;
    }
    return (
      <div style={{ paddingTop: "80px" }}>
        <NavBar />
        <Sidebar />
        <div style={{ marginLeft: "10%" }}>
          <Row>
            <Col span={4}>
              <Button as={Link} to="/" icon labelPosition="left">
                <Icon name="left arrow" />
                Back to Orders
              </Button>
            </Col>
            <Col span={20}>
              {details && details.linked_order !== null && (
                <Row>
                  <Col span={4}>
                    <h2>Linked Order: </h2>
                  </Col>
                  <Col span={4}>
                    <p
                      style={{ marginTop: "4%" }}
                      className="validate-link"
                      onClick={() =>
                        this.props.getOrderDetailsForPage(
                          details.linked_order.id
                        )
                      }
                    >
                      {details.linked_order.internal_order_id}
                    </p>
                  </Col>
                  <Col span={16}>
                    {details && details.reason_for_linking !== null && (
                      <Label basic color="blue">
                        {"Linking Reason:" + details.reason_for_linking}
                      </Label>
                    )}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </div>
        <OrderDetailsActions
          id={details.id}
          status={details.status}
          details={details}
        />
        {details.is_stock === false ? (
          <OrderDetails
            details={details}
            highlightedFields={highlightedFields}
            id={details.id}
          />
        ) : (
          <StockOrderDetails details={details} />
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  details: state.home.orderDetails
});
const actions = {
  getOrderDetailsForPage
};
export default connect(
  mapStateToProps,
  actions
)(OrderDetailsPage);

const highlightedFields = {
  lnam: "lnam",
  sph: "sph",
  customer: "customer",
  add: "add",
  received_date: "received_date"
};
