import React from "react";
import { Loader, Dimmer } from "semantic-ui-react";

const LoaderComponent = ({ size }) => (
  <Dimmer active inverted>
    <Loader size={size}>Loading</Loader>
  </Dimmer>
);

export default LoaderComponent;
