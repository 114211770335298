import { createReducer } from "../../app/common/util/reducerUtil";
import {
  ORDER_CANCELLATION_START,
  ORDER_CANCELLATION_SUCCESS,
  ORDER_CANCELLATION_FAILURE
} from "./cancellationConstants";

const initialState = {
  cancellationLoader: false
};

export const orderCancellationStart = state => {
  return {
    ...state,
    cancellationLoader: true
  };
};
export const orderCancellationSuccess = state => {
  return {
    ...state,
    cancellationLoader: false
  };
};
export const orderCancellationFailure = state => {
  return {
    ...state,
    cancellationLoader: false
  };
};

export default createReducer(initialState, {
  [ORDER_CANCELLATION_START]: orderCancellationStart,
  [ORDER_CANCELLATION_SUCCESS]: orderCancellationSuccess,
  [ORDER_CANCELLATION_FAILURE]: orderCancellationFailure
});
