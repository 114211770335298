import axios from "axios";
import { getItem, makeUrl } from "../../app/common/util/genericUtils";

export const authCardApiCall = id => {
  const token = getItem("suprolUser");
  return axios({
    method: "POST",
    url: makeUrl("/orders/auth-print/create-queue/" + id),
    headers: {
      Authorization: `Token ${token.auth_token}`
    }
  });
};
