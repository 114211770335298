import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "../table/Table";
import Paginater from "../table/Paginater";
import moment from "moment";
import {
  fetchStockOrdersList,
  getOrderDetailsForPage,
  archiveStockOrder,
  printStockOrder
} from "../../homeActions";
import { openModal } from "../../../modal/modalActions";
import { Tag, Button, Row, Col } from "antd";
class StockOrdersTab extends Component {
  state = {};
  componentDidMount() {
    // if (this.props.activeTabKey === "5") {
    // this.props.fetchStockOrdersList();
    //}
  }
  columns = [
    {
      title: "Order No.",
      dataIndex: "internal_order_id",
      sorter: (a, b) => a.internal_order_id - b.internal_order_id,
      sortDirections: ["ascend"],
      render: (text, record, index) => (
        <p
          className="validate-link"
          onClick={() => this.props.getOrderDetailsForPage(record.id, index)}
        >
          {text}
        </p>
      )
    },
    {
      title: "Reference Id",
      dataIndex: "reference_id"
    },
    {
      title: "External Order Id",
      dataIndex: "external_order_id"
    },
    {
      title: "Entry Date & Time",
      dataIndex: "received_date",
      render: text => {
        if (text !== null) {
          return <p>{moment(text).format("YYYY-MM-DD HH:mm")}</p>;
        }
      }
    },
    {
      title: "Coating",
      dataIndex: "coating.value"
    },
    {
      title: "LNAM",
      dataIndex: "lnam.name"
    },
    {
      title: "Customer",
      dataIndex: "customer.name"
    },
    {
      title: "Promise Date & Time",
      dataIndex: "promised_date",
      render: text => {
        if (text !== null) {
          return <p>{moment(text).format("YYYY-MM-DD HH:mm")}</p>;
        }
      }
    },
    {
      title: "Labels",
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {record.label.length > 0 &&
              record.label.map((item, index) => {
                return (
                  <Tag color="blue" key={index}>
                    {item.label.short_code}
                  </Tag>
                );
              })}
          </div>
        );
      }
    },
    {
      title: "Actions",
      fixed: "right",
      width: 200,
      render: (text, record) => {
        return (
          <Row>
            <Col span={12}>
              <Button
                style={{ margin: "7% 0 0 5%" }}
                size="small"
                type="primary"
                onClick={() => this.props.archiveStockOrder(record.id)}
              >
                Mark Done
              </Button>
            </Col>

            <Col span={12}>
              <Button
                style={{ margin: "7% 0 0 5%" }}
                size="small"
                type="primary"
                onClick={this.handleLabelModal(record.id)}
              >
                Add Label
              </Button>
            </Col>
          </Row>
        );
      }
    }
  ];
  handleLabelModal = id => () => {
    this.props.openModal("CreateLabelModal", { id });
  };
  handleOrderPrint = id => () => {
    window.print();
    this.props.printStockOrder(id);
  };
  render() {
    const { loading, orders, fetchStockOrdersList } = this.props;
    return (
      <div>
        <Table
          columns={this.columns}
          loading={loading}
          data={orders.results}
          defaultPageSize={40}
          pagination={false}
          bordered={true}
          scroll={{ x: 1300 }}
          size="small"
        />
        <div style={{ textAlign: "center", marginTop: "4%" }}>
          <Paginater
            defaultCurrent={1}
            total={orders.count}
            onChange={fetchStockOrdersList}
            defaultPageSize={40}
          />
        </div>
      </div>
    );
  }
}
const actions = {
  fetchStockOrdersList,
  getOrderDetailsForPage,
  archiveStockOrder,
  printStockOrder,
  openModal
};
const mapStateToProps = state => ({
  loading: state.home.loadingStockOrders,
  orders: state.home.stockOrders,
  activeTabKey: state.home.activeTabKey
});
export default connect(
  mapStateToProps,
  actions
)(StockOrdersTab);
