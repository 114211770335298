import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Icon, Button, Label } from "semantic-ui-react";
import Sidebar from "../../app/common/util/Sidebar";
import { connect } from "react-redux";
import NavBar from "../nav/NavBar/NavBar";
import OrderForm from "../orderPunching/OrderForm";
import { fetchFormData } from "../orderPunching/orderActions";
import { getDetailsForLinkingForm } from "./linkingActions";
import LoaderComponent from "../../app/common/util/LoaderComponent";
import { doesFormDataExist } from "../orderPunching/formUtils";
import { isEmpty } from "../../app/common/util/genericUtils";

class OrderLinkingForm extends Component {
  state = {};
  componentWillMount() {
    const {
      match: { params },
      orderDetails,
      getDetailsForLinkingForm
    } = this.props;
    const dataFlag = doesFormDataExist();
    dataFlag && this.props.fetchFormData();
    if (isEmpty(orderDetails)) {
      if (params && params.id !== undefined) {
        getDetailsForLinkingForm(params.id);
      }
    }
  }
  render() {
    const { orderDetails, loadingFormData } = this.props;
    if (loadingFormData) {
      return <LoaderComponent />;
    }

    return (
      <div>
        <Sidebar />
        <Button
          as={Link}
          to="/"
          icon
          labelPosition="left"
          style={{ margin: "5% 0 0 10%" }}
        >
          <Icon name="left arrow" />
          Back to Orders
        </Button>
        {orderDetails && orderDetails.id !== undefined && (
          <Label basic color="red">
            Please change the Received and Promised Date & time
          </Label>
        )}
        <OrderForm
          initialValues={orderDetails}
          type="linking"
          labelInValue={true}
        />
        <NavBar />
      </div>
    );
  }
}
const actions = {
  fetchFormData,
  getDetailsForLinkingForm
};
const mapStateToProps = state => ({
  loadingFormData: state.linking.loadingFormData,
  orderDetails: state.linking.formData
});

export default connect(
  mapStateToProps,
  actions
)(OrderLinkingForm);
