import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Icon, Button } from "semantic-ui-react";
import Sidebar from "../../app/common/util/Sidebar";
import NavBar from "../nav/NavBar/NavBar";
import OrderDetails from "../home/orderDetails/OrderDetails";
import { connect } from "react-redux";
import { isEmpty } from "../../app/common/util/genericUtils";
import { getOrderForBlankSelection } from "./blankSelectionActions";
import LoaderComponent from "../../app/common/util/LoaderComponent";
import BlankSelectionForm from "./BlankSelectionForm";

class BlankSelectionPage extends Component {
  state = {};
  componentWillMount() {
    const {
      match: { params },
      details,
      getOrderForBlankSelection
    } = this.props;

    if (isEmpty(details)) {
      getOrderForBlankSelection({ internal_order_id: params.id });
    }
  }
  render() {
    const { details } = this.props;
    if (isEmpty(details)) {
      return <LoaderComponent />;
    }
    return (
      <div>
        <Sidebar />
        <Button
          as={Link}
          to="/"
          icon
          labelPosition="left"
          style={{ margin: "5% 0 0 10%" }}
        >
          <Icon name="left arrow" />
          Back to Orders
        </Button>
        <BlankSelectionForm orderId={details.id} orderDetails={details} />
        <OrderDetails details={details} highlightedFields={highlightedFields} />
        <NavBar />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  details: state.blankSelection.orderDetails
});
const actions = {
  getOrderForBlankSelection
};
export default connect(
  mapStateToProps,
  actions
)(BlankSelectionPage);

const highlightedFields = {
  lnam: "lnam",
  coating: "coating",
  received_date: "received_date",
  received_hour: "received_hour",
  received_minute: "received_minute",
  add: "add"
};
