import React, { Component } from "react";
import { connect } from "react-redux";

import WhatsappOrderList from "./WhatsappOrderList";
import { getWhatsappOrdersList } from "./whatsappOrderActions";
import LoaderComponent from "../../app/common/util/LoaderComponent";
import Paginater from "../home/tabView/table/Paginater";

class WhatsappOrders extends Component {
  state = {};
  componentWillMount() {
    this.props.getWhatsappOrdersList();
  }
  render() {
    const { orders, loading, getWhatsappOrdersList } = this.props;
    console.log(orders);
    return (
      <div className="whatsapp-list-view">
        {loading ? (
          <LoaderComponent size="medium" />
        ) : (
          <div>
            <WhatsappOrderList orders={orders.results} />
          </div>
        )}
        <div style={{ margin: "5% 0 1% 0", textAlign: "center" }}>
          <Paginater
            defaultCurrent={1}
            total={orders.count}
            onChange={getWhatsappOrdersList}
            defaultPageSize={8}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  orders: state.whatsappOrders.orders,
  loading: state.whatsappOrders.loading
});
const actions = {
  getWhatsappOrdersList
};

export default connect(
  mapStateToProps,
  actions
)(WhatsappOrders);
