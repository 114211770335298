import {
  GET_WHATSAPP_ORDERS,
  GET_WHATSAPP_ORDERS_FAILURE,
  GET_WHATSAPP_ORDERS_SUCCESS,
  ARCHIVE_ORDER_START,
  ARCHIVE_ORDER_SUCCESS,
  ARCHIVE_ORDER_FAILURE,
  PUNCH_WHATSAPP_ORDER_START,
  PUNCH_WHATSAPP_ORDER_SUCCESS,
  PUNCH_WHATSAPP_ORDER_FAILURE,
  SET_CURRENT_ACTIVE_ORDER,
  SET_MULTIPLE_ORDER_FLAG,
  SET_DATA_FOR_FORM,
  GET_INTERNAL_ORDER_ID_START,
  GET_INTERNAL_ORDER_ID_SUCCESS,
  GET_INTERNAL_ORDER_ID_FAILURE
} from "./whatsappOrderConstants";
import { createReducer } from "../../app/common/util/reducerUtil";

const initialState = {
  orders: [],
  loading: false,
  punchingOrder: false,
  formData: {},
  activeWhatsappOrder: {},
  multipleOrderFlag: false,
  loadingOrderId: false
};

export const getWhatsappOrders = (state, payload) => {
  return {
    ...state,
    loading: true
  };
};
export const getWhatsappOrdersSuccess = (state, payload) => {
  return {
    ...state,
    orders: payload,
    loading: false
  };
};
export const getWhatsappOrdersFailure = (state, payload) => {
  return {
    ...state,
    orders: [],
    loading: false
  };
};
export const archiveOrderStart = state => {
  return {
    ...state
  };
};
export const archiveOrderSuccess = state => {
  return {
    ...state
  };
};
export const archiveOrderFailure = state => {
  return {
    ...state
  };
};
export const punchWhatsappOrderStart = state => {
  return {
    ...state,
    punchingOrder: true
  };
};
export const punchWhatsappOrderSuccess = state => {
  return {
    ...state,
    punchingOrder: false
  };
};
export const punchWhatsappOrderFailure = state => {
  return {
    ...state,
    punchingOrder: false
  };
};
export const setDataForForm = (state, payload) => {
  return {
    ...state,
    formData: payload
  };
};
export const setCurrentActiveOrder = (state, payload) => {
  return {
    ...state,
    activeWhatsappOrder: payload
  };
};
export const setMultipleOrderFlag = (state, payload) => {
  return {
    ...state,
    multipleOrderFlag: payload
  };
};
export const getInternalOrderIdStart = state => {
  return {
    ...state,
    loadingOrderId: true
  };
};
export const getInternalOrderIdSuccess = state => {
  return {
    ...state,
    loadingOrderId: false
  };
};
export const getInternalOrderIdFailure = state => {
  return {
    ...state,
    loadingOrderId: false
  };
};
export default createReducer(initialState, {
  [GET_WHATSAPP_ORDERS]: getWhatsappOrders,
  [GET_WHATSAPP_ORDERS_SUCCESS]: getWhatsappOrdersSuccess,
  [GET_WHATSAPP_ORDERS_FAILURE]: getWhatsappOrdersFailure,
  [ARCHIVE_ORDER_START]: archiveOrderStart,
  [ARCHIVE_ORDER_SUCCESS]: archiveOrderSuccess,
  [ARCHIVE_ORDER_FAILURE]: archiveOrderFailure,
  [PUNCH_WHATSAPP_ORDER_START]: punchWhatsappOrderStart,
  [PUNCH_WHATSAPP_ORDER_SUCCESS]: punchWhatsappOrderSuccess,
  [PUNCH_WHATSAPP_ORDER_FAILURE]: punchWhatsappOrderFailure,
  [SET_DATA_FOR_FORM]: setDataForForm,
  [SET_CURRENT_ACTIVE_ORDER]: setCurrentActiveOrder,
  [SET_MULTIPLE_ORDER_FLAG]: setMultipleOrderFlag,
  [GET_INTERNAL_ORDER_ID_START]: getInternalOrderIdStart,
  [GET_INTERNAL_ORDER_ID_SUCCESS]: getInternalOrderIdSuccess,
  [GET_INTERNAL_ORDER_ID_FAILURE]: getInternalOrderIdFailure
});
