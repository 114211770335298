import { createReducer } from "../../app/common/util/reducerUtil";
import {
  GET_ORDER_FOR_BLANK_SELECTION_START,
  GET_ORDER_FOR_BLANK_SELECTION_SUCCESS,
  GET_ORDER_FOR_BLANK_SELECTION_FAILURE,
  CHECK_OPC_VALIDATION_START,
  CHECK_OPC_VALIDATION_SUCCESS,
  CHECK_OPC_VALIDATION_FAILURE,
  BLANK_SELECTION_START,
  BLANK_SELECTION_SUCCESS,
  BLANK_SELECTION_FAILURE,
  SET_MULTIPLE_BLANK_REASON
} from "./blankSelectionConstants";
export const initialState = {
  getOrderLoader: false,
  orderDetails: {},
  opcValidationLoader: false,
  blankSelectionLoader: false,
  multipleBlankReason: null
};

export const getOrderForBlankSelectionStart = state => {
  return {
    ...state,
    getOrderLoader: true,
    orderDetails: {}
  };
};
export const getOrderForBlankSelectionSuccess = (state, payload) => {
  return {
    ...state,
    getOrderLoader: false,
    orderDetails: payload
  };
};
export const getOrderForBlankSelectionFailure = state => {
  return {
    ...state,
    getOrderLoader: false,
    orderDetails: {}
  };
};
export const checkOpcValidationStart = state => {
  return {
    ...state,
    opcValidationLoader: true
  };
};
export const checkOpcValidationSuccess = state => {
  return {
    ...state,
    opcValidationLoader: false
  };
};
export const checkOpcValidationFailure = state => {
  return {
    ...state,
    opcValidationLoader: false
  };
};
export const blankSelectionStart = state => {
  return {
    ...state,
    blankSelectionLoader: true
  };
};
export const blankSelectionSuccess = state => {
  return {
    ...state,
    blankSelectionLoader: false
  };
};
export const blankSelectionFailure = state => {
  return {
    ...state,
    blankSelectionLoader: false
  };
};
export const setMultipleBlankReason = (state, payload) => {
  return {
    ...state,
    multipleBlankReason: payload
  };
};
export default createReducer(initialState, {
  [GET_ORDER_FOR_BLANK_SELECTION_START]: getOrderForBlankSelectionStart,
  [GET_ORDER_FOR_BLANK_SELECTION_SUCCESS]: getOrderForBlankSelectionSuccess,
  [GET_ORDER_FOR_BLANK_SELECTION_FAILURE]: getOrderForBlankSelectionFailure,
  [CHECK_OPC_VALIDATION_START]: checkOpcValidationStart,
  [CHECK_OPC_VALIDATION_SUCCESS]: checkOpcValidationSuccess,
  [CHECK_OPC_VALIDATION_FAILURE]: checkOpcValidationFailure,
  [BLANK_SELECTION_START]: blankSelectionStart,
  [BLANK_SELECTION_SUCCESS]: blankSelectionSuccess,
  [BLANK_SELECTION_FAILURE]: blankSelectionFailure,
  [SET_MULTIPLE_BLANK_REASON]: setMultipleBlankReason
});
