import React, { Component } from "react";
import { Button } from "antd";
import { Form } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import {
  combineValidators,
  composeValidators,
  isRequired,
  hasLengthLessThan,
  hasLengthGreaterThan,
  isNumeric
} from "revalidate";
import TextInput from "../../../app/common/form/TextInput";
import { checkLinkedOrder } from "../linkingActions";

class LinkedOrderForm extends Component {
  handleFormSubmit = data => {
    if (
      this.props.type !== undefined &&
      this.props.linkingOrderId !== undefined
    ) {
      this.props.checkLinkedOrder(
        data.internal_order_id,
        this.props.type,
        this.props.linkingOrderId
      );
    } else {
      this.props.checkLinkedOrder(data.internal_order_id);
    }
  };
  render() {
    const { handleSubmit, invalid, checkingLinkedOrder } = this.props;
    return (
      <div>
        <Form error size="large" autoComplete="off">
          <Field
            name="internal_order_id"
            component={TextInput}
            type="text"
            placeholder="Internal Order Id"
            autoFocus={true}
          />

          <Button
            block
            type="primary"
            style={{ height: "38px" }}
            onClick={handleSubmit(this.handleFormSubmit)}
            disabled={invalid}
            loading={checkingLinkedOrder}
          >
            Submit
          </Button>
        </Form>
      </div>
    );
  }
}
const validate = combineValidators({
  internal_order_id: composeValidators(
    isNumeric({ message: "Numeric value only" }),
    isRequired({ message: "Internal Order Id is required" }),
    hasLengthLessThan(13)({
      message: "Max. 12 characters allowed"
    }),
    hasLengthGreaterThan(7)({ message: "Min. 8 characters" })
  )()
});
const actions = {
  checkLinkedOrder
};
const mapStateToProps = state => ({
  checkingLinkedOrder: state.linking.checkingLinkedOrder
});
export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({ form: "linkedOrderForm", validate, enableReinitialize: true })(
    LinkedOrderForm
  )
);
