import React, { Component } from "react";
import { Select, Input, Button } from "antd";
import { connect } from "react-redux";
import { getLinkingReasons, linkOrders } from "../linkingActions";
import LoaderComponent from "../../../app/common/util/LoaderComponent";

const Option = Select.Option;

class LinkingReasonForm extends Component {
  state = {
    inputVal: "",
    selectedOption: null,
    showTextBox: false
  };
  componentDidMount() {
    this.props.getLinkingReasons();
  }
  handleInput = e => {
    this.setState({ inputVal: e.target.value });
  };
  handleSelect = selectedOption => {
    if (
      selectedOption &&
      selectedOption.label &&
      selectedOption.label.toUpperCase() === "OTHERS"
    ) {
      this.setState({ selectedOption, showTextBox: true });
    } else {
      this.setState({ selectedOption, showTextBox: false });
    }
  };
  handleFormSubmit = () => {
    const { linkedOrderId, linkingOrderId, linkOrders } = this.props;
    let data = {};
    data["linkedOrderId"] = linkedOrderId;
    data["linkingOrderId"] = linkingOrderId;
    if (this.state.showTextBox === true) {
      data["reason"] = this.state.inputVal;
    } else {
      data["reason"] = this.state.selectedOption.key;
    }
    console.log(data);
    linkOrders(data);
  };
  render() {
    const {
      linkingReasons,
      loadingLinkingReasons,
      linkOrdersLoader
    } = this.props;
    const { inputVal, selectedOption } = this.state;
    if (loadingLinkingReasons) {
      return <LoaderComponent />;
    }

    return (
      <div>
        <Select
          onChange={this.handleSelect}
          placeholder="Linking Reason"
          style={{ width: "100%" }}
          labelInValue={true}
        >
          {linkingReasons &&
            linkingReasons.map((item, index) => {
              return (
                <Option key={index} value={item.id}>
                  {item.reason}
                </Option>
              );
            })}
        </Select>
        {this.state.showTextBox === true && (
          <Input.TextArea
            placeholder="Enter Reason"
            rows={4}
            value={inputVal}
            onChange={this.handleInput}
            style={{ marginTop: "5%" }}
          />
        )}
        <Button
          block
          type="primary"
          style={{ height: "38px", marginTop: "6%" }}
          onClick={this.handleFormSubmit}
          disabled={
            selectedOption === null
              ? true
              : selectedOption.label.toUpperCase() === "OTHERS"
              ? inputVal.length > 1
                ? true
                : false
              : false
          }
          loading={linkOrdersLoader}
        >
          Submit
        </Button>
      </div>
    );
  }
}
const actions = {
  getLinkingReasons,
  linkOrders
};
const mapStateToProps = state => ({
  linkingReasons: state.linking.linkingReasons,
  loadingLinkingReasons: state.linking.loadingLinkingReasons,
  linkedOrderId: state.linking.linkedOrderId,
  linkOrdersLoader: state.linking.linkOrdersLoader
});
export default connect(
  mapStateToProps,
  actions
)(LinkingReasonForm);
