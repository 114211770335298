import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "../reducers/rootReducer";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

export const history = createBrowserHistory();

export const configureStore = preloadedState => {
  const middlewares = [thunk];

  const middlewareEnhancer = applyMiddleware(
    ...middlewares,
    routerMiddleware(history)
  );

  const storeEnhancers = [middlewareEnhancer];

  const composedEnhancers = composeWithDevTools(...storeEnhancers);

  const store = createStore(
    rootReducer(history),
    preloadedState,
    composedEnhancers
  );

  if (process.env.NODE_ENV !== "production") {
    if (module.hot) {
      module.hot.accept("../reducers/rootReducer", () => {
        const newRootReducer = require("../reducers/rootReducer").default;
        store.replaceReducer(newRootReducer(history));
      });
    }
  }

  return store;
};
