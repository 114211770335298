import React, { Component } from "react";
import { Button, Row, Col } from "antd";
import { connect } from "react-redux";
import {
  getNextOrder,
  getPreviousOrder,
  checkLenswareStatus,
  printStockOrder,
  archiveStockOrder
} from "../homeActions";
import { openModal } from "../../modal/modalActions";
import { PermissibleRender } from "@brainhubeu/react-permissible";
import {
  getUserPermissions,
  getUserType,
  ErrorModal,
  errorToast
} from "../../../app/common/util/genericUtils";
import { getLabelPrintingData } from "../../labelPrinting/labelPrintingActions";
import { printAuthCard } from "../../authCardPrinting/authCardActions";
import { lenswareStatusApiCall } from "../api";
import { reportError } from "../../../app/common/util/sentryConfig";
class OrderDetailsActions extends Component {
  state = {
    checkingStatus: false
  };
  handleCancelModal = () => {
    const { openModal, id } = this.props;
    let userType = getUserType();
    if (userType === "Corporate") {
      openModal("CancellationModal", { record: { id } });
    } else {
      ErrorModal(
        "Error",
        "Please contact Customer Support to cancel this order."
      );
    }
  };
  handleCancelForProcessing = id => () => {
    this.setState({ checkingStatus: true });
    return lenswareStatusApiCall(id)
      .then(response => {
        this.setState({ checkingStatus: false });
        if (response && response.data.response_code === 80) {
          if (response.data.data.status.coorperate_value === "CANC") {
            this.handleCancelModal();
          } else {
            errorToast("Error", "Please cancel the order in Lensware first");
          }
        } else {
          errorToast("Error", response.data.ui_message);
        }
      })
      .catch(error => {
        this.setState({ checkingStatus: false });
        errorToast(error.message);
        reportError(error);
      });
  };
  handleOrderPrint = id => () => {
    window.print();
    this.props.printStockOrder(id);
  };
  render() {
    const {
      status,
      getNextOrder,
      getPreviousOrder,
      openModal,
      details,
      getLabelPrintingData,
      gettingLabelData,
      checkLenswareStatus,
      loadingLenswareStatus,
      printAuthCard,
      authCardLoader,
      printingStockOrder,
      archivingStockOrder
    } = this.props;

    const userPermissions = getUserPermissions();
    return (
      <div style={{ margin: "2% 0 0 10%" }}>
        <Row>
          <Col span={18}>
            {status !== "processing" && (
              <Button
                type="danger"
                style={{
                  marginRight: "2%"
                }}
                onClick={this.handleCancelModal}
                disabled={details.is_stock === true ? true : false}
              >
                Cancel
              </Button>
            )}
            {status === "processing" && (
              <Button
                type="danger"
                style={{
                  marginRight: "2%"
                }}
                loading={this.state.checkingStatus}
                onClick={this.handleCancelForProcessing(details.id)}
                //  disabled={status === "processing" ? true : false}
              >
                Cancel
              </Button>
            )}
            <PermissibleRender
              userPermissions={userPermissions}
              requiredPermissions={["can_add_label"]}
            >
              <Button
                type="primary"
                onClick={() =>
                  openModal("CreateLabelModal", { id: this.props.id })
                }
              >
                Add Label
              </Button>
            </PermissibleRender>
            <PermissibleRender
              userPermissions={userPermissions}
              requiredPermissions={["can_link_order"]}
            >
              <Button
                style={{ marginLeft: "2%" }}
                type="primary"
                onClick={() =>
                  this.props.openModal("LinkedOrderModal", {
                    type: "linkOldOrder",
                    linkingOrderId: this.props.id
                  })
                }
              >
                Link Order
              </Button>
            </PermissibleRender>
            {details.is_stock === false && (
              <React.Fragment>
                <PermissibleRender
                  userPermissions={userPermissions}
                  requiredPermissions={["can_print_envelope"]}
                >
                  {details.blank_selection_attempts &&
                    details.blank_selection_attempts > 0 && (
                      <Button
                        style={{ marginLeft: "2%" }}
                        type="primary"
                        loading={gettingLabelData}
                        onClick={() => getLabelPrintingData(details, false)}
                      >
                        Print Envelope
                      </Button>
                    )}
                </PermissibleRender>
                <PermissibleRender
                  userPermissions={userPermissions}
                  requiredPermissions={["can_print_authcard"]}
                >
                  {details.blank_selection_attempts &&
                    details.blank_selection_attempts > 0 && (
                      <Button
                        style={{ marginLeft: "2%" }}
                        type="primary"
                        loading={authCardLoader}
                        onClick={() => printAuthCard(details.id)}
                      >
                        Print Auth Card
                      </Button>
                    )}
                </PermissibleRender>
                {details && details.status === "processing" && (
                  <Button
                    style={{ marginLeft: "2%" }}
                    type="primary"
                    loading={loadingLenswareStatus}
                    onClick={() => checkLenswareStatus(details.id)}
                  >
                    Check Current Status
                  </Button>
                )}
              </React.Fragment>
            )}
          </Col>
          <Col span={6}>
            <Button
              type="primary"
              style={{ marginRight: "7%" }}
              onClick={() =>
                getPreviousOrder(details.is_stock === true ? "stock" : status)
              }
            >
              Previous
            </Button>
            <Button
              type="primary"
              onClick={() =>
                getNextOrder(details.is_stock === true ? "stock" : status)
              }
            >
              Next
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: "1%" }}>
          <Col span={24}>
            <Button
              ///style={{ marginLeft: "2%" }}
              type="primary"
              loading={printingStockOrder}
              onClick={this.handleOrderPrint(details.id)}
            >
              Print Order
            </Button>
            {details.is_stock === true && (
              <Button
                style={{ marginLeft: "2%" }}
                type="primary"
                loading={archivingStockOrder}
                onClick={() => this.props.archiveStockOrder(details.id)}
              >
                Mark Done
              </Button>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
const actions = {
  getNextOrder,
  getPreviousOrder,
  openModal,
  getLabelPrintingData,
  checkLenswareStatus,
  printAuthCard,
  printStockOrder,
  archiveStockOrder
};
const mapStateToProps = state => ({
  putOnHoldLoader: state.home.putOnHoldLoader,
  gettingLabelData: state.label.gettingLabelData,
  loadingLenswareStatus: state.home.loadingLenswareStatus,
  authCardLoader: state.authCard.authCardLoader,
  printingStockOrder: state.home.printingStockOrder,
  archivingStockOrder: state.home.archivingStockOrder
});
export default connect(
  mapStateToProps,
  actions
)(OrderDetailsActions);
