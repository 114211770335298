import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";
import { getItem } from "../common/util/genericUtils";

class PrivateRoute extends Component {
  render() {
    const { component: Component, ...rest } = this.props;
    const user = getItem("suprolUser");
    if (user && user.auth_token) {
      return <Route {...rest} render={props => <Component {...props} />} />;
    } else {
      return (
        <Route
          {...rest}
          render={props => (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          )}
        />
      );
    }
  }
}

export default PrivateRoute;
