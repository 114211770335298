import {
  combineValidators,
  createValidator,
  composeValidators,
  isRequired,
  hasLengthLessThan,
  hasLengthGreaterThan,
  isAlphaNumeric,
  isNumeric,
  matchesPattern,
  isRequiredIf
} from "revalidate";
import { lnamFromFAlphabet } from "./constants";
import { getLnamType, getLnamConfigFromId, fieldRangeMapper } from "./formUtils";
import { store } from "../../ParentApp";
import { formValueSelector } from "redux-form";
import moment from "moment";
import { configureStore } from "../../app/store/configureStore";
import { getUserType, isEmpty } from "../../app/common/util/genericUtils";

const positiveRegex = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
const decimalRegex = /^[0-9]\d{0,9}(\.\d{1,3})?%?$/;
const numericRegex = /^(\+|-)?\d+$/;
const negativePositiveRegex = /^-*\+?[0-9]\d*(\.\d+)?$/;

const prescriptionRangeValidator = (fieldName) =>
  createValidator(
    message => (value, allValues) => {
      if (value) {
        let errorMessage = ""
        let currentValue = parseFloat(value)
        let lnamObj = allValues['lnam']
        if (lnamObj && lnamObj.key) {
          let config = getLnamConfigFromId(lnamObj.key)
          console.log('called valid', config)
          if (isEmpty(config) === true) {
            errorMessage = "Incorrect Lnam Selected"
            return errorMessage
          } else {
            let minValue = config[fieldRangeMapper[fieldName].min]
            let maxValue = config[fieldRangeMapper[fieldName].max]
            console.log(minValue, maxValue, currentValue)
            if (!(currentValue >= minValue && currentValue <= maxValue)) {
              errorMessage = `${fieldName} should lie b/w ${minValue} & ${maxValue}`
              return errorMessage
            }
          }
        } else {
          errorMessage = "Please Select Lnam"
          return errorMessage
        }
      }
    },

    field => `${field} must lie between predfined range`
  );

// const prescriptionSumValidator=()=>
// createValidator(
//   message => (value,allValues) => {
//     if (value) {
//       let errorMessage = ""
//       let currentValue = parseFloat(value)
//       let lnamObj = allValues['lnam']
//       if (lnamObj && lnamObj.key) {

//       }
//     }
//   },

//   field => `${field} has invalid values`
// );
const valueLieBetween = (a, b) =>
  createValidator(
    message => value => {
      if (value && !(value > a && value < b)) {
        return message;
      }
    },

    field => `${field} must lie between ${a} and ${b}`
  );

const shouldBeGreaterThen = a =>
  createValidator(
    message => value => {
      if (value && value <= a) {
        return message;
      }
    },

    field => `${field} should be less then ${a}`
  );

const shouldBeLessThen = a =>
  createValidator(
    message => value => {
      if (value && value >= a) {
        return message;
      }
    },

    field => `${field} should be less then ${a}`
  );

const shouldBeMultipleOf = v =>
  createValidator(
    message => value => {
      let remainder = value % v;
      // console.log("rem", value, v, remainder);
      if (value && remainder !== 0) {
        return message;
      }
    },

    field => `${field} should be less then ${v}`
  );

const hourLessThanCurrentHour = (fieldName, formName) =>
  createValidator(
    message => value => {
      const selector = formValueSelector(formName);
      const fieldValue = selector(store.getState(), fieldName);
      const hour = moment().format("H");
      const currentDay = moment().format("DD-MM-YYYY");
      const selectedDate = moment(fieldValue).format("DD-MM-YYYY");
      //console.log(currentDay, selectedDate, value, hour, fieldValue);
      if (currentDay === selectedDate) {
        if (value > hour || value < 0) {
          return message;
        }
      } else if (value < 0 || value > 23) {
        return message;
      }
    },
    field => `Invalid Hour`
  );
const minuteLessThanCurrentMinute = (dateField, hourField, formName) =>
  createValidator(
    message => value => {
      const selector = formValueSelector(formName);
      const dateFieldValue = selector(store.getState(), dateField);
      const hourFieldValue = selector(store.getState(), hourField);
      const minute = moment().format("m");
      const hour = moment().format("H");
      const currentDay = moment().format("DD-MM-YYYY");
      const selectedDate = moment(dateFieldValue).format("DD-MM-YYYY");
      //console.log(minute, currentDay, selectedDate);
      if (currentDay === selectedDate && hour === hourFieldValue) {
        if (value > minute || value < 0) {
          return message;
        }
      } else if (value < 0 || value > 59) {
        return message;
      }
    },
    field => `Invalid Hour`
  );
const hourGreaterThanCurrentHour = (fieldName, formName) =>
  createValidator(
    message => value => {
      const selector = formValueSelector(formName);
      const fieldValue = selector(store.getState(), fieldName);
      const hour = moment().format("H");
      const currentDay = moment().format("DD-MM-YYYY");
      const selectedDate = moment(fieldValue).format("DD-MM-YYYY");
      if (currentDay === selectedDate) {
        if (value < hour || value > 23) {
          return message;
        }
      } else if (value < 0 || value > 23) {
        return message;
      }
    },
    field => `Invalid Hour`
  );
const minuteGreaterThanCurrentMinute = (dateField, hourField, formName) =>
  createValidator(
    message => value => {
      const selector = formValueSelector(formName);
      const dateFieldValue = selector(store.getState(), dateField);
      const hourFieldValue = selector(store.getState(), hourField);
      const minute = moment().format("m");
      const hour = moment().format("H");
      const currentDay = moment().format("DD-MM-YYYY");
      const selectedDate = moment(dateFieldValue).format("DD-MM-YYYY");
      //console.log(minute, currentDay, selectedDate);
      if (currentDay === selectedDate && hour === hourFieldValue) {
        if (value < minute || value > 59) {
          return message;
        }
      } else if (value < 0 || value > 59) {
        return message;
      }
    },
    field => `Invalid Hour`
  );
export const validate = combineValidators({
  customer: isRequired({ message: "Customer Details are required" }),
  product_group: isRequired({ message: "Product Group is required" }),
  reference_id: composeValidators(
    isNumeric({ message: "Numeric value only" }),
    // isRequired({ message: "Internal Order Id is required" }),
    hasLengthLessThan(21)({
      message: "Max. 20 characters allowed"
    }),
    hasLengthGreaterThan(7)({ message: "Min. 8 characters" })
  )(),
  received_by: isRequired({ message: "Order received by is required" }),
  entered_by: isRequiredIf(() => {
    let userType = getUserType();
    if (userType && userType === "Branch") {
      return true;
    }
  })({ message: "Order entered by is required" }),
  distributor: hasLengthLessThan(51)({ message: "Max. 50 characters allowed" }),
  patient: hasLengthLessThan(51)({
    message: "Max. 50 characters allowed"
  }),
  promised_date: isRequired({ message: "Promise Date is required" }),
  promised_hour: composeValidators(
    isRequired({ message: "Promise Hour is required" }),
    hourGreaterThanCurrentHour("promised_date", "orderForm")({
      message: "The time is invalid"
    }),
    isNumeric({ message: "Numeric value only" })
  )(),
  promised_minute: composeValidators(
    isRequired({ message: "Promise Minute is required" }),
    minuteGreaterThanCurrentMinute(
      "promised_date",
      "promised_hour",
      "orderForm"
    )({
      message: "The time is invalid"
    }),
    isNumeric({ message: "Numeric value only" })
  )(),
  shipping_address: isRequired({ message: "Shipping Address required" }),
  dispatch_type: isRequired({ message: "Dispatch Type is required" }),
  received_date: isRequired({ message: "Received Date is required" }),
  received_hour: composeValidators(
    isRequired({ message: "Received Hour is required" }),
    hourLessThanCurrentHour("received_date", "orderForm")({
      message: "The time is invalid"
    }),
    isNumeric({ message: "Numeric value only" })
  )(),
  received_minute: composeValidators(
    isRequired({ message: "Received Minute is required" }),
    minuteLessThanCurrentMinute("received_date", "received_hour", "orderForm")({
      message: "The time is invalid"
    }),
    isNumeric({ message: "Numeric value only" })
  )(),
  lnam: isRequired({ message: "LNAM is required" }),
  coating: composeValidators(
    hasLengthLessThan(21)({ message: "Max. 20 characters allowed" }),
    isRequired({ message: "Coating is required" })
  )(),
  corridor: isRequired({ message: "Corridor is required" }),
  cto: composeValidators(
    isRequiredIf(values => {
      if (values && values.lnam && values.lnam.key) {
        const lnamType = getLnamType(values.lnam.key);
        if (lnamFromFAlphabet.includes(lnamType) === true) {
          return true;
        }
      }
    })({
      message: "Cto is required"
    })
  )(),
  fitting_height: composeValidators(
    isRequiredIf(values => {
      if (values && values.lnam && values.lnam.key) {
        const lnamType = getLnamType(values.lnam.key);
        if (lnamFromFAlphabet.includes(lnamType) === true) {
          return true;
        }
      }
    })({
      message: "Fitting Height is required"
    })
  )(),
  clinit: composeValidators(
    isRequiredIf(values => {
      if (values && values.lnam && values.lnam.key) {
        const lnamType = getLnamType(values.lnam.key);
        if (lnamFromFAlphabet.includes(lnamType) === true) {
          return true;
        }
      }
    })({
      message: "Customer Initials is required"
    }),
    hasLengthLessThan(3)({ message: "Max. 2 characters allowed" })
  )(),
  "right_lens_config.sph": composeValidators(
    isRequiredIf(() => {
      const rightEyeCheckbox = store.getState().orderForm.rightEyeCheckbox;
      if (rightEyeCheckbox === true) {
        return true;
      }
    })({ message: "Sph is required" }),
    matchesPattern(negativePositiveRegex)({
      message: "+ve and -ve value only"
    }),
    shouldBeMultipleOf(0.25)({
      message: "Multiple of 0.25 allowed"
    })
  )(),
  "right_lens_config.cyl": composeValidators(
    matchesPattern(negativePositiveRegex)({
      message: "+ve and -ve value only"
    }),
    shouldBeMultipleOf(0.25)({
      message: "Multiple of 0.25 allowed"
    }),
    prescriptionRangeValidator('cyl')({ message: 'Error' })
  )(),
  "right_lens_config.axis": composeValidators(
    isRequiredIf(values => {
      if (values && values.lnam && values.lnam.key) {
        const lnamType = getLnamType(values.lnam.key);
        if (values.right_lens_config && values.right_lens_config.cyl > 0) {
          if (lnamType !== "LEN" && lnamType !== "SPH") {
            return true;
          }
        }
      }
    })({
      message: "Axis is required"
    }),
    matchesPattern(numericRegex)({ message: "Numeric value only" }),
    shouldBeLessThen(181)({ message: "Max. allowed value is 180" })
  )(),
  "right_lens_config.add": composeValidators(
    isRequired({ message: "Add is required" }),
    matchesPattern(positiveRegex)({
      message: "+ve value only"
    }),
    shouldBeMultipleOf(0.25)({
      message: "Multiple of 0.25 allowed"
    }),
    prescriptionRangeValidator('add')({ message: 'Error' })
  )(),
  "right_lens_config.dia": composeValidators(
    matchesPattern(numericRegex)({ message: "Numeric value only" }),
    isRequired({ message: "Dia R is required" }),
    valueLieBetween(49, 81)({ message: "Min. 50 & Max. 80" }),
    prescriptionRangeValidator('dia')({ message: 'Error' })
  )(),
  "right_lens_config.ellh": composeValidators(
    matchesPattern(numericRegex)({ message: "Numeric value only" }),
    valueLieBetween(49, 81)({ message: "Min. 50 & Max. 80" })
  )(),
  "right_lens_config.swing": composeValidators(
    isRequired({ message: "Swing is required" }),
    matchesPattern(numericRegex)({
      message: "Numeric value only"
    })
  )(),
  //"right_lens_config.mbase": matchesPattern(numericRegex)({
  //message: "Numeric value only"
  //}),
  "right_lens_config.et": matchesPattern(negativePositiveRegex)({
    message: "Integers and Decimal Only"
  }),
  "right_lens_config.ct": matchesPattern(negativePositiveRegex)({
    message: "Integers and Decimal Only"
  }),
  "right_lens_config.ed": matchesPattern(numericRegex)({
    message: "Numeric value only"
  }),

  "left_lens_config.sph": composeValidators(
    isRequiredIf(() => {
      const leftEyeCheckbox = store.getState().orderForm.leftEyeCheckbox;
      if (leftEyeCheckbox === true) {
        return true;
      }
    })({ message: "Sph is required" }),
    matchesPattern(negativePositiveRegex)({
      message: "+ve and -ve value only"
    }),
    shouldBeMultipleOf(0.25)({
      message: "Multiple of 0.25 allowed"
    })
  )(),
  "left_lens_config.cyl": composeValidators(
    matchesPattern(negativePositiveRegex)({
      message: "+ve and -ve value only"
    }),
    shouldBeMultipleOf(0.25)({
      message: "Multiple of 0.25 allowed"
    }),
    prescriptionRangeValidator('cyl')({ message: 'Error' })
  )(),
  "left_lens_config.axis": composeValidators(
    isRequiredIf(values => {
      if (values && values.lnam && values.lnam.key) {
        const lnamType = getLnamType(values.lnam.key);
        if (
          values &&
          values.left_lens_config &&
          values.left_lens_config.cyl > 0
        ) {
          if (lnamType !== "LEN" && lnamType !== "SPH") {
            return true;
          }
        }
      }
    })({
      message: "Axis is required"
    }),
    matchesPattern(numericRegex)({ message: "Numeric value only" }),
    shouldBeLessThen(181)({ message: "Max. allowed value is 180" })
  )(),
  "left_lens_config.add": composeValidators(
    isRequired({ message: "Add is required" }),
    matchesPattern(positiveRegex)({
      message: "+ve value only"
    }),
    shouldBeMultipleOf(0.25)({
      message: "Multiple of 0.25 allowed"
    }),
    prescriptionRangeValidator('add')({ message: 'Error' })
  )(),
  "left_lens_config.dia": composeValidators(
    matchesPattern(numericRegex)({ message: "Numeric value only" }),
    isRequired({ message: "Dia L is required" }),
    valueLieBetween(49, 81)({ message: "Min. 50 & Max. 80" }),
    prescriptionRangeValidator('dia')({ message: 'Error' })
  )(),
  "left_lens_config.ellh": composeValidators(
    matchesPattern(numericRegex)({ message: "Numeric value only" }),
    valueLieBetween(49, 81)({ message: "Min. 50 & Max. 80" })
  )(),
  "left_lens_config.swing": composeValidators(
    isRequired({ message: "Swing is required" }),
    matchesPattern(numericRegex)({
      message: "Numeric value only"
    })
  )(),
  "left_lens_config.et": matchesPattern(negativePositiveRegex)({
    message: "Integers and Decimal Only"
  }),
  "left_lens_config.ct": matchesPattern(negativePositiveRegex)({
    message: "Integers and Decimal Only"
  }),
  "left_lens_config.ed": matchesPattern(numericRegex)({
    message: "Numeric value only"
  }),

  "frame.mono_pd_r": composeValidators(
    matchesPattern(negativePositiveRegex)({
      message: "Integers and Decimal Only"
    }),
    isRequiredIf(values => {
      if (values && values.lnam && values.lnam.key) {
        const lnamType = getLnamType(values.lnam.key);
        const rightEyeCheckbox = store.getState().orderForm.rightEyeCheckbox;
        const ctoFieldFlag = store.getState().orderForm.ctoFieldFlag;
        if (lnamType.startsWith("F") === true && rightEyeCheckbox === true) {
          return true;
        } else if (
          rightEyeCheckbox === true &&
          lnamFromFAlphabet.includes(lnamType === true) &&
          ctoFieldFlag === true
        ) {
          return true;
        }
      }
    })({
      message: "Mono Pd R is required"
    })
  )(),
  "frame.mono_pd_l": composeValidators(
    matchesPattern(negativePositiveRegex)({
      message: "Integers and Decimal Only"
    }),
    isRequiredIf(values => {
      if (values && values.lnam && values.lnam.key) {
        const lnamType = getLnamType(values.lnam.key);
        const leftEyeCheckbox = store.getState().orderForm.leftEyeCheckbox;
        const ctoFieldFlag = store.getState().orderForm.ctoFieldFlag;
        if (lnamType.startsWith("F") === true && leftEyeCheckbox === true) {
          return true;
        } else if (
          leftEyeCheckbox === true &&
          lnamFromFAlphabet.includes(lnamType === true) &&
          ctoFieldFlag === true
        ) {
          return true;
        }
      }
    })({
      message: "Mono Pd L is required"
    })
  )(),
  "frame.npd_r": composeValidators(
    matchesPattern(negativePositiveRegex)({
      message: "Integers and Decimal Only"
    }),
    isRequiredIf(values => {
      if (values && values.lnam && values.lnam.key) {
        const lnamType = getLnamType(values.lnam.key);
        const lnamList = ["FPD", "FPP", "FPL"];
        const rightEyeCheckbox = store.getState().orderForm.rightEyeCheckbox;
        if (lnamList.includes(lnamType) === true && rightEyeCheckbox === true) {
          return true;
        }
      }
    })({
      message: "Npd R is required"
    })
  )(),
  "frame.npd_l": composeValidators(
    matchesPattern(negativePositiveRegex)({
      message: "Integers and Decimal Only"
    }),
    isRequiredIf(values => {
      if (values && values.lnam && values.lnam.key) {
        const lnamType = getLnamType(values.lnam.key);
        const lnamList = ["FPD", "FPP", "FPL"];
        const leftEyeCheckbox = store.getState().orderForm.leftEyeCheckbox;
        if (lnamList.includes(lnamType) === true && leftEyeCheckbox === true) {
          return true;
        }
      }
    })({
      message: "Npd L is required"
    })
  )(),
  "frame.a_width": composeValidators(
    matchesPattern(negativePositiveRegex)({
      message: "+ve and -ve value only"
    }),
    isRequiredIf(values => {
      if (values && values.lnam && values.lnam.key) {
        const lnamType = getLnamType(values.lnam.key);
        const ctoFieldFlag = store.getState().orderForm.ctoFieldFlag;
        if (lnamFromFAlphabet.includes(lnamType) === true) {
          return true;
        } else if (
          lnamFromFAlphabet.includes(lnamType) === true &&
          ctoFieldFlag === true
        ) {
          return true;
        }
      }
    })({
      message: "A Width is required"
    })
  )(),
  "frame.b_width": composeValidators(
    matchesPattern(negativePositiveRegex)({
      message: "+ve and -ve value only"
    }),
    isRequiredIf(values => {
      if (values && values.lnam && values.lnam.key) {
        const lnamType = getLnamType(values.lnam.key);
        const ctoFieldFlag = store.getState().orderForm.ctoFieldFlag;

        if (lnamFromFAlphabet.includes(lnamType) === true) {
          return true;
        } else if (
          lnamFromFAlphabet.includes(lnamType) === true &&
          ctoFieldFlag === true
        ) {
          return true;
        }
      }
    })({
      message: "B Width is required"
    })
  )(),
  "frame.dbl": composeValidators(
    matchesPattern(negativePositiveRegex)({
      message: "+ve and -ve value only"
    }),
    shouldBeGreaterThen(12)({ message: "Min. allowed value is 13" }),
    isRequiredIf(values => {
      if (values && values.lnam && values.lnam.key) {
        const lnamType = getLnamType(values.lnam.key);
        const ctoFieldFlag = store.getState().orderForm.ctoFieldFlag;
        if (
          lnamFromFAlphabet.includes(lnamType) === true &&
          ctoFieldFlag === true
        ) {
          return true;
        }
      }
    })({
      message: "DBL is required"
    })
  )(),
  "frame.right_prism.prvm1": matchesPattern(decimalRegex)({
    message: "Decimal value only"
  }),
  "frame.right_prism.prvm2": matchesPattern(decimalRegex)({
    message: "Decimal value only"
  }),
  "frame.right_prism.prva1": matchesPattern(numericRegex)({
    message: "Integer value only"
  }),
  "frame.right_prism.prva2": matchesPattern(numericRegex)({
    message: "Integer value only"
  }),
  "add_ons.tool_charges": matchesPattern(decimalRegex)({
    message: "Decimal value only"
  }),
  "add_ons.other_charges": composeValidators(
    matchesPattern(decimalRegex)({
      message: "Decimal value only"
    }),
    hasLengthGreaterThan(3)({ message: "Charges should be greater then 3." })
  )()
});

export const customIsRequired = value =>
  value || typeof value === "string" || typeof value === "number"
    ? undefined
    : "Field is Required";
